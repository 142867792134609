<div class="main-container">
    <div class="row align-center">
        <div class="top-heading col-md-3">
            Compliance
        </div>
        <div class="col-md-9">
            <div class="row align-center" style="justify-content: flex-end;">
                <!-- <div class="col-md-4" style="text-align: right;">
                    <select class="input-field" name="region" id="region" aria-placeholder="Select Role" (change)="onEmployeeRoleSelectionChange($event)">
                        <option value="Select Role" selected disabled>Select Role</option>
                        <option value="All">All</option>
                        <option value="SUPERVISOR">Supervisor</option>
                        <option value="SORTER">Sorter</option>
                        <option value="GO-D">Go-D</option>
                        <option value="RIDER">Rider</option>
                    </select>
                </div> -->
                <!-- <div class="col-md-5" style="text-align: right;">
                    <input type="text" class="input-field" name="searchQuery" id="searchQuery" placeholder="Search Here" (input)="searchData($event.target)">
                </div>
                <div class="col-md-3">
                    <button class="add-new-emp" (click)="addNewSociety()">
                        Add New Society
                    </button>
                </div> -->
            </div>
        </div>
    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height: 79vh;margin-top: 2em;" *ngIf="!isLoading">
        <div class="row" style="margin-right: 2em;">
            <div class="col-md-3" (click)="moveToPrivacyPolicy()">
                <div class="main-card">
                    <img class="img-card" src="../../../../../assets/img/privacy.jpg" alt="State Image" srcset="">
                    <div class="name-on-card">
                        Privacy Policy
                    </div>
                </div>
            </div>
            <div class="col-md-3" (click)="moveToTermsAndCondition()">
                <div class="main-card">
                    <img class="img-card" src="../../../../../assets/img/terms.jpeg" alt="City Image" srcset="">
                    <div class="name-on-card">
                        Terms and Condition
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>