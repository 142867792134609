<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-edit-sport *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)" [dataToEdit]="dataToEdit">
            </app-add-edit-sport>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">
            <!-- Top Data -->
            <div class="row align-center">
                <div class="top-heading col-md-3">
                    Sports List
                </div>
                <div class="col-md-9">
                    <div class="row align-center" style="justify-content: flex-end;">

                        <div class="col-md-4" style="text-align: right;">
                            <select class="input-field" name="region" id="region" aria-placeholder="Select Business"
                                (change)="onSelect($event)">
                                <option value="Select Business" selected disabled>Select Sport Category</option>
                                <option value="All">All</option>
                                <option *ngFor="let sportCategory of listOfSportsCategory"
                                    [value]="sportCategory['_id']"
                                    [selected]="selectedSportCategory==sportCategory['_id']">
                                    {{sportCategory['categoryName']}}</option>
                            </select>
                        </div>
                        <div class="col-md-5" style="text-align: right;">
                            <input type="text" class="input-field" name="searchQuery" id="searchQuery"
                                placeholder="Search Here" (input)="searchData($event.target)">
                        </div>
                        <div class="col-md-3">
                            <button class="add-new-emp" (click)="addNewSPort()">
                                Add New Sport
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <!-- Table Data -->
            <div *ngIf="isLoading" class="display-flex align-items-center" style="height: 60vh;">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>

            <div *ngIf="listOfSportToDisplay.length==0 && !isLoading">
                <div class="no-available">
                    Sports not found.
                </div>
            </div>

            <div *ngIf="listOfSportToDisplay.length>0 && !isLoading">
                <table class="table-style">
                    <thead class="table-heading">
                        <th style="cursor: pointer;" data-order="desc" data-name="name" scope="col"
                            class="table-heading-content">
                            Image</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="name" scope="col"
                            class="table-heading-content">
                            Sport</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Sport Categoory</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                            class="table-heading-content">
                            Active</th>
                        <th class="table-heading-content">ACTION</th>

                    </thead>
                    <div style="height: 0.6em;"> </div>
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let sport of listOfSportToDisplay | paginate: { itemsPerPage: count, currentPage: p }">
                            <th class="table-data-content">
                                <div class="avatar rounded-circle">
                                    <img style="height: 30px;width: 30px; object-fit: contain;" src={{sport.image}}
                                        alt="Sport">
                                </div>
                            </th>
                            <th class="table-data-content">{{sport.sport}}</th>
                            <th class="table-data-content">{{sport.sportCategory['categoryName']}}</th>
                            <th class="table-data-content" style="padding-left: 2rem;">
                                <img *ngIf="sport.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-on"
                                    src="../../../assets/svgIcons/switch-on.svg" style="width: 2rem; cursor: pointer;"
                                    (click)="updateActiveStatus(sport,false)" slot="start" />
                                <img *ngIf="!sport.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-off"
                                    src="../../../assets/svgIcons/switch-off.svg" style="width: 2rem; cursor: pointer;"
                                    (click)="updateActiveStatus(sport,true)" slot="start" />
                            </th>
                            <th class="table-data-content" style="width: 10em;">
                                <!-- <img (click)="viewOfferDetails(offer)" style="height: 1.1rem;margin-right: 1em;cursor: pointer;" src='../../../assets/img/view.png'> -->
                                <div style="display: contents;">
                                    <img (click)="editSport(sport)"
                                        style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>
                                    <img (click)="deleteSport(sport)"
                                        style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/delete.png'>
                                </div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>

            </div>
        </div>
    </ng-sidebar-container>
</div>
<div class="main-container">
    <div class="row align-center">
        <div class="top-heading col-md-3">
            Sports List
        </div>
        <div class="col-md-9">
            <div class="row align-center" style="justify-content: flex-end;">

                <div class="col-md-4" style="text-align: right;">
                    <select class="input-field" name="region" id="region" aria-placeholder="Select Business"
                        (change)="onSelect($event)">
                        <option value="Select Business" selected disabled>Select Sport Category</option>
                        <option value="All">All</option>
                        <option *ngFor="let sportCategory of listOfSportsCategory" [value]="sportCategory['_id']"
                            [selected]="selectedSportCategory==sportCategory['_id']">
                            {{sportCategory['categoryName']}}</option>
                    </select>
                </div>
                <div class="col-md-5" style="text-align: right;">
                    <input type="text" class="input-field" name="searchQuery" id="searchQuery" placeholder="Search Here"
                        (input)="searchData($event.target)">
                </div>
                <div class="col-md-3">
                    <button class="add-new-emp" (click)="addNewSPort()">
                        Add New Sport
                    </button>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height: 79vh;" *ngIf="!isLoading">
        <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="overflow: hidden;position: relative;">
            <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right"
                [dock]="dock" [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
                [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
                [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
                <app-add-edit-sport *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)"
                    [dataToEdit]="dataToEdit">
                </app-add-edit-sport>
            </ng-sidebar>
            <div ng-sidebar-content style="overflow: hidden;padding: 1em;">

                <div *ngIf="listOfSportToDisplay.length==0">
                    <div class="no-available">
                        Sports not found.
                    </div>
                </div>
                <div *ngIf="listOfSportToDisplay.length>0">
                    <table class="table-style">
                        <thead class="table-heading">
                            <th style="cursor: pointer;" data-order="desc" data-name="name" scope="col"
                                class="table-heading-content">
                                Image</th>
                            <th style="cursor: pointer;" data-order="desc" data-name="name" scope="col"
                                class="table-heading-content">
                                Sport</th>
                            <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                                class="table-heading-content">
                                Sport Categoory</th>
                            <th style="cursor: pointer;" data-order="desc" data-name="code" scope="col"
                                class="table-heading-content">
                                Active</th>
                            <th class="table-heading-content">ACTION</th>

                        </thead>
                        <div style="height: 0.6em;"> </div>
                        <tbody>
                            <tr class="table-data"
                                *ngFor="let sport of listOfSportToDisplay | paginate: { itemsPerPage: count, currentPage: p }">
                                <th class="table-data-content">
                                    <div class="avatar rounded-circle">
                                        <img style="height: 30px;width: 30px; object-fit: contain;" src={{sport.image}}
                                            alt="Sport">
                                    </div>
                                </th>
                                <th class="table-data-content">{{sport.sport}}</th>
                                <th class="table-data-content">{{sport.sportCategory['categoryName']}}</th>
                                <th class="table-data-content" style="padding-left: 2rem;">
                                    <img *ngIf="sport.isActive"
                                        class="table-data-content__status-icon table-data-content__status-icon-on"
                                        src="../../../assets/svgIcons/switch-on.svg"
                                        style="width: 2rem; cursor: pointer;" (click)="updateActiveStatus(sport,false)"
                                        slot="start" />
                                    <img *ngIf="!sport.isActive"
                                        class="table-data-content__status-icon table-data-content__status-icon-off"
                                        src="../../../assets/svgIcons/switch-off.svg"
                                        style="width: 2rem; cursor: pointer;" (click)="updateActiveStatus(sport,true)"
                                        slot="start" />
                                </th>
                                <th class="table-data-content" style="width: 10em;">
                                    <!-- <img (click)="viewOfferDetails(offer)" style="height: 1.1rem;margin-right: 1em;cursor: pointer;" src='../../../assets/img/view.png'> -->
                                    <div style="display: contents;">
                                        <img (click)="editSport(sport)"
                                            style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                            src='../../../assets/img/editing.png'>
                                        <img (click)="deleteSport(sport)"
                                            style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                            src='../../../assets/img/delete.png'>
                                    </div>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-right" style="font-size: medium;margin: 1em;">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div>


            </div>
        </ng-sidebar-container>
    </div>