<div class="container__campaignList">
  <div class="container__campaignList__title">
    <div class="container__campaignList__title__text1">
      {{ businessId == null || businessId == undefined ? "Add" : "Edit" }} Business
    </div>
  </div>

  <div class="container__campaignList__tabs">
    <div class="container__campaignList__tabs__box">
      <mat-horizontal-stepper>
        <mat-step label="Business Details" class="custom-mat-step">
          <div class="dashedLine"></div>
          <div class="container_editUser">
            <div class="container_editUser_main">
                <div class="container_editUser_title">Business Details</div>
                <div>
                    <form [formGroup]="addBusinessForm">
                        <div class="row">
                            <div class="col-md-6">
                                <label for="firstName" class="label mt-5">First Name</label>
                                <input type="text" class="input-style" id="firstName" placeholder="First Name" formControlName="firstName"/>
                            </div>
                            <div class="col-md-6">
                                <label for="lastName" class="label mt-5">Last Name</label>
                                <input type="text" class="input-style" id="lastName" placeholder="Last Name" formControlName="lastName"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="phone" class="label mt-5">Phone Number</label>
        
                                <div style="display: flex">
                                    <input type="text" class="input-style" id="phone" minlength="10" maxlength="10"
                                        placeholder="Enter phone" formControlName="phone"/>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="email" class="label mt-5">Email</label>
                                <input type="email" class="input-style" id="email"
                                    placeholder="Enter email" formControlName="email"/>
                            </div>
                        </div>
                        <div class="row">
                            <div for="pancardNumber" class="col-md-6">
                                <label class="label mt-5">Pan Card Detials</label>
                                <input type="text" class="input-style" id="pancardNumber"
                                    placeholder="Pan card Number" formControlName="pancardNumber"/>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="buttons">
                    <button *ngIf="isAdd" class="btn-1" mat-button (click)="cancelBtnHandler()">
                        Cancel
                    </button>
                    <button *ngIf="!isAdd" class="btn-1" mat-button (click)="closeEditBtnHandler()">
                      Cancel
                  </button>
                    <button class="btn" mat-button matStepperNext>
                      <div *ngIf="!isAddEditAboutLoading">
                        {{isAdd?'Save Details & Continue':'Save Changes'}}
                    </div>
                        <!-- <div>
                            <mat-progress-spinner mode="indeterminate" diameter="20" style="margin: 0 auto">
                            </mat-progress-spinner>
                        </div> -->
                    </button>
                </div>
            </div>
        </div>
        </mat-step>

        <mat-step label="Bank Details" class="custom-mat-step">
          <div class="dashedLine"></div>
          <div class="container_editUser">
            <div class="container_editUser_main">
                <div class="container_editUser_title">Bank Details</div>
                <div>
                    <form [formGroup]="addBusinessForm">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="label mt-5">Account Holder Name</label>
                                <input type="text" class="input-style"  placeholder="Account Holder Name" formControlName="accountHolderName"/>
                            </div>
                            <div class="col-md-6">
                                <label class="label mt-5">Bank Name</label>
                                <input type="text" class="input-style"  placeholder="Bank Name" formControlName="bankName"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="label mt-5">Account Number</label>
        
                                <div style="display: flex">
                                    <input type="text" class="input-style"
                                        placeholder="Account Number" formControlName="accountNumber"/>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="label mt-5">IFSC Code</label>
                                <input type="email" class="input-style" 
                                    placeholder="IFSC Code" formControlName="ifscCode"/>
                            </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <label class="label  mt-5" for="">Cancelled Cheque Leaf</label>
                          <div
                            *ngIf="!cancelledChequeLaefPreview"
                            class="uploadBox"
                          >
                            <label
                              for="upload"
                              style="
                                color: #253b86;
                                font-size: 1.44rem;
                                cursor: pointer;
                                font-weight: 500;
                                display: flex;
                                width: 100%;
                                align-items: center;
                                justify-content: center;
                              "
                              class="upload"
                              ><img
                                src="../../../../../assets/svgIcons/upload-img.svg"
                                alt=""
                                srcset=""
                            /></label>
                            <div style="color: #5e5e5e; font-size: 1rem; font-weight: 400; display: contents;">
                              <span style="color: #798dff">Browse files</span>,
                              Supports jpeg and jpg format
                            </div>
                            <div style="color: #acacb4; font-size: 1rem; font-weight: 400; padding-top: 1rem;">
                              Less than 2 Mb. 280 x 203 px
                            </div>
                            <input
                              type="file"
                              id="upload"
                              for="upload"
                              style="display: none"
                              accept="image/*"
                              (change)="selectCancelledLeafChequeFile($event)"
                            
                            />
                          </div>
                          <div
                            *ngIf="cancelledChequeLaefPreview"
                            class="uploadBox"
                            style="position: relative; height: 16rem; width: 18rem;"
                          >
                            <img
                              *ngIf="cancelledChequeLaefPreview"
                              (click)="removeCancelledLeafChequeImage()"
                              class="removeImage"
                              src="../../../../../assets/svgIcons/close.svg"
                              alt=""
                              srcset=""
                            />
                            <img
                              style="height: 16rem; width: 18rem; border-radius: 0.5rem"
                              [src]="cancelledChequeLaefPreview"
                              alt=""
                              srcset=""
                            />
                          </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <label class="label  mt-5" for="">Pan card</label>
                          <div
                            *ngIf="!pancardPreview"
                            class="uploadBox"
                          >
                            <label
                              for="upload"
                              style="
                                color: #253b86;
                                font-size: 1.44rem;
                                cursor: pointer;
                                font-weight: 500;
                                display: flex;
                                width: 100%;
                                align-items: center;
                                justify-content: center;
                              "
                              class="upload"
                              ><img
                                src="../../../../../assets/svgIcons/upload-img.svg"
                                alt=""
                                srcset=""
                            /></label>
                            <div style="color: #5e5e5e; font-size: 1rem; font-weight: 400; display: contents;">
                              <span style="color: #798dff">Browse files</span>,
                              Supports jpeg and jpg format
                            </div>
                            <div style="color: #acacb4; font-size: 1rem; font-weight: 400; padding-top: 1rem;">
                              Less than 2 Mb. 280 x 203 px
                            </div>
                            <input
                              type="file"
                              id="upload"
                              for="upload"
                              style="display: none"
                              accept="image/*"
                              (change)="selectPancardFile($event)"
                            
                            />
                          </div>
                          <div
                            *ngIf="pancardPreview"
                            class="uploadBox"
                            style="position: relative; height: 16rem; width: 18rem;"
                          >
                            <img
                              *ngIf="pancardPreview"
                              (click)="removePancardImage()"
                              class="removeImage"
                              src="../../../../../assets/svgIcons/close.svg"
                              alt=""
                              srcset=""
                            />
                            <img
                              style="height: 16rem; width: 18rem; border-radius: 0.5rem"
                              [src]="pancardPreview"
                              alt=""
                              srcset=""
                            />
                          </div>
                          <label *ngIf="pancardPreview!=null  && pancardPreview.includes('https')"
                                    class="imageLabelDownload" (click)="downloadImage(pancardPreview)">
                                    <mat-icon class="matIcon">download</mat-icon>
                                </label>
                          </div>
                        </div>
                    </form>
                </div>
                <div class="buttons">
                  <button class="btn-1" mat-button (click)="cancelBtnHandler()" matStepperPrevious>
                    Cancel
                  </button>
                  <button class="btn" mat-button (click)="isAddEditAboutLoading?null:onSubmitBusiness()">
                    <div *ngIf="!isAddEditAboutLoading">
                      {{'Save Details & Continue'}}
                    </div>
                    <div *ngIf="isAddEditAboutLoading">
                      <mat-progress-spinner mode="indeterminate" diameter="20" style="margin: 0 auto">
                      </mat-progress-spinner>
                    </div>
                  </button>
                </div>
            </div>
        </div>
        </mat-step>
      </mat-horizontal-stepper>
      <!-- <mat-stepper></mat-stepper> -->
    </div>
  </div>

  <!-- <div style="grid-column: 1/-1">
      <router-outlet></router-outlet>
    </div> -->
</div>
