<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-edit-rental *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)"
                [dataToEdit]="dataToEdit">
            </app-add-edit-rental>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">
            <!-- Top Data -->
            <div class="row align-center">
                <div class="top-heading col-md-10">
                    Turf Rentals
                </div>
                <div class="col-md-2">
                    <button class="add-new-emp" (click)="addNewItem()">
                        Add New Item
                    </button>
                </div>
            </div>
            <!-- Table Data -->
            <div *ngIf="isLoading" class="display-flex align-items-center" style="height: 60vh;">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>

            <div *ngIf="listOfRentals.length==0 && !isLoading">
                <div class="no-available">
                    No item available.
                </div>
            </div>

            <div *ngIf="listOfRentals.length>0 && !isLoading">
                <table class="table-style">
                    <thead class="table-heading">
                        <th style="cursor: pointer;" data-order="desc" data-name="item" scope="col"
                            class="table-heading-content">Item Name</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="sport" scope="col"
                            class="table-heading-content">Sport</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="price" scope="col"
                            class="table-heading-content">Price</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="duration" scope="col"
                            class="table-heading-content">Duration</th>
                        <!-- <th style="cursor: pointer;" data-order="desc" data-name="ownerPhone" scope="col"
                                class="table-heading-content">Status</th> -->
                        <th style="cursor: pointer;" data-order="desc" data-name="ownerName" scope="col"
                            class="table-heading-content">Edit</th>
                        <th style="cursor: pointer;" data-order="desc" data-name="ownerPhone" scope="col"
                            class="table-heading-content">Delete</th>

                    </thead>
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let item of listOfRentals | paginate: { itemsPerPage: count, currentPage: p }">
                            <th style="cursor: pointer;" class="table-data-content">
                                {{item['productName']}}</th>
                            <th style="cursor: pointer;" class="table-data-content">
                                {{item['sport']['sport']}}</th>
                            <th class="table-data-content">{{item['price']}}</th>
                            <th style="width: 10em;" class="table-data-content">{{item['duration']}}</th>
                            <!-- <th class="table-data-content" style="padding-left: 2rem;">
                                    <img *ngIf="turf.isActive"
                                        class="table-data-content__status-icon table-data-content__status-icon-on"
                                        src="../../../assets/svgIcons/switch-on.svg" style="width: 2rem;"
                                        (click)="suspendTurf(turf,false)" slot="start" />
                                    <img *ngIf="!turf.isActive"
                                        class="table-data-content__status-icon table-data-content__status-icon-off"
                                        src="../../../assets/svgIcons/switch-off.svg" style="width: 2rem;"
                                        (click)="suspendTurf(turf,true)" slot="start" />
                                </th> -->
                            <th class="table-data-content" style="width:10em;text-align-last: center;">
                                <div style="display: contents;">
                                    <img title="Edit" (click)="editRental(item)"
                                        style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>
                                    <!-- <img title="Resume" *ngIf="!turf.isActive" (click)="suspendTurf(turf,true)"
                                            style="height: 1.5rem;margin-right: 1em;cursor: pointer;"
                                            src='../../../assets/img/visibility.png'> -->
                                    <!-- <img title="View" style="height: 1.5rem;margin-right: 1em;cursor: pointer;"
                                            src='../../../assets/img/view.png'>

                                        <img title="Delete" (click)="deleteItem(item)"
                                            style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                            src='../../../assets/img/delete.png'> -->
                                </div>

                            </th>
                            <th class="table-data-content" style="width:10em;text-align-last: center;">
                                <div style="display: contents;">

                                    <img title="Delete" (click)="deleteItem(item)"
                                        style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/delete.png'>
                                </div>

                            </th>
                        </tr>

                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>

            </div>
        </div>
    </ng-sidebar-container>
</div>


<!-- <div class="main-container">
    <div class="row align-center">
        <div class="top-heading col-md-10">
            Turf Rentals
        </div>
        <div class="col-md-2">
            <button class="add-new-emp" (click)="addNewItem()">
                Add New Item
            </button>
        </div>
    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height: 79vh;" *ngIf="!isLoading">
        <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="overflow: hidden;">
            <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right"
                [dock]="dock" [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
                [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
                [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
                <app-add-edit-rental *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)"
                    [dataToEdit]="dataToEdit">
                </app-add-edit-rental>
            </ng-sidebar>
            <div ng-sidebar-content style="overflow: hidden;padding: 1em;">

                <div *ngIf="listOfRentals.length==0">
                    <div class="no-available">
                        No item available.
                    </div>
                </div>
                <div *ngIf="listOfRentals.length!=0">
                    <table class="table-style">
                        <thead class="table-heading">
                            <th style="cursor: pointer;" data-order="desc" data-name="item" scope="col"
                                class="table-heading-content">Item Name</th>
                            <th style="cursor: pointer;" data-order="desc" data-name="sport" scope="col"
                                class="table-heading-content">Sport</th>
                            <th style="cursor: pointer;" data-order="desc" data-name="price" scope="col"
                                class="table-heading-content">Price</th>
                            <th style="cursor: pointer;" data-order="desc" data-name="duration" scope="col"
                                class="table-heading-content">Duration</th>
                           
                            <th style="cursor: pointer;" data-order="desc" data-name="ownerName" scope="col"
                                class="table-heading-content">Edit</th>
                            <th style="cursor: pointer;" data-order="desc" data-name="ownerPhone" scope="col"
                                class="table-heading-content">Delete</th>

                        </thead>
                        <tbody>
                            <tr class="table-data"
                                *ngFor="let item of listOfRentals | paginate: { itemsPerPage: count, currentPage: p }">
                                <th style="cursor: pointer;" class="table-data-content">
                                    {{item['productName']}}</th>
                                <th style="cursor: pointer;" class="table-data-content">
                                    {{item['sport']['sport']}}</th>
                                <th class="table-data-content">{{item['price']}}</th>
                                <th style="width: 10em;" class="table-data-content">{{item['duration']}}</th>
                              
                                <th class="table-data-content" style="width:10em;text-align-last: center;">
                                    <div style="display: contents;">
                                        <img title="Edit" (click)="editRental(item)"
                                            style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                            src='../../../assets/img/editing.png'>
                                       
                                    </div>

                                </th>
                                <th class="table-data-content" style="width:10em;text-align-last: center;">
                                    <div style="display: contents;">

                                        <img title="Delete" (click)="deleteItem(item)"
                                            style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                            src='../../../assets/img/delete.png'>
                                    </div>

                                </th>
                            </tr>

                        </tbody>
                    </table>
                    <div class="text-right" style="font-size: medium;margin: 1em;">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div>


            </div>
        </ng-sidebar-container>
    </div>
</div> -->