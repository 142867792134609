<div style="width: 35em;position: relative;">
    <div class="clear-button" (click)="close()">
        <mat-icon class="mat-clear-icon">clear</mat-icon>
    </div>
    <h2 mat-dialog-title style="font-weight: 500;">Business Detail</h2>
    <hr>
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12" style="display: flex;">

                    <div style="width: 7em;">
                        Business ID:
                    </div>
                    <div style="width: 9em;font-size: 80%;">
                        {{data['business']['_id'] }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display: flex;">

                    <div style="width: 7em;">
                        Owner Name:
                    </div>
                    <div style="width: 9em;font-size: 80%;">
                        {{data['business']['userName'] }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display: flex;">

                    <div style="width: 7em;">
                        Owner Phone:
                    </div>
                    <div style="width: 9em;font-size: 80%;">
                        {{data['business']['phone'] }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display: flex;">
                    <div style="width: 7em;">
                        Registration Date:
                    </div>
                    {{data['business']['createdAt'] | date}}
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12" style="display: flex;">
                    <div style="font-weight: 600;">
                        Bank Details:-
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display: flex;">
                    <div style="font-weight: 500;">
                        Holder Name:- {{data['business']['bankDetails'][0]['accountHolderName']}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display: flex;">
                    <div>
                        Account No:- {{data['business']['bankDetails'][0]['accountNumber']}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display: flex;">
                    <div>
                        Bank Name:- {{data['business']['bankDetails'][0]['bankName']}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display: flex;">
                    <div>
                        IFSC Code:- {{data['business']['bankDetails'][0]['ifscCode']}}
                    </div>
                </div>
            </div>

        </div>
    </div>
    <hr>
    <div>
        <h3 style="font-weight: 500;">Document</h3>

        <div class="row" *ngFor="let item of _albums; let i=index">
            <div class="col-md-12" style="font-size: 13px;font-weight: 500;margin-bottom: 5px;">
                {{item['docType']}} Number: {{item['docNumber']}}
            </div>
            <div *ngIf="item['docUrl']!=''" class="col-md-12"
                style="font-size: 11.5px;font-weight: 500;margin-bottom: 5px;">
                {{item['docType']}} Image:-
            </div>

            <div *ngIf="item['docUrl']!=''" class="col-md-12" style="display: flex;align-items: center;">
                <img class="prod-img" [src]="item.thumb" (click)="openPopup(_albums,i)" />
            </div>
        </div>
    </div>

    <div>
        <!-- <h3 style="font-weight: 500;">Bank Details</h3> -->
        <div class="row" *ngFor="let item of _bank; let i=index">
            <!-- <div class="col-md-12" style="font-size: 13px;font-weight: 500;margin-bottom: 5px;">
                Bank Name: {{item['bankName']}}
            </div>
            <div class="col-md-12" style="font-size: 13px;font-weight: 500;margin-bottom: 5px;">
                Account Number: {{item['accountNumber']}}
            </div>
            <div class="col-md-12" style="font-size: 13px;font-weight: 500;margin-bottom: 5px;">
                Account Holder Name: {{item['accountHolderName']}}
            </div>
            <div class="col-md-12" style="font-size: 13px;font-weight: 500;margin-bottom: 5px;">
                IFSC Code: {{item['ifscCode']}}
            </div> -->
            <div *ngIf="item['docUrl']!=''" class="col-md-12"
                style="font-size: 11.5px;font-weight: 500;margin-bottom: 5px;">
                Cancelled Cheque Image:-
            </div>
            <div *ngIf="item['docUrl']!=''" class="col-md-12" style="display: flex;align-items: center;">
                <img class="prod-img" [src]="item.thumb" (click)="openPopup(_bank,i)" />

                <div class="prod-name" style="margin-left: 1em;">
                    {{item['docNumber']}}
                </div>
            </div>
        </div>
    </div>
    <hr>

    <div class="row" *ngIf="data['business']['status']!='PENDING'">
        <div class="col-md-12">

            <div style="display: contents;" *ngIf="data['business']['status']!='PENDING'"
                [ngClass]="{'rejected-order':data['business']['status']=='REJECTED','accepted-order':data['business']['status']=='PENDING' }">
                {{data['business']['status']=='REJECTED'?'Rejected':(data['business']['status']=='APPROVED'? 'Approved'
                :'Pending')}}
            </div>

        </div>
    </div>
    <div class="row" *ngIf="data['business']['status']=='PENDING'">

        <div class="col-md-12" style="display: flex;align-items: center;">

            <input type="radio" name="radio-btn" id="Approve" name="Approve" [checked]="isAcceptButtonSelected"
                (change)="radioChangeHandler($event)">
            <label for="Approve" class="lbl-radio">Approve</label>
            <input type="radio" name="radio-btn" id="Reject" name="Reject" [checked]="isRejectButtonSelected"
                (change)="radioChangeHandler($event)">
            <label for="Reject" class="lbl-radio">Reject</label>
        </div>
    </div>

    <div *ngIf="isAcceptButtonSelected">
        <div>
            <div style="width: 25em;font-size: medium;margin-top: 1em;" mat-dialog-content>Are you sure you want to
                approve this Business?</div>

        </div>
        <div mat-dialog-actions>
            <button class="canel-btn" mat-dialog-close (click)="close()">No</button>
            <button class="ok-btn" (click)="acceptBusiness()">Yes</button>
        </div>
    </div>
    <div *ngIf="isRejectButtonSelected">
        <div>
            <label for="remark" class="label required">Remark</label>
            <input type="text" id="remark" class="input-style" placeholder="Enter remark" [(ngModel)]="remark"
                [ngModelOptions]="{standalone: true}">
        </div>
        <div mat-dialog-actions>
            <button class="no-btn" mat-dialog-close (click)="close()">No</button>
            <button class="yes-btn" [disabled]="remark==null" (click)="remark==null? null:rejectBusiness()">Yes</button>
        </div>
    </div>
</div>