import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { webApi } from 'src/app/api';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.scss']
})
export class AddressDetailsComponent implements OnInit {
  @Input() isAdd: boolean = false;
  @Output() backToPreviousStepper: EventEmitter<any> = new EventEmitter();
  @Output() closeEdit: EventEmitter<any> = new EventEmitter();

  userDetails: any;
  turfId: any;
  turfInfo: any;
  turfEditId: any;

  isLoading: boolean = true;
  turfAddressForm: FormGroup;
  formdata = new FormData();

  isAddEditAddressLoading: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private appService: AppService,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
    this.turfEditId = this._activatedRoute.snapshot.paramMap.get('id');
    console.log(this.turfEditId);

    this._activatedRoute.queryParams.subscribe(params => {
      this.turfId = params['id'];
      console.log(this.turfId);
    });

    this.turfAddressForm = this._formBuilder.group({
      streetName: new FormControl('', [Validators.required]),
      landmark: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    if(this.turfEditId){
      this.fetchTurfByIdV2();
    }
  }

  fetchTurfByIdV2() {
    this.isLoading = true;
    try {
      let URL = '';
      URL = `${webApi.domain + webApi.endPoint.fetchTurfByIdV2 + `/${this.turfEditId}`}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      this.appService.getMethodWithToken(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.turfInfo = data.result;
          console.log('turfInfo', this.turfInfo);
          this.turfAddressForm.get('streetName')?.setValue(this.turfInfo['address']['streetName']);
          this.turfAddressForm.get('landmark')?.setValue(this.turfInfo['address']['landmark']);
          this.turfAddressForm.get('pincode')?.setValue(this.turfInfo['address']['pincode']);
          this.turfAddressForm.get('city')?.setValue(this.turfInfo['address']['city']);
          this.turfAddressForm.get('state')?.setValue(this.turfInfo['address']['state']);
        }
        this.isLoading = false;
      });
    } catch (err) {
      this.isLoading = false;
      console.log(err);
    }
  }

  cancelBtnHandler() {
    this.backToPreviousStepper.emit();
  }

  onSubmitAddress() {
    this.isAddEditAddressLoading = true;

    if (this.turfId != null) {
      this.formdata.set('turfId', this.turfId);
    }

    this.formdata.set('streetName', this.turfAddressForm.get('streetName')?.value);
    this.formdata.set('landmark', this.turfAddressForm.get('landmark')?.value);
    this.formdata.set('pincode', this.turfAddressForm.get('pincode')?.value);
    this.formdata.set('city', this.turfAddressForm.get('city')?.value);
    this.formdata.set('state', this.turfAddressForm.get('state')?.value);

    var requestOptions: RequestInit = {
      method: 'POST',
      body: this.formdata,
      redirect: 'follow',
      headers: new Headers({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      }),
    };

    const URL = `${webApi.domain + webApi.endPoint.addEditTurfAddressInfoV2}`;

    try {
      fetch(URL, requestOptions).then((result) => {
        if (result) {
          let data = result.json();
          data.then((res) => {
            if (res['success']) {
              this.isAddEditAddressLoading = false;
              if (this.turfId != null) {
                this.showSuccessToaster('Turf Address edited successfully', 'Success');
              } else {
                this.showSuccessToaster('Turf Address added successfully', 'Success');
              }
              this.closeEdit.emit();
            } else {
              this.isAddEditAddressLoading = false;
              if (this.turfId != null) {
                this.showWarningToaster(res['message'], 'Warning');
              } else {
                this.showWarningToaster(res['message'], 'Warning');
              }
            }
          });
        }
      });
    } catch (error) {
      this.isAddEditAddressLoading = false;
      if (this.turfId != null) {
        this.showWarningToaster('Unable to edit Turf Address', 'Warning');
      } else {
        this.showWarningToaster('Unable to add Turf Address', 'Warning');
      }
    }
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }

}
