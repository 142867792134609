<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div *ngIf="!isLoading" class="container-fluid main-content" id="container-wrapper">
    <!-- <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="./">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
        </ol>
    </div> -->

    <div class="row mb-3" style="width: 100%;">

        <div class="col-md-12 heading-text" style="justify-content: space-between;display: flex;align-items: center;">
            <div class="dashboard-div">
                Dashboard
            </div>
            <div class="col-auto filter" mat-button [matMenuTriggerFor]="matMenuDuration">
                <div>

                    {{selectedEarningDuration}}
                </div>
                <i class="fas fa-chevron-down"></i>
                <mat-menu #matMenuDuration="matMenu">
                    <button mat-menu-item *ngFor="let data of listOfDuration" (click)="changeDuration(data,'Pickup')">{{data}}</button>
                </mat-menu>
            </div>
        </div>

        <!-- Earnings (Monthly) Card Example -->
        <div class="col-xl-3 col-md-6 mb-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-uppercase mb-1">Earnings ({{selectedEarningDuration}})
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">{{'\u20b9'+totalEarning.toFixed(2)}}
                            </div>
                            <div class="mt-2 mb-0 text-muted text-xs">
                                <span [ngClass]="{'text-success':totalEarningPercentageChange>=0,'text-danger':totalEarningPercentageChange<0}" class="mr-2"><i class="fas"
                                        [ngClass]="{'fa-arrow-up':totalEarningPercentageChange>=0,'fa-arrow-down':totalEarningPercentageChange<0}"></i>
                                    {{totalEarningPercentageChange.toFixed(2)}}%</span>

                                <span>Since {{selectedEarningDuration=='Monthly'?'last month':
                                    (selectedEarningDuration=='Weekly'?'last week': (selectedEarningDuration=='Current\
                                    Quarter'?'last quarter': (selectedEarningDuration=='Yearly'?'last year':
                                    'yesterday')))}}</span>
                            </div>
                        </div>
                        <div class="col-auto" style="cursor: pointer;">
                            <i class="fas fa-calendar fa-2x text-primary"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Earnings (Annual) Card Example -->
        <div class="col-xl-3 col-md-6 mb-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-uppercase mb-1">Sales ({{selectedSalesDuration}})
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">{{totalSales}}</div>
                            <div class="mt-2 mb-0 text-muted text-xs">
                                <span [ngClass]="{'text-success':totalSalesPercentageChange>=0,'text-danger':totalSalesPercentageChange<0}" class="mr-2"><i class="fas"
                                        [ngClass]="{'fa-arrow-up':totalSalesPercentageChange>=0,'fa-arrow-down':totalSalesPercentageChange<0}"></i>
                                    {{totalSalesPercentageChange.toFixed(2)}}%</span>

                                <span>Since {{selectedSalesDuration=='Monthly'?'last month':
                                    (selectedSalesDuration=='Weekly'?'last week': (selectedSalesDuration=='Current\
                                    Quarter'?'last quarter': (selectedSalesDuration=='Yearly'?'last year':
                                    'yesterday')))}}</span>
                            </div>
                        </div>
                        <div class="col-auto" style="cursor: pointer;">
                            <i class="fas fa-shopping-cart fa-2x text-success"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- New User Card Example -->
        <div class="col-xl-3 col-md-6 mb-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-uppercase mb-1">New User ({{selectedUserDuration}})
                            </div>
                            <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{{totalUser}}</div>
                            <div class="mt-2 mb-0 text-muted text-xs">
                                <span [ngClass]="{'text-success':totalUserPercentageChange>=0,'text-danger':totalUserPercentageChange<0}" class="mr-2"><i class="fas"
                                        [ngClass]="{'fa-arrow-up':totalUserPercentageChange>=0,'fa-arrow-down':totalUserPercentageChange<0}"></i>
                                    {{totalUserPercentageChange.toFixed(2)}}%</span>
                                <span>Since {{selectedUserDuration=='Monthly'?'last month':
                                    (selectedUserDuration=='Weekly'?'last week': (selectedUserDuration=='Current\
                                    Quarter'?'last quarter': (selectedUserDuration=='Yearly'?'last year':
                                    'yesterday')))}}</span>
                            </div>
                        </div>
                        <div class="col-auto" style="cursor: pointer;">
                            <i class="fas fa-users fa-2x text-info"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Pending Requests Card Example -->
        <div class="col-xl-3 col-md-6 mb-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-uppercase mb-1">Pending Business ({{selectedRequestDuration}})
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">{{totalRequest}}</div>
                            <div class="mt-2 mb-0 text-muted text-xs">
                                <span [ngClass]="{'text-success':totalRequestPercentageChange>=0,'text-danger':totalRequestPercentageChange<0}" class="mr-2"><i class="fas"
                                        [ngClass]="{'fa-arrow-up':totalRequestPercentageChange>=0,'fa-arrow-down':totalRequestPercentageChange<0}"></i>
                                    {{totalRequestPercentageChange.toFixed(2)}}%</span>
                                <span>Since {{selectedRequestDuration=='Monthly'?'last month':
                                    (selectedRequestDuration=='Weekly'?'last week': (selectedRequestDuration=='Current\
                                    Quarter'?'last quarter': (selectedRequestDuration=='Yearly'?'last year':
                                    'yesterday')))}}</span>
                            </div>
                        </div>
                        <div class="col-auto" style="cursor: pointer;">
                            <i class="fas fa-comments fa-2x text-warning"></i>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Booking Table -->
        <div class="col-xl-8 col-lg-7 mb-4">
            <div class="card">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h4 class="m-0 font-weight-bold text-primary">Booking</h4>
                    <div class="m-0 float-right btn btn-danger btn-sm" (click)="gotoBooking()">View More <i class="fas fa-chevron-right"></i></div>
                </div>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Turf</th>
                                <th>Date</th>
                                <th>Sport</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="listOfBooking.length==0">
                            <tr>
                                <td></td>
                                <td></td>
                                <td style="display: flex;justify-content: center; width: 100%; margin-top: 2em; margin-bottom: 2em;">
                                    No Booking details available yet
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="listOfBooking.length>0">
                            <tr *ngFor="let data of listOfBooking">
                                <td>{{data['user']['firstName'] +' '+ data['user']['lastName']}}</td>
                                <td>{{data['user']['phone']}}</td>
                                <td>{{data['turfId']['name']}}</td>
                                <td><span>{{data['bookingDate'] |date}}</span></td>
                                <td>{{data['sport']['sport']}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="card-footer"></div>
            </div>
        </div>

        <!-- Pie Chart -->
        <div class="col-xl-4 col-lg-5">
            <div class="card mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h4 class="m-0 font-weight-bold text-primary">Sport Type</h4>
                    <div class="dropdown no-arrow">
                        <a mat-button [matMenuTriggerFor]="matMenuSportType" class="dropdown-toggle btn btn-primary btn-sm">
                            {{selectedSportTypeDuration}} <i class="fas fa-chevron-down"></i>
                        </a>
                        <mat-menu #matMenuSportType="matMenu">
                            <button mat-menu-item *ngFor="let data of listOfDuration" (click)="onSportMenuChange(data)">{{data}}</button>
                        </mat-menu>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngIf="isSportCategoryLoading">
                        <div class="card-spinner-loading">
                            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                            </mat-progress-spinner>
                        </div>
                    </div>
                    <div *ngIf="listOfSports.length==0 && !isSportCategoryLoading">
                        <div style="display: flex;justify-content: center; width: 100%; margin-top: 2em; margin-bottom: 2em;">
                            No Sports Booking Done yet
                        </div>
                    </div>
                    <div *ngIf="listOfSports.length>0 && !isSportCategoryLoading">

                        <div class="mb-3" *ngFor="let data of listOfSports">
                            <div class="small text-gray-500">{{data['name']}}
                                <div class="small float-right"><b>{{data['sportBooking']}} of {{data['totalBooking']}}
                                        Booking</b></div>
                            </div>
                            <div class="progress" style="height: 12px;">
                                <div [ngClass]="{'bg-warning':data['name']=='Cricket' || data['name']=='Snooker',
                            'bg-danger':data['name']=='Football',
                            'bg-success':data['name']=='Badminton',
                            'bg-info':data['name']=='Table Tennis'}" class="progress-bar" role="progressbar" [ngStyle]="{'width.%': (data.sportBooking/data.totalBooking)*100}"></div>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- <div class="card-footer text-center">
                    <a class="m-0 small text-primary card-link" href="#">View More <i
                            class="fas fa-chevron-right"></i></a>
                </div> -->
            </div>
        </div>

        <!-- Message From Customer-->
        <!-- <div class="col-xl-4 col-lg-5 ">
            <div class="card">
                <div class="card-header py-4 bg-primary d-flex flex-row align-items-center justify-content-between">
                    <h4 class="m-0 font-weight-bold text-light">Message From Customer</h4>
                </div>
                <div>
                    <div class="customer-message align-items-center">
                        <a class="font-weight-bold" href="#">
                            <div class="text-truncate message-title">Hi there! I am wondering if you can help me with a problem I've been having.</div>
                            <div class="small text-gray-500 message-time font-weight-bold">Udin Cilok · 58m</div>
                        </a>
                    </div>
                    <div class="customer-message align-items-center">
                        <a href="#">
                            <div class="text-truncate message-title">But I must explain to you how all this mistaken idea
                            </div>
                            <div class="small text-gray-500 message-time">Nana Haminah · 58m</div>
                        </a>
                    </div>
                    <div class="customer-message align-items-center">
                        <a class="font-weight-bold" href="#">
                            <div class="text-truncate message-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit
                            </div>
                            <div class="small text-gray-500 message-time font-weight-bold">Jajang Cincau · 25m</div>
                        </a>
                    </div>
                    <div class="customer-message align-items-center">
                        <a class="font-weight-bold" href="#">
                            <div class="text-truncate message-title">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                            </div>
                            <div class="small text-gray-500 message-time font-weight-bold">Udin Wayang · 54m</div>
                        </a>
                    </div>
                    <div class="card-footer text-center">
                        <a class="m-0 small text-primary card-link" href="#">View More <i
                                class="fas fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
        </div> -->
    </div>




</div>