<div class="container__employeeList">
    <div class="container__employeeList__header">
        <div class="container__employeeList__header__title">
            Business Management List 
        </div>
        <div class="searchBox">
            <div class="col-md-6" style="text-align: right;">
                <input type="text" class="input-field" name="searchQuery" id="searchQuery"
                    placeholder="Search Here" (input)="searchData($event.target)">
            </div>
            <div style="margin-left: 2rem">
                <button class="container__employeeList__header__addBtn" (click)="navigateToAddBusiness()">
                    <div>Add New Business</div>
                </button> 
            </div>
        </div>
    </div>
    <div class="container__employeeList__list">
        <div class="container__employeeList__list__box">
            <div class="container__employeeList__list__box__header">
                <div class="listTitle listTitle-1">Owner Name</div>
                <div class="listTitle listTitle-2">Owner Phone</div>
                <div class="listTitle listTitle-3">Owner Email</div>
                <div class="listTitle listTitle-4">Creation Date</div>
                <div class="listTitle listTitle-5">Status</div>
                <div class="listTitle listTitle-6">View Turfs</div>
                <div class="listTitle listTitle-5">Active</div>
                <div class="listTitle listTitle-5">Action</div>

            </div>
            <div class="container__employeeList__list__box__content"  *ngIf="!isLoading">
                <div  class="noData" style="height: 100%" *ngIf="listOfBusinessToDisplay.length == 0">
                    No Data Available To Show !
                </div>
                <ng-container *ngIf="listOfBusinessToDisplay.length != 0">
                    <div class="container__employeeList__list__box__listItem" 
                    *ngFor="let business of listOfBusinessToDisplay | paginate: { itemsPerPage: count, currentPage: p }">

                        <div class="listItem listItem-1" (click)="viewBusinessDetails(business)">{{business.userName}}</div>
                        <div class="listItem listItem-2" (click)="viewBusinessDetails(business)">{{business.phone}}</div>
                        <div class="listItem listItem-3" (click)="viewBusinessDetails(business)">{{business.email}}</div>
                        <div class="listItem listItem-4">{{business.createdAt|date}}</div>
                        <div class="listItem listItem-5">{{business.status}}</div>
                <div class="listTitle listItem-6" (click)="viewTurfOfBusiness(business)">view</div>

                        <div class="listItem listItem-5">
                            <img *ngIf="business.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-on"
                                    src="../../../assets/svgIcons/switch-on.svg" style="width: 2rem;"
                                    (click)="suspendBusiness(business,false)" slot="start" />
                                <img *ngIf="!business.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-off"
                                    src="../../../assets/svgIcons/switch-off.svg" style="width: 2rem;"
                                    (click)="suspendBusiness(business,true)" slot="start" />
                        </div>
                <div class="listTitle listTitle-5">
                    <img title="Edit"
                    style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                    src='../../../assets/img/editing.png' (click)="editBusinessDetail(business)">

                    <img title="View" (click)="viewBusinessDetails(business)"
                        style="height: 1.5rem;margin-right: 1em;cursor: pointer;"
                        src='../../../assets/img/view.png'>
                    <img title="Delete" (click)="deleteBusiness(business)"
                    style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                    src='../../../assets/img/delete.png'>
                </div>

                    </div>
                </ng-container>

            </div>
            <div class="container__employeeList__list__box__content" *ngIf="listOfBusinessToDisplay.length == 0"
                style="display: flex; align-items: center">
                <mat-progress-spinner mode="indeterminate" diameter="30" style="margin: 0 auto">
                </mat-progress-spinner>
            </div>
            <div class="text-right" style="font-size: medium;margin: 1em;">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
        </div>
    </div>

    <div class="text-right" style="font-size: medium; margin: 1em" *ngIf="isLoadingExtraData">
        <mat-progress-spinner mode="indeterminate" diameter="20" style="margin: 0 auto">
        </mat-progress-spinner>
    </div>
</div>
