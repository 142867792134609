<!-- <ng-container>
    <mat-toolbar class="main-catalog-header">
        <div class="header-row">
            <div>
            </div>
            <div style="display:flex;align-items: center;">
                <img src="https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png" alt="Logo" class="top-container__admin-logo">

                <div style="margin-top:1em;">
                    <div style="line-height:1px;font-size: 15px;margin-left: 1em;color: rgb(45, 45, 45);">

                        {{userDetail['name']}}
                    </div>
                    <div style="font-size: 10px;margin-left: 1.5em;color: slategrey;">
                        Admin
                    </div>
                </div>
            </div>
        </div>
    </mat-toolbar>

    <mat-sidenav-container class="main-catalog-container">
        <mat-sidenav #sidenav mode="side" opened class="main-catalog-sidenav" [fixedInViewport]="true">
            <div style="padding-top: 5em; margin-top: .5rem;">
                <div *ngFor="let data of this.as.sidebarOptions">
                    <div class="sidenav-component sidenav-row" (click)="this.as.gotoComponent(data['title'],this.userDetail['role'])">
                        <div>
                            <img [ngClass]="{ 'svg-style-selected': data['isSelected'] , 'svg-style-unselected': !data['isSelected'] }" class="svg-style" [src]="data['svgUrl']" alt="" srcset="">
                        </div>
                        <span [ngClass]="{ 'sidebar-text-selected': data['isSelected'] , 'sidebar-text-unselected': !data['isSelected'] }" class="sidebar-text">{{data['title']}}</span>
                    </div>
                </div>
            </div>

            <div class="sidenav-component " style="margin-top: 3rem; font-size: 0.9rem; color: grey;     width: 70%;
            margin: auto;
            margin-bottom: 2.5rem;
            margin-top: 1rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;" (click)="logout()">
                <div>
                    <img class="svg-style-unselected" src="../../assets/svgIcons/logout.svg" alt="" srcset="">
                </div>
                <span class="sidebar-text">Logout</span>
            </div>
        </mat-sidenav>
        <mat-sidenav-content>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>

</ng-container> -->


<div>
    <app-header>

        <!-- Main Content Start-->

        <router-outlet mainContent></router-outlet>


        <!-- router-outlet -->
        <!-- Main Content End-->


        <!-- Footer Start -->

        <!-- <div>
            <app-footer footer></app-footer>
        </div> -->

        <!-- Footer End -->


    </app-header>
</div>