<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Venue
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div *ngIf="!isLoading" class="popup-body">
    <form name="addTurfForm" [formGroup]="addTurfForm">
        <div class="row">
            <div class="col-md-6">
                <label for="turfName" class="label required">Venue Name</label>
                <input type="text" placeholder="Turf name" id="turfName" formControlName="turfName" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="turfContact" class="label required">Venue Contact</label>
                <input type="text" placeholder="Turf Contact" id="turfContact" formControlName="turfContact"
                    class="input-style" maxlength="10">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="business" class="label required">Select Business</label>
                <select id="business" name="business" class="input-style" formControlName="business">
                    <option value="Select Business">Select Business</option>
                    <option *ngFor="let data of listOfBusiness" [value]="data['_id']">{{data['user']['firstName']+'
                        '+data['user']['lastName']}}</option>
                </select>
            </div>
            <div class="col-md-6">
                <label for="turfType" class="label required">Select Venue Type</label>
                <select id="turfType" name="turfType" class="input-style" formControlName="turfType">
                    <!-- <option value="Select TurfType" selected disabled>Select TurfType</option> -->
                    <option value="BOOK">BOOK</option>
                    <option value="CALL">CALL</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="advanceAmount" class="label required">Advance Payment Amount</label>
                <input type="number" placeholder="Advance Amount" id="advanceAmount" formControlName="advanceAmount"
                    class="input-style" maxlength="10">
            </div>
            <div class="col-md-6">
                <label for="advanceAmount" class="label">Owner WhatsApp Confirmation</label>
                <select id="ownerWhatsAppConfirmation" name="ownerWhatsAppConfirmation" class="input-style" formControlName="ownerWhatsAppConfirmation">
                    <option value="Owner">Owner</option>
                    <option value="Caretaker">Caretaker</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6" style="display: flex; align-items: center;">
                <input type="checkbox" id="recommendedVenue" formControlName="recommendedVenue"
                    (change)="recommendedVenueChange($event)" class="input-style"
                    style="width: auto;margin-right: 0.5em;">
                <label for="recommendedVenue" class="label required">Recommended Venue</label>
            </div>
            <div *ngIf="addTurfForm.get('recommendedVenue')?.value" class="col-md-6"
                style="display: flex; align-items: center;">
                <label for="recommendedPosition" class="label required">Recommended Position</label>
                <input type="text" placeholder="Recommended Position" id="recommendedPosition"
                    formControlName="recommendedPosition" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="commission" class="label required">Commission Rate</label>
                <input type="number" placeholder="Commission" id="commission" formControlName="commission"
                    class="input-style">
            </div>
            <div class="col-md-6">
                <label for="turfType" class="label required">Venue Status</label>
                <select id="turfType" name="turfType" class="input-style" formControlName="status">
                    <option value="APPROVED">APPROVED</option>
                    <option value="REJECTED">REJECTED</option>
                    <option value="PENDING">PENDING</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="description" class="label required">Description</label>
                <input type="text" placeholder="Description" id="description" formControlName="description"
                    class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                Address Detail:-
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="streetName" class="label required">Street Name</label>
                <input type="text" placeholder="Street Name" id="streetName" formControlName="streetName"
                    class="input-style">
            </div>
            <div class="col-md-6">
                <label for="landmark" class="label required">Landmark</label>
                <input type="text" placeholder="Landmark" id="landmark" formControlName="landmark" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="city" class="label required">City</label>
                <input type="text" placeholder="City" id="city" formControlName="city" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="state" class="label required">State</label>
                <input type="text" placeholder="State" id="state" formControlName="state" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="pincode" class="label required">Pincode</label>
                <input type="text" placeholder="Pincode" id="pincode" formControlName="pincode" class="input-style"
                    maxlength="6">
            </div>
        </div>

        <div class="row">
            <div class="col-md-12" style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em; ">
                Opened on Days:-
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="days" class="label required">Days</label>
                <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Days'"
                    [ngModelOptions]="{standalone: true}" [settings]="daysDropdownSettings" [data]="listOfDays"
                    [(ngModel)]="selectedDays" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)"
                    (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>

            </div>
        </div>

        <div class="row">
            <div class="col-md-12" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                Select Sport Category:-
            </div>
        </div>

        <div class="row">
            <div class="col-md-12" margin style="display: flex;align-items: center;margin-bottom: .5rem;"
                *ngFor="let data of sportCategory;">
                <input type="radio" name="radio-btn" style="margin-right: .5rem;" [id]="data['id']" [name]="data['id']"
                    [checked]="data['isSelected']" (change)="radioHandlerSportCategory($event)">
                <label [for]="data['categoryName']" class="lbl-radio">{{data['categoryName']}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                Available Sports:-
            </div>
        </div>

        <div class="row" style="margin-bottom: 1rem;">
            <div class="col-md-12">
                <label for="sport" class="label required">Sport</label>
                <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Sport'"
                    [ngModelOptions]="{standalone: true}" [settings]="sportsTypeDropdownSettings" [data]="getSports()"
                    [(ngModel)]="selectedSport" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)"
                    (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>

            </div>
        </div>
        <div class="row" *ngIf="selectedSportCategory['categoryName']=='Outdoor'">
            <div class="col-md-12" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                Select Netting Option:-
            </div>
        </div>
        <div class="row" style="margin-bottom: 1rem;" *ngIf="selectedSportCategory['categoryName']=='Outdoor'">
            <div class="col-md-12" style="display: flex;align-items: center;">

                <div style="margin-right: 3rem;">
                    <input type="radio" name="radio-btn" id="Netting" name="Netting" value="Netting"
                        [checked]="isNettingButtonSelected" (change)="radioChangeHandler($event)">
                    <label for="Netting" class="lbl-radio">Netting</label>
                </div>
                <div>
                    <input type="radio" name="radio-btn" id="Non Netting" name="Non Netting" value="Non Netting"
                        [checked]="isNonNettingButtonSelected" (change)="radioChangeHandler($event)">
                    <label for="Non Netting" class="lbl-radio">Non Netting</label>

                </div>
            </div>
        </div>

        <div class="row" *ngIf="selectedSportCategory['categoryName']=='Outdoor'">
            <div class="col-md-12" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                Select Venue title:-
            </div>
        </div>
        <div *ngIf="!isChangingNetToNonNetting && selectedSportCategory['categoryName']=='Outdoor'" class="row"
            style="width: 38em;">
            <div class="col-md-6" *ngFor="let data of listOfTurfSize;">
                <div>
                    <input type="checkbox" [checked]="data['isSelected']"
                        (change)="addOrRemoveSize(data['title'],$event)" [value]="data['title']"
                        [disabled]="!data['enabled']">
                    <label style=" width: 3em;margin-left: 1em; margin-right: 1em;" for="">{{data['title']}}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em; ">
                Venue Timing & Pricing:-
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em; ">
                <input type="checkbox" [checked]="isMorningSlotSelected"
                    (change)="changeSlotSelection('Morning',$event)">
                <label style="margin-left: 1em;">Morning Slot</label>
            </div>
        </div>
        <div *ngIf="isMorningSlotSelected && selectedSportCategory['categoryName']=='Outdoor'" class="row"
            style="width: 38em;">
            <div class="col-md-12" style="margin-bottom: 1em;">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                        Weekday price</div>
                    <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                        Weekend price</div>
                </div>
            </div>
            <div class="col-md-12" *ngFor="let data of listOfPriceForMorning;">
                <div>
                    <input type="checkbox" [checked]="data['isSelected']"
                        (change)="changePricingSelectionForMorning(data['title'],$event)">
                    <label style=" width: 3em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                    <input *ngIf="checkForWeekday()" type="number" placeholder="Enter weekdays price"
                        [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                        style="width: 25%; margin-right: 1rem;" maxlength="5"
                        (input)="addPriceForTurfSize(data['title'],$event.target,'Morning',false)">
                    <input *ngIf="checkForWeekend()" type="number" placeholder="Enter weekend price"
                        [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                        style="width: 25%;" maxlength="5"
                        (input)="addPriceForTurfSize(data['title'],$event.target,'Morning',true)">
                </div>
            </div>


        </div>
        <div *ngIf="isMorningSlotSelected && selectedSportCategory['categoryName']!='Outdoor'" class="row"
            style="width: 38em;">
            <div class="col-md-12" style="margin-bottom: 1em;">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                        Weekday price</div>
                    <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;">Weekend price</div>
                </div>
            </div>
            <div class="col-md-12" *ngFor="let data of listofSlotForMorning;">
                <div>
                    <input type="checkbox" [checked]="data['isSelected']"
                        (change)="changePricingSelectionForMorning(data['title'],$event)">
                    <label style=" width: 6em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                    <input *ngIf="checkForWeekday()" type="number" placeholder="Enter weekdays price"
                        [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                        style="width: 25%; margin-right: 1rem;" maxlength="5"
                        (input)="addPriceForTurfSize(data['title'],$event.target,'Morning',false)">
                    <input *ngIf="checkForWeekend()" type="number" placeholder="Enter weekend price"
                        [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                        style="width: 25%;" maxlength="5"
                        (input)="addPriceForTurfSize(data['title'],$event.target,'Morning',true)">
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12" style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em; ">
                <input type="checkbox" [checked]="isAfternoonSlotSelected"
                    (change)="changeSlotSelection('Afternoon',$event)"> <label
                    style="margin-left: 1em;">AfternoonSlot</label>
            </div>
        </div>
        <div *ngIf="isAfternoonSlotSelected && selectedSportCategory['categoryName']=='Outdoor'" class="row"
            style="width: 38em;">
            <div class="col-md-12" style="margin-bottom: 1em;">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                        Weekday price</div>
                    <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                        Weekend price</div>
                </div>
            </div>

            <div class="col-md-12" *ngFor="let data of listOfPriceForAfternoon;">
                <div>
                    <input type="checkbox" [checked]="data['isSelected']"
                        (change)="changePricingSelectionForAfternoon(data['title'],$event)">
                    <label style=" width: 3em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                    <input *ngIf="checkForWeekday()" type="number" placeholder="Enter price"
                        [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                        style="width: 25%; margin-right: 1rem;" maxlength="5"
                        (input)="addPriceForTurfSize(data['title'],$event.target,'Afternoon',false)">
                    <input *ngIf="checkForWeekend()" type="number" placeholder="Enter price"
                        [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                        style="width: 25%; margin-right: 1rem;" maxlength="5"
                        (input)="addPriceForTurfSize(data['title'],$event.target,'Afternoon',true)">
                </div>
            </div>
        </div>
        <div *ngIf="isAfternoonSlotSelected && selectedSportCategory['categoryName']!='Outdoor'" class="row"
            style="width: 38em;">
            <div class="col-md-12" style="margin-bottom: 1em;">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                        Weekday price</div>
                    <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;">Weekend price</div>
                </div>
            </div>

            <div class="col-md-12" *ngFor="let data of listofSlotForAfternoon;">
                <div>
                    <input type="checkbox" [checked]="data['isSelected']"
                        (change)="changePricingSelectionForAfternoon(data['title'],$event)">
                    <label style=" width: 6em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                    <input *ngIf="checkForWeekday()" type="number" placeholder="Enter price"
                        [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                        style="width: 25%; margin-right: 1rem;" maxlength="5"
                        (input)="addPriceForTurfSize(data['title'],$event.target,'Afternoon',false)">
                    <input *ngIf="checkForWeekend()" type="number" placeholder="Enter price"
                        [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                        style="width: 25%; margin-right: 1rem;" maxlength="5"
                        (input)="addPriceForTurfSize(data['title'],$event.target,'Afternoon',true)">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12" style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em; ">
                <input type="checkbox" [checked]="isEveningSlotSelected"
                    (change)="changeSlotSelection('Evening',$event)"> <label style="margin-left: 1em;">Evening
                    Slot</label>

            </div>
        </div>
        <div *ngIf="isEveningSlotSelected && selectedSportCategory['categoryName']=='Outdoor'" class="row"
            style="width: 38em;">

            <div class="col-md-12" style="margin-bottom: 1em;">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                        Weekday price</div>
                    <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                        Weekend price</div>
                </div>
            </div>

            <div class="col-md-12" *ngFor="let data of listOfPriceForEvening;">
                <div>
                    <input type="checkbox" [checked]="data['isSelected']"
                        (change)="changePricingSelectionForEvening(data['title'],$event)">
                    <label style=" width: 3em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                    <input *ngIf="checkForWeekday()" type="number" placeholder="Enter price"
                        [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                        style="width: 25%; margin-right: 1rem;" maxlength="5"
                        (input)="addPriceForTurfSize(data['title'],$event.target,'Evening',false)">
                    <input *ngIf="checkForWeekend()" type="number" placeholder="Enter price"
                        [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                        style="width: 25%; margin-right: 1rem;" maxlength="5"
                        (input)="addPriceForTurfSize(data['title'],$event.target,'Evening',true)">
                </div>
            </div>
        </div>
        <div *ngIf="isEveningSlotSelected && selectedSportCategory['categoryName']!='Outdoor'" class="row"
            style="width: 38em;">

            <div class="col-md-12" style="margin-bottom: 1em;">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                        Weekday price</div>
                    <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;">Weekend price</div>
                </div>
            </div>

            <div class="col-md-12" *ngFor="let data of listofSlotForEvening;">
                <div>
                    <input type="checkbox" [checked]="data['isSelected']"
                        (change)="changePricingSelectionForEvening(data['title'],$event)">
                    <label style=" width: 6em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                    <input *ngIf="checkForWeekday()" type="number" placeholder="Enter price"
                        [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                        style="width: 25%; margin-right: 1rem;" maxlength="5"
                        (input)="addPriceForTurfSize(data['title'],$event.target,'Evening',false)">
                    <input *ngIf="checkForWeekend()" type="number" placeholder="Enter price"
                        [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                        style="width: 25%; margin-right: 1rem;" maxlength="5"
                        (input)="addPriceForTurfSize(data['title'],$event.target,'Evening',true)">
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12" style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em; ">
                <input type="checkbox" [checked]="isNightSlotSelected" (change)="changeSlotSelection('Night',$event)">
                <label style="margin-left: 1em;">Night Slot</label>

            </div>
        </div>
        <div *ngIf="isNightSlotSelected && selectedSportCategory['categoryName']=='Outdoor'" class="row"
            style="width: 38em;">
            <div class="col-md-12" style="margin-bottom: 1em;">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                        Weekday price</div>
                    <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                        Weekend price</div>
                </div>
            </div>

            <div class="col-md-12" *ngFor="let data of listOfPriceForNight;">
                <div>
                    <input type="checkbox" [checked]="data['isSelected']"
                        (change)="changePricingSelectionForNight(data['title'],$event)">
                    <label style=" width: 3em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                    <input *ngIf="checkForWeekday()" type="number" placeholder="Enter price"
                        [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                        style="width: 25%;margin-right: 1rem;" maxlength="5"
                        (input)="addPriceForTurfSize(data['title'],$event.target,'Night',false)">
                    <input *ngIf="checkForWeekend()" type="number" placeholder="Enter price"
                        [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                        style="width: 25%;margin-right: 1rem;" maxlength="5"
                        (input)="addPriceForTurfSize(data['title'],$event.target,'Night',true)">
                </div>
            </div>
        </div>
        <div *ngIf="isNightSlotSelected && selectedSportCategory['categoryName']!='Outdoor'" class="row"
            style="width: 38em;">
            <div class="col-md-12" style="margin-bottom: 1em;">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                        Weekday price</div>
                    <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;">Weekend price</div>
                </div>
            </div>
            <div class="col-md-12" *ngFor="let data of listofSlotForNight;">
                <div>
                    <input type="checkbox" [checked]="data['isSelected']"
                        (change)="changePricingSelectionForNight(data['title'],$event)">
                    <label style=" width: 6em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                    <input *ngIf="checkForWeekday()" type="number" placeholder="Enter price"
                        [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                        style="width: 25%;margin-right: 1rem;" maxlength="5"
                        (input)="addPriceForTurfSize(data['title'],$event.target,'Night',false)">
                    <input *ngIf="checkForWeekend()" type="number" placeholder="Enter price"
                        [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                        style="width: 25%;margin-right: 1rem;" maxlength="5"
                        (input)="addPriceForTurfSize(data['title'],$event.target,'Night',true)">
                </div>
            </div>
        </div>

        <div class="row" *ngIf="isMorningSlotSelected">
            <div class=" col-md-12" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                Morning Slot Time :-
            </div>
            <div>
                <div class="row" style="width: 38em;">
                    <div class="col-md-6">
                        <div>
                            <label style=" width: 6em;margin-left: 1em; margin-right: 1em;" for="">Start Time</label>
                            <input type="number" placeholder="Start Time" class="input-style"
                                [value]="morningSlotTime['startTime']" style="width: 50%;" maxlength="5"
                                (input)="addTimeForSlot($event.target,'Morning',true)">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>
                            <label style=" width: 6em;margin-left: 1em; margin-right: 1em;" for="">End Time</label>
                            <input type="number" placeholder="End Time" class="input-style"
                                [value]="morningSlotTime['endTime']" style="width: 50%;" maxlength="5"
                                (input)="addTimeForSlot($event.target,'Morning',false)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="isAfternoonSlotSelected">
            <div class=" col-md-12" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                Afternoon Slot Time :-
            </div>
            <div>
                <div class="row" style="width: 38em;">
                    <div class="col-md-6">
                        <div>
                            <label style=" width: 6em;margin-left: 1em; margin-right: 1em;" for="">Start Time</label>
                            <input type="number" placeholder="Start Time" class="input-style"
                                [value]="afternoonSlotTime['startTime']" style="width: 50%;" maxlength="5"
                                (input)="addTimeForSlot($event.target,'Afternoon',true)">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>
                            <label style=" width: 6em;margin-left: 1em; margin-right: 1em;" for="">End Time</label>
                            <input type="number" placeholder="End Time" class="input-style"
                                [value]="afternoonSlotTime['endTime']" style="width: 50%;" maxlength="5"
                                (input)="addTimeForSlot($event.target,'Afternoon',false)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="isEveningSlotSelected">
            <div class=" col-md-12" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                Evening Slot Time :-
            </div>
            <div>
                <div class="row" style="width: 38em;">
                    <div class="col-md-6">
                        <div>
                            <label style=" width: 6em;margin-left: 1em; margin-right: 1em;" for="">Start Time</label>
                            <input type="number" placeholder="Start Time" class="input-style"
                                [value]="eveningSlotTime['startTime']" style="width: 50%;" maxlength="5"
                                (input)="addTimeForSlot($event.target,'Evening',true)">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>
                            <label style=" width: 6em;margin-left: 1em; margin-right: 1em;" for="">End Time</label>
                            <input type="number" placeholder="End Time" class="input-style"
                                [value]="eveningSlotTime['endTime']" style="width: 50%;" maxlength="5"
                                (input)="addTimeForSlot($event.target,'Evening',false)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="isNightSlotSelected">
            <div class=" col-md-12" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                Night Slot Time :-
            </div>
            <div>
                <div class="row" style="width: 38em;">
                    <div class="col-md-6">
                        <div>
                            <label style=" width: 6em;margin-left: 1em; margin-right: 1em;" for="">Start Time</label>
                            <input type="number" placeholder="Start Time" class="input-style"
                                [value]="nightSlotTime['startTime']" style="width: 50%;" maxlength="5"
                                (input)="addTimeForSlot($event.target,'Night',true)">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>
                            <label style=" width: 6em;margin-left: 1em; margin-right: 1em;" for="">End Time</label>
                            <input type="number" placeholder="End Time" class="input-style"
                                [value]="nightSlotTime['endTime']" style="width: 50%;" maxlength="5"
                                (input)="addTimeForSlot($event.target,'Night',false)">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div *ngIf="selectedSportCategory['categoryName']=='Outdoor'" class="col-md-12"
                style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                Venue {{isNettingButtonSelected? 'Relation':'Quantity'}} & Label:-
            </div>
            <div *ngIf="selectedSportCategory['categoryName']!='Outdoor'" class="col-md-12"
                style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                Game Quantity & Label:-
            </div>
        </div>
        <div *ngIf="selectedSportCategory['categoryName']=='Outdoor'">
            <div class="row" style="width: 38em;" *ngFor="let data of listOfTurfSize;">
                <div class="col-md-6">
                    <div *ngIf="data['isSelected']">
                        <label style=" width: 3em;margin-left: 1em; margin-right: 1em;" for="">{{data['title']}}</label>
                        <input type="number" placeholder="Enter number" class="input-style" [value]="data['quantity']"
                            style="width: 40%;" maxlength="5"
                            (input)="addQuantityForTurfSize(data['title'],$event.target)">

                    </div>
                </div>
                <div class="col-md-6">
                    <div *ngIf="data['isSelected']">
                        <label style=" width: 3em;margin-left: 1em; margin-right: 1em;" for="">Label</label>
                        <input type="text" placeholder="Enter label" class="input-style" [value]="data['label']"
                            style="width: 60%;" (input)="addLabelForTurfSize(data['title'],$event.target)">

                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="selectedSportCategory['categoryName']!='Outdoor'">
            <div class="row" style="width: 38em;" *ngFor="let data of gameQuantity;">
                <div class="col-md-6">
                    <div *ngIf="data['isSelected']">
                        <label style=" width: 6em;margin-left: 1em; margin-right: 2rem;"
                            for="">{{data['title']}}</label>
                        <input type="number" placeholder="Enter number" class="input-style" [value]="data['quantity']"
                            style="width: 40%;" maxlength="5"
                            (input)="addQuantityForTurfSize(data['title'],$event.target)">
                    </div>

                </div>
                <div class="col-md-6">
                    <div *ngIf="data['isSelected']">
                        <label style=" width: 3em;margin-left: 1em; margin-right: 1em;" for="">Label</label>
                        <input type="text" placeholder="Enter label" class="input-style" [value]="data['label']"
                            style="width: 60%;" (input)="addLabelForTurfSize(data['title'],$event.target)">

                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12" style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em; ">
                Venue Facility:-
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="facility" class="label required">Facility</label>
                <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Facility'"
                    [ngModelOptions]="{standalone: true}" [settings]="facilitiesDropdownSettings"
                    [data]="listOfFacilities" [(ngModel)]="selectedFacilities" (onSelect)="onItemSelect($event)"
                    (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12" style=" font-weight: 700;margin-top: 1em; ">
                Venue Images(Atleast 3 images):-
            </div>
        </div>

        <div class="row">

            <div class="col-md-6">
                <label for="turfImage1" class="label" style="margin-top: 1em;">Venue Image 1</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultTurfImage[0]!=null" src="{{defaultTurfImage[0]}}" class="proofImage">
                                <label *ngIf="defaultTurfImage[0]==null" class="imageLabel"> Upload
                                    <input (change)="displayTurf($event,0)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultTurfImage[0]!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayTurf($event,0)" type="file" accept="image/*">
                                </label>
                                <!-- <label *ngIf="defaultTurfImage[0]!=null  && defaultTurfImage[0].includes('https')"
                                    class="imageLabelDownload" (click)="downloadImage(defaultTurfImage[0])">
                                    <mat-icon class="matIcon">download</mat-icon>
                                </label> -->
                                <label *ngIf="defaultTurfImage[0]!=null  && defaultTurfImage[0]!=''"
                                    class="imageLabelDownload" (click)="deleteImage(0)">
                                    <mat-icon class="matIcon">delete</mat-icon>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <label for="turfImage2" class="label" style="margin-top: 1em;">Venue Image 2</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultTurfImage[1]!=null" src="{{defaultTurfImage[1]}}" class="proofImage">
                                <label *ngIf="defaultTurfImage[1]==null" class="imageLabel"> Upload
                                    <input (change)="displayTurf($event,1)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultTurfImage[1]!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayTurf($event,1)" type="file" accept="image/*">
                                </label>
                                <!-- <label *ngIf="defaultTurfImage[1]!=null  && defaultTurfImage[1].includes('https')"
                                    class="imageLabelDownload" (click)="downloadImage(defaultTurfImage[1])">
                                    <mat-icon class="matIcon">download</mat-icon>
                                </label> -->
                                <label *ngIf="defaultTurfImage[1]!=null &&   defaultTurfImage[1]!=''"
                                    class="imageLabelDownload" (click)="deleteImage(1)">
                                    <mat-icon class="matIcon">delete</mat-icon>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">

            <div class="col-md-6">
                <label for="turfImage3" class="label" style="margin-top: 1em;">Venue Image 3</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultTurfImage[2]!=null" src="{{defaultTurfImage[2]}}" class="proofImage">
                                <label *ngIf="defaultTurfImage[2]==null" class="imageLabel"> Upload
                                    <input (change)="displayTurf($event,2)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultTurfImage[2]!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayTurf($event,2)" type="file" accept="image/*">
                                </label>
                                <!-- <label *ngIf="defaultTurfImage[2]!=null  && defaultTurfImage[2].includes('https')"
                                    class="imageLabelDownload" (click)="downloadImage(defaultTurfImage[2])">
                                    <mat-icon class="matIcon">download</mat-icon>
                                </label> -->
                                <label *ngIf="defaultTurfImage[2]!=null   && defaultTurfImage[2]!=''"
                                    class="imageLabelDownload" (click)="deleteImage(2)">
                                    <mat-icon class="matIcon">delete</mat-icon>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <label for="turfImage4" class="label" style="margin-top: 1em;">Venue Image 4</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultTurfImage[3]!=null" src="{{defaultTurfImage[3]}}" class="proofImage">
                                <label *ngIf="defaultTurfImage[3]==null" class="imageLabel"> Upload
                                    <input (change)="displayTurf($event,3)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultTurfImage[3]!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayTurf($event,3)" type="file" accept="image/*">
                                </label>
                                <!-- <label *ngIf="defaultTurfImage[3]!=null  && defaultTurfImage[3].includes('https')"
                                    class="imageLabelDownload" (click)="downloadImage(defaultTurfImage[3])">
                                    <mat-icon class="matIcon">download</mat-icon>
                                </label> -->
                                <label *ngIf="defaultTurfImage[3]!=null &&  defaultTurfImage[3]!=''"
                                    class="imageLabelDownload" (click)="deleteImage(3)">
                                    <mat-icon class="matIcon">delete</mat-icon>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">

            <div class="col-md-6">
                <label for="turfImage5" class="label" style="margin-top: 1em;">Venue Image 5</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultTurfImage[4]!=null" src="{{defaultTurfImage[4]}}" class="proofImage">
                                <label *ngIf="defaultTurfImage[4]==null" class="imageLabel"> Upload
                                    <input (change)="displayTurf($event,4)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultTurfImage[4]!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayTurf($event,4)" type="file" accept="image/*">
                                </label>
                                <!-- <label *ngIf="defaultTurfImage[4]!=null  && defaultTurfImage[4].includes('https')"
                                    class="imageLabelDownload" (click)="downloadImage(defaultTurfImage[4])">
                                    <mat-icon class="matIcon">download</mat-icon>
                                </label> -->
                                <label *ngIf="defaultTurfImage[4]!=null && defaultTurfImage[4]!=''"
                                    class="imageLabelDownload" (click)="deleteImage(4)">
                                    <mat-icon class="matIcon">delete</mat-icon>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="save">
            <input *ngIf="dataToEdit!=null" type="submit"
                [ngClass]="{'save-btn-disabled':addTurfForm.invalid  ,'save-btn':addTurfForm.valid     }"
                value="{{'Save'}}" [disabled]="addTurfForm.invalid " (click)="addTurf()" />
            <input *ngIf="dataToEdit==null" type="submit"
                [ngClass]="{'save-btn-disabled':addTurfForm.invalid  || !isValidTurfImageImage || selectedDays.length==0|| selectedSport.length==0|| listOfFacilities.length==0 ,'save-btn':addTurfForm.valid && isValidTurfImageImage &&  selectedDays.length!=0 && selectedSport.length!=0 && listOfFacilities.length!=0  &&selectedTurfImage.length>=3 }"
                value="{{'Save'}}"
                [disabled]="addTurfForm.invalid   || !isValidTurfImageImage|| selectedDays.length==0|| selectedSport.length==0||selectedTurfImage.length<3 || listOfFacilities.length==0"
                (click)="addTurf()" />
        </div>
    </form>
</div>