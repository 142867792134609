import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteRentalItemComponent } from '../delete-rental-item/delete-rental-item.component';
import { ViewItemComponent } from '../view-item/view-item.component';

@Component({
  selector: 'app-list-rental',
  templateUrl: './list-rental.component.html',
  styleUrls: ['./list-rental.component.scss'],
})
export class ListRentalComponent implements OnInit {
  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
  }

  ngOnInit(): void {
    this.fetchAllItem();
  }

  isLoading: boolean = false;
  dataToEdit: any;
  userDetails: any;
  isAddEditCalled: boolean = false;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  listOfSports: any = [];
  p: any = 1;
  count: any = 50;
  listOfRentals: any = [];

  addNewItem() {
    this.toggleOpened();
  }

  async fetchAllItem() {
    this.isLoading = true;
    this.listOfRentals = [];
    try {
      let param = {};
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchRentalItem}`;
      param = {};
      await this.appService
        .fetchDetailViaGet(URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfRentals = data['result'];
            console.log(this.listOfRentals);
            this.isLoading = false;
          } else {
            this.listOfRentals = [];
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.listOfRentals = [];
      this.isLoading = false;
    }
  }

  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    // this.isLoading = true;
    if (event != null) {
      if (event != 'Error') {
        // if (this.dataToEdit != null) {
        const index = this.listOfRentals.findIndex(
          (rental: any) => rental['_id'] == event['_id']
        );

        if (index == -1) {
          this.listOfRentals.unshift(event);
        } else {
          this.listOfRentals[index].duration = event['duration'];
          this.listOfRentals[index].price = event['price'];
          this.listOfRentals[index].productName = event['productName'];
          this.listOfRentals[index].productImage = event['productImage'];
          this.listOfRentals[index].sport = event['sport'];
          this.listOfRentals[index].turfs = event['turfs'];
        }
        this.showSuccessToaster('Item updated successfully', 'Success');
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      } else {
        this.showErrorToaster('Error occured.Kindly try again', 'Error');
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  editRental(item: any) {
    this.listOfRentals.forEach((data: any) => {
      if (data['_id'] == item['_id']) {
        this.dataToEdit = data;
      }
    });
    this.toggleOpened();
  }

  // deleteItem(item: any) {}

  deleteItem(rental: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      rental: rental,
    };
    dialogConfig.maxHeight = `75vh`;
    let dialogRef = this.matDialog.open(
      DeleteRentalItemComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: any) => {
        // console.log(res);
        if (res == null) {
          return;
        } else {
          try {
            let param = {};
            const httpHeaders = new HttpHeaders({
              Authorization: `Bearer ${this.userDetails['accessToken']}`,
            });
            let URL;
            URL = `${webApi.domain + webApi.endPoint.deleteRentalItem}`;
            param = {
              rentalId: res['value']['rental']['_id'],
            };
            await this.appService
              .deleteData(param, URL, httpHeaders)
              .then((data: any) => {
                if (data['success']) {
                  const index = this.listOfRentals.findIndex((data: any) => {
                    data['_id'] == res['value']['rental']['_id'];
                  });
                  this.listOfRentals.splice(index, 1);
                  this.showSuccessToaster(
                    'Item deleted successfully',
                    'Success'
                  );
                } else {
                  this.showErrorToaster(
                    'Error while deleting item details.Kindly try again',
                    'Error'
                  );
                }
              });
          } catch (error) {
            console.log(error);
            this.showErrorToaster(
              'Error while deleting item details.Kindly try again',
              'Error'
            );
          }
        }
      }
    );
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
