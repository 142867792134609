import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ListWithdrawalComponent } from './list-withdrawal/list-withdrawal.component';




const routes: Routes = [
  {
    path: '',
    component: ListWithdrawalComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'admin/withdrawal' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WithdrawalRoutingModule { }
