import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ListBookingComponent } from './list-booking/list-booking.component';



const routes: Routes = [
  {
    path: '',
    component: ListBookingComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'admin/booking' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BookingRoutingModule { }
