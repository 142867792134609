import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit {
  userDetails: any;
  isLoading: boolean = true;
  isSportCategoryLoading: boolean = false;
  listOfBooking: any = [];
  listOfSports: any = [];
  listOfDuration: any = [
    'Daily',
    'Weekly',
    'Monthly',
    'Current Quarter',
    'Yearly',
  ];

  totalEarning: number = 0;
  totalEarningPercentageChange: number = 0;
  selectedEarningDuration: string = 'Monthly';

  totalSales: number = 0;
  totalSalesPercentageChange: number = 0;
  selectedSalesDuration: string = 'Monthly';

  totalUser: number = 0;
  totalUserPercentageChange: number = 0;
  selectedUserDuration: string = 'Monthly';

  totalRequest: number = 0;
  totalRequestPercentageChange: number = 0;
  selectedRequestDuration: string = 'Monthly';

  selectedSportTypeDuration: string = 'Monthly';

  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
    if (this.userDetails == null) {
      this.router.navigate(['/auth/login']);
    }
  }

  ngOnInit(): void {
    // this.fetchDetailsBasedOnInterval('All', 'Monthly');
    this.fetchBooking();
  }
  gotoBooking() {
    this.appService.gotoComponent('Booking', 'Admin');
  }
  async fetchDetailsBasedOnInterval(type: any, duration: any) {
    this.isLoading = true;
    this.totalEarning = 0;
    this.totalEarningPercentageChange = 0;

    this.totalSales = 0;
    this.totalSalesPercentageChange = 0;

    this.totalUser = 0;
    this.totalUserPercentageChange = 0;

    this.totalRequest = 0;
    this.totalRequestPercentageChange = 0;

    try {
      let param = {
        type: type,
        duration: duration,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchDetailsBasedOnInterval}`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.totalEarning = data['totalEarning'];
            this.totalUser = data['totalUser'];
            this.totalSales = data['totalSales'];
            this.totalRequest = data['totalRequest'];

            this.totalEarningPercentageChange = data['totalEarningHistory'];
            this.totalUserPercentageChange = data['totalUserHistory'];
            this.totalSalesPercentageChange = data['totalSalesHistory'];
            this.totalRequestPercentageChange = data['totalRequestHistory'];

            this.fetchSportBookingPercentage('Monthly');
          } else {
            this.fetchSportBookingPercentage('Monthly');
          }
        });
    } catch (error) {
      this.fetchSportBookingPercentage('Monthly');
    }
  }
  async fetchDetailsBasedOnIntervalOnce(type: any, duration: any) {
    this.isLoading = true;
    try {
      let param = {
        type: type,
        duration: duration,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchDetailsBasedOnInterval}`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            if (type == 'Earning') {
              this.totalEarning = data['totalEarning'];
              this.totalEarningPercentageChange = data['totalEarningHistory'];
            } else if (type == 'User') {
              this.totalUser = data['totalUser'];
              this.totalUserPercentageChange = data['totalUserHistory'];
            } else if (type == 'Sales') {
              this.totalSales = data['totalSales'];
              this.totalSalesPercentageChange = data['totalSalesHistory'];
            } else if (type == 'Request') {
              this.totalRequest = data['totalRequest'];
              this.totalRequestPercentageChange = data['totalRequestHistory'];
            } else {
              this.totalEarning = data['totalEarning'];
              this.totalUser = data['totalUser'];
              this.totalSales = data['totalSales'];
              this.totalRequest = data['totalRequest'];

              this.totalEarningPercentageChange = data['totalEarningHistory'];
              this.totalUserPercentageChange = data['totalUserHistory'];
              this.totalSalesPercentageChange = data['totalSalesHistory'];
              this.totalRequestPercentageChange = data['totalRequestHistory'];
            }
            this.isLoading = false;
          } else {
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.isLoading = false;
    }
  }
  async fetchSportBookingPercentage(duration: any) {
    this.listOfSports = [];
    try {
      let param = {
        duration: duration,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchSportBookingPercentage}`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfSports = data['result'];
            this.isLoading = false;
          } else {
            this.listOfSports = [];
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.listOfSports = [];
      this.isLoading = false;
    }
  }
  async fetchSportBookingPercentageOnce(duration: any) {
    this.listOfSports = [];
    this.isSportCategoryLoading = true;
    try {
      let param = {
        duration: duration,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchSportBookingPercentage}`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfSports = data['result'];
            this.isSportCategoryLoading = false;
          } else {
            this.listOfSports = [];
            this.isSportCategoryLoading = false;
          }
        });
    } catch (error) {
      this.listOfSports = [];
      this.isSportCategoryLoading = false;
    }
  }
  async fetchBooking() {
    this.listOfBooking = [];
    try {
      let param = {};
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchFewBooking}`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfBooking = data['result'];
            this.fetchDetailsBasedOnInterval('All', 'Monthly');
          } else {
            this.listOfBooking = [];
            this.fetchDetailsBasedOnInterval('All', 'Monthly');
          }
        });
    } catch (error) {
      this.listOfBooking = [];
      this.fetchDetailsBasedOnInterval('All', 'Monthly');
    }
  }
  onSportMenuChange(data: any) {
    this.selectedSportTypeDuration = data;
    this.fetchSportBookingPercentageOnce(data);
  }
  changeDuration(duration: any, type: any) {
    // if (type == 'Earning') {
    //   this.selectedEarningDuration = duration;
    // } else if (type == 'User') {
    //   this.selectedUserDuration = duration;
    // } else if (type == 'Sales') {
    //   this.selectedSalesDuration = duration;
    // } else if (type == 'Request') {
    //   this.selectedRequestDuration = duration;
    // }
    this.selectedEarningDuration = duration;
    this.selectedUserDuration = duration;
    this.selectedSalesDuration = duration;
    this.selectedRequestDuration = duration;

    this.fetchDetailsBasedOnIntervalOnce('All', duration);
  }
}
