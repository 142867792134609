<div class="main-container">
    <div class="row align-center" style="margin-right: 1.4rem;margin-left: .1rem;">
        <div class="top-heading col-md-3">
            Booking List
        </div>
        <div class="col-md-9">
            <div class="row align-center">
                <div class="col-md-12" style="display: flex;justify-content: space-around;align-items: center;">
                    <div class="col-md-5"
                        style="text-align: right;display: flex;justify-content: space-around;align-items: center;">
                        Slot Booking Date:- <input type="date" style="width: 45%;" class="input-field"
                            placeholder="Select Date" [(ngModel)]="dateResult" [ngModelOptions]="{standalone: true}"
                            name="dateQuery" id="dateQuery" (input)="fetchDataBasedOndate($event.target)">
                        <!-- <div (click)="fetchAllBooking(0)" style="color: rgb(101, 101, 255);text-decoration: underline;cursor: pointer;margin-left: 10px;">
                            Clear
                        </div> -->
                    </div>
                    <div class="col-md-3" style="text-align: right;">
                        <select style="width: 100%;" class="input-field" name="region" id="region"
                            aria-placeholder="Select Payment Status" (change)="onPaymentStatusSelectionChange($event)">
                            <option value="Select Payment Status" selected disabled>Select Payment Status</option>
                            <option [selected]="selectedStatus=='All'" value="All">All</option>
                            <option [selected]="selectedStatus=='PENDING'" value="PENDING">PENDING</option>
                            <option [selected]="selectedStatus=='SUCCESS'" value="SUCCESS">SUCCESS</option>
                            <!-- <option [selected]="selectedStatus=='FAILED'" value="FAILED">FAILED</option> -->
                            <option [selected]="selectedStatus=='OFFLINE'" value="OFFLINE">OFFLINE</option>

                        </select>
                    </div>
                    <div class="col-md-4" style="text-align: right;">
                        <input type="text" class="input-field" style="width: 100%;" name="searchQuery" id="searchQuery"
                            placeholder="Search Here" [(ngModel)]="searchStr" (keyup.enter)="searchBookingData()"
                            (input)="searchData($event.target)">
                        <!-- (input)="searchData($event.target)" -->
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height: 79vh;" *ngIf="!isLoading">

        <div style="padding: 1em;">


            <div *ngIf="listOfBookingToDisplay.length==0">
                <div class="no-available">
                    No booking available.
                </div>
            </div>
            <div *ngIf="listOfBookingToDisplay.length!=0">
                <table class="table-style">
                    <thead class="table-heading">
                        <th style="cursor: pointer;" [appSort]="listOfBookingToDisplay" data-order="desc"
                            data-name="createdAt" scope="col" class="table-heading-content">Booking Date</th>
                        <th style="cursor: pointer;" [appSort]="listOfBookingToDisplay" data-order="desc"
                            data-name="name" scope="col" class="table-heading-content">Name</th>
                        <th style="cursor: pointer;" [appSort]="listOfBookingToDisplay" data-order="desc"
                            data-name="phone" scope="col" class="table-heading-content">Phone</th>
                        <th style="cursor: pointer;" [appSort]="listOfBookingToDisplay" data-order="desc"
                            data-name="turfName" scope="col" class="table-heading-content">Turf</th>
                        <th style="cursor: pointer;" [appSort]="listOfBookingToDisplay" data-order="desc"
                            data-name="bookingDate" scope="col" class="table-heading-content">Slot Booking Date</th>
                        <th style="cursor: pointer;" [appSort]="listOfBookingToDisplay" data-order="desc"
                            data-name="bookingSlotStartTime" scope="col" class="table-heading-content">Time</th>
                        <th style="cursor: pointer;" [appSort]="listOfBookingToDisplay" data-order="desc"
                            data-name="turfSize" scope="col" class="table-heading-content">Sport</th>
                        <th style="cursor: pointer;" [appSort]="listOfBookingToDisplay" data-order="desc"
                            data-name="bookingMode" scope="col" class="table-heading-content">Booking Mode</th>
                        <th style="cursor: pointer;" [appSort]="listOfBookingToDisplay" data-order="desc"
                            data-name="bookingStatus" scope="col" class="table-heading-content">Booking Status</th>
                        <th style="cursor: pointer;" [appSort]="listOfBookingToDisplay" data-order="desc"
                            data-name="paymentStatus" scope="col" class="table-heading-content">Payment Status</th>
                        <th style="cursor: pointer;" [appSort]="listOfBookingToDisplay" data-order="desc"
                            data-name="paymentStatus" scope="col" class="table-heading-content">Message</th>
                        <th class="table-heading-content" style="width:10em;text-align-last: center;">ACTION</th>
                    </thead>
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let booking of listOfBookingToDisplay | paginate: { itemsPerPage: count, currentPage: Page }">
                            <th class="table-data-content">{{booking.createdAt|date}}</th>
                            <th (click)="viewBookingDetail(booking)" style="cursor: pointer;"
                                class="table-data-content">
                                {{booking.name}}</th>
                            <th (click)="viewBookingDetail(booking)" style="cursor: pointer;"
                                class="table-data-content">
                                {{booking.phone}}</th>
                            <th class="table-data-content">{{booking.turfName}}</th>
                            <th class="table-data-content">{{booking.ogBookingDate|date}}</th>
                            <th class="table-data-content">{{getTimeFormat(booking.bookingSlotStartTime)}} to
                                {{getTimeFormat(booking.bookingSlotEndTime)}} </th>

                            <th style="width: 10em;" class="table-data-content">{{booking.sportName}}</th>

                            <th style="width: 10em;" class="table-data-content">
                                {{booking.bookingMode.toLocaleUpperCase()}}
                            </th>
                            <th style="width: 10em;" class="table-data-content">
                                {{booking.bookingStatus}}
                            </th>
                            <th style="width: 10em;" class="table-data-content">
                                {{booking.paymentStatus}}
                            </th>
                            <th style="width: 10em;" class="table-data-content">
                                <div mat-button [matMenuTriggerFor]="menu" style="cursor: pointer">
                                    <img class="dots" style="width: 1.5rem; cursor: pointer"
                                        src="../../../../../assets/svgIcons/verticleDots.svg" alt="" srcset="" />
                                </div>
                                <mat-menu #menu="matMenu">

                                    <mat-list>
                                        <div>
                                            <div mat-subheader><b>Send Message</b></div>
                                            <mat-list-item>
                                                <button class="menu" mat-menu-item
                                                    (click)="sendWhatsAppMessage(booking,'User')">
                                                    User Message
                                                </button>
                                            </mat-list-item>
                                            <mat-list-item>
                                                <button class="menu" mat-menu-item
                                                    (click)="sendWhatsAppMessage(booking,'Owner')">
                                                    Owner Message
                                                </button>
                                            </mat-list-item>

                                        </div>
                                    </mat-list>
                                </mat-menu>

                            </th>
                            <th class="table-data-content" style="width:10em;text-align-last: center;">
                                <img title="Edit" (click)="viewBookingDetail(booking)"
                                    style="height: 1.5rem;margin-right: 1em;cursor: pointer;"
                                    src='../../../assets/img/view.png'>
                            </th>
                        </tr>

                    </tbody>
                </table>
                <div *ngIf="isLoadingExtraData" class="text-right" style="font-size: medium; margin: 1em">
                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin: 0 auto">
                    </mat-progress-spinner>
                </div>
                <div *ngIf="!isLoadingExtraData" class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                </div>
            </div>

        </div>
    </div>
</div>