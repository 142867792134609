import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-otp-verify-page',
  templateUrl: './otp-verify-page.component.html',
  styleUrls: ['./otp-verify-page.component.scss'],
})
export class OtpVerifyPageComponent implements OnInit {
  isConfirmClicked: boolean = false;
  otp: any;
  windowRef: any;
  userDetail: any;
  mobileNo: any;
  role: any;

  config = {
    allowNumbersOnly: false,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'margin-top': '8px',
      'margin-right': '8px',
    },
  };
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.userDetail = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
    if (this.userDetail != null) {
      this.as.gotoDefault(this.userDetail);
    }
    this.routes.queryParams.subscribe((params) => {
      this.mobileNo = params['mobileNo'];
      this.role = params['role'];
    });
  }
  ngOnInit(): void {
    this.windowRef = this.as.windowRef;
  }
  onOtpChange(event: any) {
    this.otp = event;
  }
  async isUserExists() {
    const param = {
      phone: this.mobileNo,
      userRole: this.role,
    };
    let URL = '';
    //  URL = `${webApi.domain + webApi.endPoint.isUserExists}`;
    await this.as.fetchDataFromDB(param, URL).then(async (data: any) => {
      if (data['type']) {
        localStorage.setItem(
          'BYS-ADMIN-WEB',
          JSON.stringify({
            accessToken: data['accessToken'],
            name:
              data['result']['firstName'] + ' ' + data['result']['lastName'],
            phone: data['result']['phone'],
            role: data['result']['userRole'],
          })
        );
        this.isConfirmClicked = false;
        await this.as.sideBar();
        if (this.role == 'Admin') {
          this.router.navigate([this.as.sideBarCopy[0]['route']]);
        }
      } else {
        this.showErrorToaster(`Not a valid ${this.role}`, 'Invalid User');
        this.isConfirmClicked = false;
        this.showWarningToaster('Incorrect otp entered.', 'Alert');
      }
    });
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  confirmOtp() {
    this.isConfirmClicked = true;
    this.windowRef.confirmationResult
      .confirm(this.otp)
      .then(async (result: any) => {
        this.isUserExists();
      })
      .catch((error: any) => {
        console.log(error, 'Incorrect code entered?');
        this.showWarningToaster('Incorrect otp entered.', 'Alert');
        this.isConfirmClicked = false;
      });
  }
}
