<div class="main-container">
    <div class="crm">
        <div class="top">
            <div class="top-box">
                <div class="view-box" [ngClass]="{'selected': venue}" (click)="toggleVenueAndUserInfo()">
                    Venue
                </div>
                <div class="view-box" [ngClass]="{'selected': !venue}" (click)="toggleVenueAndUserInfo()">
                    User Information
                </div>
            </div>
        </div>
        <div class="first-box box-desktop">
            <div class="search-date">
                <div class="input-container" style="display: flex;width: 65%;">
                    <input type="text" placeholder="Search venue & locality" class="search-style"
                        [(ngModel)]="venueSearchString" (keyup.enter)="searchVenueDataOnEnterHadler()"
                        (input)="searchVenueDataHandler($event.target)" (keydown)="searchVenueKeydownHandler()">
                    <img src="../../../../assets/svgIcons/search.svg" alt="" class="search-icon">
                </div>
                <div class="input-container" style="display: flex;">
                    <mat-form-field class="example-full-width" style="opacity: 0;height: 0px;width: 0px;">
                        <input matInput [matDatepicker]="picker" style="display: none;" [value]="selectedDate"
                            [min]="maxStartDate" (dateChange)="dateChangeHandler($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <div for="getDate" class="date-set" (click)="picker.open()" style="cursor: pointer;">
                        <img src="../../../../assets/svgIcons/solar_calendar-outline.svg" alt="" class="date-icon">
                        <div class="date-written">{{selectedDate==null || selectedDate==undefined? 'Date':(selectedDate
                            | date)}}</div>
                    </div>
                    <!-- <input type="date" id="getDate" style="display: none;"> -->
                </div>
            </div>
            <div *ngIf="listOfVenue.length==0">
                <div style="color: gray;height: 50vh;display: flex;align-items: center; justify-content: center;">
                    <div *ngIf="isVenueLoading">
                        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                        </mat-progress-spinner>
                    </div>
                    <div *ngIf="!isVenueLoading">
                        Please Enter Venue Name
                    </div>
                </div>
            </div>
            <div *ngIf="listOfVenue.length>0">
                <div class="heading">
                    Venue List
                </div>
                <div class="venue-list">
                    <div *ngFor="let venue of listOfVenue" class="venue-box" (click)="selectVenueHandler(venue)"
                        [ngClass]="{'venue-box__selected':venue['isSelected']}">
                        <div class="display-flex align-items-center">
                            <div class="venue-box__name">
                                {{venue['name']}}
                            </div>
                            <!-- <div>
                                <img src="../../../../assets/img/close.png" style="height: 1em;">
                            </div> -->
                        </div>
                        <div>
                            <div class="venue-box__address"
                                [ngClass]="{'venue-box__address-show-all':venue['showMoreAddress']}">
                                {{venue['address']['streetName']+',\
                                '+venue['address']['landmark']+', '
                                +venue['address']['city']+',\
                                '+venue['address']['pincode']}}
                            </div>
                            <div (click)="toggleShowMore(venue)"
                                style="color: #5624d0;font-size: 11px;cursor: pointer;">
                                {{venue['showMoreAddress']? 'Show Less': 'Show more'}}

                            </div>
                        </div>
                        <div class="display-flex justify-content-space-between align-items-center"
                            (click)="selectVenueHandler(venue)">
                            <div>
                                <img src="../../../../assets/svgIcons/star.svg" style="height: 1em;">
                                {{venue['rating']}}
                            </div>
                            <div>
                                <img src="../../../../assets/svgIcons/call.svg" style="height: 1em;"> {{venue['phone']}}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedVenue!=null && selectedVenue!=undefined">
                    <div style="display: flex;justify-content: space-between;">
                        <div class="heading">
                            Selected Venue
                        </div>
                        <div class="view-pricing" (click)="viewPricing()">
                            View Pricing
                        </div>
                    </div>
                    <div>
                        <div class="select-heading">
                            Select Sport
                        </div>
                        <div class="select-sport">
                            <div class="sport" *ngFor="let sportType of selectedVenue['sportsType']"
                                [ngClass]="{'selected': sportType['isSelected']}" (click)="selectSportType(sportType)">
                                <img *ngIf="sportType['sport']=='Cricket'" src="../../../../assets/svgIcons/cricket.svg"
                                    alt="">
                                <img *ngIf="sportType['sport']=='Tennis'" src="../../../../assets/svgIcons/ball.svg"
                                    alt="">
                                <img *ngIf="sportType['sport']=='Football'"
                                    src="../../../../assets/svgIcons/soccer-ball.svg" alt="">
                                <div class="sport-name">{{sportType['sport']}}</div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="select-heading">
                            Select Turf Size
                        </div>
                        <div class="select-turf-size">
                            <div class="turf-size" *ngFor="let size of selectedVenue['turfSize']"
                                [ngClass]="{'selected': size['isSelected']}" (click)="selectSportTurfSize(size)">
                                <div class="size">{{removeColonToX(size['title'])}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="time-slot-desktop">
                        <div class="select-heading">
                            Select Time Slot
                        </div>
                        <div class="select-time">
                            <div class="time" [ngClass]="{'selected': selectedSession ==dt['title']}"
                                (click)="searchSessionDataHandler(dt['title'])"
                                *ngFor="let dt of selectedVenue['turfSession']">
                                <img *ngIf="dt['src']!=null && dt['src']!=undefined && dt['src']!=''" [src]="dt['src']"
                                    alt="">
                                <div>
                                    {{dt['title']}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="select-heading">
                            <div>
                                Select Time Range
                            </div>
                            <div style="font-size: 13px;color: black;">
                                Total Amount ({{'\u20b9 '+getTotalAmount()}})
                            </div>
                        </div>
                        <div class="av">
                            <div class="available">
                                <div class="green"></div>
                                <div class="available-line">Available</div>
                            </div>
                            <div class="available">
                                <div class="grey"></div>
                                <div class="available-line">Sold Out</div>
                            </div>
                            <div class="available">
                                <div class="blue"></div>
                                <div class="available-line">Booked by me</div>
                            </div>
                            <div class="available">
                                <div class="red"></div>
                                <div class="available-line">Booked by other</div>
                            </div>
                        </div>
                        <div *ngIf="isSlotLoading"
                            style="height: 15em;display: flex;align-items: center;justify-content: center;">
                            <mat-progress-spinner mode="indeterminate" diameter="30" style="margin:0 auto;">
                            </mat-progress-spinner>
                        </div>
                        <div *ngIf="!isSlotLoading">
                            <div *ngIf="getSlotTimeBasedOnSession().length==0">
                                No slot available
                            </div>

                            <div class="times" *ngIf="getSlotTimeBasedOnSession().length>0">
                                <div class="time-slot" *ngFor="let dt of getSlotTimeBasedOnSession()"
                                    (click)="dt['isBlocked'] && !dt['isSelected']?null:selectSlotHandler(dt,dt['isSelected']?false:true)"
                                    [ngClass]="{'selected-and-bookedbyme': dt['isBookedByMe'],'selected-and-booked': dt['isSelected'] && dt['isBlocked'] && !dt['isBookedByMe'],'selected': dt['isSelected'] && !dt['isBlocked'] && !dt['isBookedByMe'],'blocked':dt['isBlocked'] && !dt['isBookedByMe'] && !dt['isSelected']}">
                                    <div class="time-slot-time"
                                        [ngClass]="{'selected': dt['isSelected'] || dt['isBookedByMe']}">
                                        {{dt['timeLabel']}}
                                    </div>
                                    <div class="price" *ngIf="selectedTurfSize!=null && selectedTurfSize!=undefined"
                                        [ngClass]="{'selected': dt['isSelected'] || dt['isBookedByMe']}">
                                        {{'\u20b9'+ (isWeekend()?
                                        dt[selectedTurfSize['title']]['weekEnd']:dt[selectedTurfSize['title']]['weekDay'])}}
                                        /-
                                    </div>
                                    <div class="price" *ngIf="selectedTurfSize==null || selectedTurfSize==undefined"
                                        [ngClass]="{'selected': dt['isSelected'] || dt['isBookedByMe']}">
                                        {{'\u20b9'+ (isWeekend()?
                                        dt[selectedVenue['turfSize'][0]['title']]['weekEnd']:dt[selectedVenue['turfSize'][0]['title']]['weekDay'])}}
                                        /-
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="first-box box-desktop">
            <div class="input-container">
                <input type="text" placeholder="Search by user number" class="search-user"
                    [(ngModel)]="userSearchString" (keyup.enter)="searchUserDataOnEnterHadler()"
                    (input)="searchUserDataHandler($event.target)">
                <img src="../../../../assets/svgIcons/search.svg" alt="" class="search-icon">
            </div>
            <div *ngIf="listOfUser.length==0 && isUserDataSearched">
                <div style="color: gray;">
                    No user Found with given detail
                </div>
            </div>
            <div *ngIf="listOfUser.length > 1 && isUserDataSearched">
                <div style="color: gray;">
                    user Found with given detail
                </div>
            </div>
            <div>
                <form name="userDetailForm" [formGroup]="userDetailForm" class="detail-form">
                    <div>
                        <label for="name" class="label required">User Name</label> <br>
                        <input type="text" class="input-style" placeholder="Enter name" formControlName="name">
                        <div class="err"
                            *ngIf="this.userDetailForm.get('name')?.touched && (this.userDetailForm.get('name')?.hasError('required') || this.userDetailForm.get('name')?.hasError('pattern'))">
                            Enter valid name.
                        </div>
                    </div>
                    <div class="phone-and-email">
                        <div class="input-container" style="width: 40%;">
                            <label for="phone" class="label required">Phone number</label> <br>
                            <input type="text" class="input-style" placeholder="Enter phone number"
                                formControlName="phone" maxlength="10" minlength="10">
                            <div class="err"
                                *ngIf="this.userDetailForm.get('phone')?.touched && (this.userDetailForm.get('phone')?.hasError('required') || this.userDetailForm.get('phone')?.hasError('pattern'))">
                                Enter valid phone.
                            </div>
                        </div>
                        <div style="width: 50%;">
                            <label for="email" class="label">Email Id</label> <br>
                            <input type="text" class="input-style" placeholder="Enter email id" formControlName="email">
                            <div class="err"
                                *ngIf="this.userDetailForm.get('email')?.touched && (this.userDetailForm.get('email')?.hasError('required') || this.userDetailForm.get('email')?.hasError('pattern'))">
                                Enter valid email.
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectedVenue!=null && selectedVenue!=undefined &&  selectedTurfSize!=null && selectedTurfSize!=undefined && isAdvancePayment()"
                        style="display: flex;align-items: center;justify-content: space-between ;">
                        <div class="payment-option" [ngClass]="{'isSelected':isFullPaymentSelected}"
                            (click)="setPaymentOption('Full Payment')">
                            <div style="font-size: 13px;font-weight: 500;">
                                Full Payment
                            </div>
                            <div style="font-size: 12px;font-weight: 500;">
                                {{'\u20b9 '+ ' '+getTotalAmount()}}
                            </div>
                        </div>
                        <div class="payment-option" [ngClass]="{'isSelected':!isFullPaymentSelected}"
                            (click)="setPaymentOption('Advance Payment')">
                            <div style="font-size: 13px;font-weight: 500;">
                                Advance Payment
                            </div>
                            <div style="font-size: 12px;font-weight: 500;">
                                {{'\u20b9 '+ ' '+getAdvanceAmount()}}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div (click)="getTotalAmount()>0?applyCouponHandler():null">
                            <span *ngIf="selectedCoupon!=null && selectedCoupon!=undefined">
                                <span class="apply-coupon">
                                    Change or Clear Coupon
                                </span>
                                <span *ngIf="getDiscountAmount()>0">
                                    (Discount of {{'\u20b9'+getDiscountAmount()}} applied)
                                </span>
                            </span>
                            <span class="apply-coupon" *ngIf="selectedCoupon==null || selectedCoupon==undefined">
                                Apply Coupon
                            </span>
                        </div>
                    </div>
                    <button type="button" class="send-payment-link-btn"
                        [ngClass]="{'send-payment-link-btn-selected':getPaidAmount()>0 && userDetailForm.valid}"
                        (click)="getPaidAmount()>0 && userDetailForm.valid?createBookingAndSendPaymentLink():null">
                        <div *ngIf="!isBookingLoading">
                            Send {{isFullPaymentSelected?'Full':'Advance'}} Payment Link {{'\u20b9\
                            '+((getPaidAmount()+getConvenienceAmt())-(isFullPaymentSelected?getDiscountAmount():0))}}
                        </div>
                        <div *ngIf="isBookingLoading">
                            <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                            </mat-progress-spinner>
                        </div>
                    </button>

                </form>
                <div>
                    <div class="label"
                        style="margin-top: 1.5rem;display: flex;align-items: center;justify-content: space-between;">
                        <div>
                            Previous Booking Detail
                        </div>
                        <div style="cursor: pointer;" (click)="getPreviousBookingsOfUser()">
                            <img src="../../../../assets/svgIcons/subway_round-arrow-1.svg" style="height: 1.5em;">
                            Refresh
                        </div>
                    </div>
                    <div class="status" *ngIf="isPreviousBookingLoad">
                        <mat-progress-spinner mode="indeterminate" diameter="30" style="margin:0 auto;">
                        </mat-progress-spinner>
                    </div>
                    <div class="status" *ngIf="!isPreviousBookingLoad">
                        <div *ngIf="listOfPreviousBookings.length==0" class="status-not-avl">
                            <!-- <img src="../../../../assets/svgIcons/subway_round-arrow-1.svg" style="height: 2em;"> -->
                            <div class="status-msg">No booking available</div>
                        </div>
                        <div *ngIf="listOfPreviousBookings.length>0" style="width: 100%;">

                            <div *ngFor="let dt of listOfPreviousBookings" class="status-avl">
                                <div>
                                    <div style="font-size: 20px;color: black;">
                                        {{dt['turfId']['name']}}
                                    </div>
                                    <div style="font-size: 11px;color: rgb(161, 161, 161);">
                                        {{dt['turfId']['address']['streetName']+',\
                                        '+dt['turfId']['address']['landmark']+', '
                                        +dt['turfId']['address']['city']+',\
                                        '+dt['turfId']['address']['pincode']}}
                                    </div>

                                </div>
                                <div
                                    style="display: flex;align-items: center;width: 100%;justify-content: space-between;">
                                    <div>
                                        <div style="color: gray;">
                                            Date
                                        </div>
                                        <div style="color: black;font-weight: 500;">
                                            {{dt['ogBookingDate'] | date}}
                                        </div>
                                    </div>
                                    <div>
                                        <div style="color: gray;">
                                            Time
                                        </div>
                                        <div style="color: black;font-weight: 500;">
                                            {{(dt['bookingSlotStartTime']>12?(dt['bookingSlotStartTime']-12).toFixed(2)+'
                                            '+(dt['bookingSlotStartTime']>12?'PM':'AM'):dt['bookingSlotStartTime'].toFixed(2)+'
                                            '+(dt['bookingSlotStartTime']>12?'PM':'AM') )}}
                                            -
                                            {{dt['bookingSlotEndTime']>12?(dt['bookingSlotEndTime']-12).toFixed(2)+'
                                            '+(dt['bookingSlotEndTime']>12?'PM':'AM'):dt['bookingSlotEndTime'].toFixed(2)+'
                                            '+ (dt['bookingSlotEndTime']>12?'PM':'AM')}}
                                        </div>
                                    </div>
                                    <div>
                                        <div style="color: gray;">
                                            Sport
                                        </div>
                                        <div style="color: black;font-weight: 500;">
                                            {{dt['sport']['sport']}}
                                        </div>
                                    </div>
                                    <div>
                                        <div style="color: gray;">
                                            Source
                                        </div>
                                        <div style="color: black;font-weight: 500;">
                                            {{dt['bookedBy']}}
                                        </div>
                                    </div>
                                    <div>
                                        <div style="color: gray;">
                                            Payment
                                        </div>
                                        <div style="color: black;font-weight: 500;">
                                            {{dt['paymentStatus']}}
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="dt['paymentStatus']=='SUCCESS' && !isUpcomingBooking(dt['ogBookingDate'])">
                                        <div style="color: gray;">
                                            Action
                                        </div>
                                        <div style="color: black;font-weight: 500;">

                                            <div mat-button [matMenuTriggerFor]="menu"
                                                style="cursor: pointer;    text-align: center;">
                                                <img class="dots"
                                                    style="width: 2rem; cursor: pointer;    width: 1.2rem;"
                                                    src="../../../../assets/svgIcons/verticleDots.svg" alt=""
                                                    srcset="" />
                                            </div>

                                            <mat-menu #menu="matMenu">
                                                <button class="menu" mat-menu-item (click)="cancelBooking(dt)">
                                                    Cancel
                                                </button> </mat-menu>

                                        </div>
                                    </div>
                                    <div
                                        *ngIf="(dt['bookingPaymentLink']!=null &&dt['bookingPaymentLink']!=undefined) && dt['paymentStatus']!='SUCCESS' && dt['paymentStatus']!='REFUND'">
                                        <div style="color: gray;">
                                            Link
                                        </div>
                                        <div style="color: blue;cursor: pointer;font-weight: 500;"
                                            [cdkCopyToClipboard]="dt['bookingPaymentLink']">
                                            Copy
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- </div> -->
            </div>
        </div>
        <div class="first-box box-mobile" *ngIf="venue">
            <div class="search-date">
                <div class="input-container" style="display: flex;width: 58%;">
                    <input type="text" placeholder="Search venue & locality" class="search-style"
                        [(ngModel)]="venueSearchString" (keyup.enter)="searchVenueDataOnEnterHadler()"
                        (input)="searchVenueDataHandler($event.target)" (keydown)="searchVenueKeydownHandler()">
                    <!-- <img src="../../../../assets/svgIcons/search.svg" alt="" class="search-icon"> -->
                </div>

                <div class="input-container" style="display: flex;">
                    <mat-form-field class="example-full-width" style="opacity: 0;height: 0px;width: 0px;">
                        <input matInput [matDatepicker]="picker" style="display: none;"
                            (dateChange)="dateChangeHandler($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <div for="getDate" class="date-set" (click)="picker.open()" style="cursor: pointer;">
                        <img src="../../../../assets/svgIcons/solar_calendar-outline.svg" alt="" class="date-icon">
                        <div class="date-written">{{selectedDate==null || selectedDate==undefined?
                            'Date':(selectedDate
                            | date)}}</div>
                    </div>
                    <!-- <input type="date" id="getDate" style="display: none;"> -->
                </div>
                <div (click)="searchVenueDataOnEnterHadler()"
                    style="border-radius: 0.5rem;border: 1px solid #499e30b8;width: 10%; display: flex;align-items: baseline;justify-content: center;">
                    <img src="../../../../assets/svgIcons/search.svg" style="width: 1rem;height: 100%;">
                </div>
            </div>
            <div *ngIf="listOfVenue.length==0">
                <div style="color: gray;height: 50vh;display: flex;align-items: center; justify-content: center;">
                    <div *ngIf="isVenueLoading">
                        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                        </mat-progress-spinner>
                    </div>
                    <div *ngIf="!isVenueLoading">
                        Please Enter Venue Name
                    </div>
                </div>
            </div>
            <div *ngIf="listOfVenue.length>0">
                <div class="heading">
                    Venue List
                </div>
                <div class="venue-list">
                    <div *ngFor="let venue of listOfVenue" class="venue-box" (click)="selectVenueHandler(venue)"
                        [ngClass]="{'venue-box__selected':venue['isSelected']}">
                        <div class="display-flex align-items-center">
                            <div class="venue-box__name">
                                {{venue['name']}}
                            </div>
                            <!-- <div>
                                <img src="../../../../assets/img/close.png" style="height: 1em;">
                            </div> -->
                        </div>
                        <div>
                            <div class="venue-box__address"
                                [ngClass]="{'venue-box__address-show-all':venue['showMoreAddress']}">
                                {{venue['address']['streetName']+',\
                                '+venue['address']['landmark']+', '
                                +venue['address']['city']+',\
                                '+venue['address']['pincode']}}
                            </div>
                            <div (click)="toggleShowMore(venue)"
                                style="color: #5624d0;font-size: 11px;cursor: pointer;">
                                {{venue['showMoreAddress']? 'Show Less': 'Show more'}}

                            </div>
                        </div>
                        <div class="display-flex justify-content-space-between align-items-center"
                            (click)="selectVenueHandler(venue)">
                            <div>
                                <img src="../../../../assets/svgIcons/star.svg" style="height: 1em;">
                                {{venue['rating']}}
                            </div>
                            <div>
                                <img src="../../../../assets/svgIcons/call.svg" style="height: 1em;"> {{venue['phone']}}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedVenue!=null && selectedVenue!=undefined">
                    <div style="display: flex;justify-content: space-between;">
                        <div class="heading">
                            Selected Venue
                        </div>
                        <div class="view-pricing" (click)="viewPricing()">
                            View Pricing
                        </div>
                    </div>
                    <div>
                        <div class="select-heading">
                            Select Sport
                        </div>
                        <div class="select-sport">
                            <div class="sport" *ngFor="let sportType of selectedVenue['sportsType']"
                                [ngClass]="{'selected': sportType['isSelected']}" (click)="selectSportType(sportType)">
                                <img *ngIf="sportType['sport']=='Cricket'" src="../../../../assets/svgIcons/cricket.svg"
                                    alt="">
                                <img *ngIf="sportType['sport']=='Tennis'" src="../../../../assets/svgIcons/ball.svg"
                                    alt="">
                                <img *ngIf="sportType['sport']=='Football'"
                                    src="../../../../assets/svgIcons/soccer-ball.svg" alt="">
                                <div class="sport-name">{{sportType['sport']}}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="select-heading">
                            Select Turf Size
                        </div>
                        <div class="select-turf-size">
                            <div class="turf-size" *ngFor="let size of selectedVenue['turfSize']"
                                [ngClass]="{'selected': size['isSelected']}" (click)="selectSportTurfSize(size)">
                                <div class="size">{{removeColonToX(size['title'])}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="time-slot-mobile">
                        <div class="select-heading">
                            Select Time Slot
                        </div>
                        <div class="select-time">
                            <div class="time" [ngClass]="{'selected': selectedSession ==dt['title']}"
                                (click)="searchSessionDataHandler(dt['title'])"
                                *ngFor="let dt of selectedVenue['turfSession']">
                                <img *ngIf="dt['src']!=null && dt['src']!=undefined && dt['src']!=''" [src]="dt['src']"
                                    alt="">
                                <div>
                                    {{dt['title']}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="select-heading">
                            <div>
                                Select Time Range
                            </div>
                            <div style="font-size: 13px;color: black;">
                                Total Amount ({{'\u20b9 '+getTotalAmount()}})
                            </div>
                        </div>
                        <div class="av">
                            <div class="available">
                                <div class="green"></div>
                                <div class="available-line">Available</div>
                            </div>
                            <div class="available">
                                <div class="grey"></div>
                                <div class="available-line">Sold Out</div>
                            </div>
                            <div class="available">
                                <div class="blue"></div>
                                <div class="available-line">Booked by me</div>
                            </div>
                            <div class="available">
                                <div class="red"></div>
                                <div class="available-line">Booked by other</div>
                            </div>
                        </div>
                        <div *ngIf="isSlotLoading"
                            style="height: 15em;display: flex;align-items: center;justify-content: center;">
                            <mat-progress-spinner mode="indeterminate" diameter="30" style="margin:0 auto;">
                            </mat-progress-spinner>
                        </div>
                        <div *ngIf="!isSlotLoading">
                            <div *ngIf="getSlotTimeBasedOnSession().length==0">
                                No slot available
                            </div>
                            <!-- {{getJSONText()}} -->
                            <div class="times" *ngIf="getSlotTimeBasedOnSession().length>0">
                                <div class="time-slot" *ngFor="let dt of getSlotTimeBasedOnSession()"
                                    (click)="dt['isBlocked'] && !dt['isSelected']?null:selectSlotHandler(dt,dt['isSelected']?false:true)"
                                    [ngClass]="{'selected-and-bookedbyme': dt['isBookedByMe'],'selected-and-booked': dt['isSelected'] && dt['isBlocked'] && !dt['isBookedByMe'],'selected': dt['isSelected'] && !dt['isBlocked'] && !dt['isBookedByMe'],'blocked':dt['isBlocked'] && !dt['isBookedByMe'] && !dt['isSelected']}">
                                    <div class="time-slot-time"
                                        [ngClass]="{'selected': dt['isSelected'] || dt['isBookedByMe']}">
                                        {{dt['timeLabel']}}
                                    </div>
                                    <div class="price" *ngIf="selectedTurfSize!=null && selectedTurfSize!=undefined"
                                        [ngClass]="{'selected': dt['isSelected'] || dt['isBookedByMe']}">
                                        {{'\u20b9'+ (isWeekend()?
                                        dt[selectedTurfSize['title']]['weekEnd']:dt[selectedTurfSize['title']]['weekDay'])}}
                                        /-
                                    </div>
                                    <div class="price" *ngIf="selectedTurfSize==null || selectedTurfSize==undefined"
                                        [ngClass]="{'selected': dt['isSelected'] || dt['isBookedByMe']}">
                                        {{'\u20b9'+ (isWeekend()?
                                        dt[selectedVenue['turfSize'][0]['title']]['weekEnd']:dt[selectedVenue['turfSize'][0]['title']]['weekDay'])}}
                                        /-
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="first-box box-mobile" *ngIf="!venue">
            <div class="input-container" style="display: flex;">
                <div style="position: relative;width: 100%;">
                    <input type="text" placeholder="Search by user number" class="search-user" style="width: 95%;"
                        [(ngModel)]="userSearchString" (keyup.enter)="searchUserDataOnEnterHadler()"
                        (input)="searchUserDataHandler($event.target)">
                    <!-- <img src="../../../../assets/svgIcons/search.svg" alt="" class="search-icon"> -->
                </div>

                <div (click)="searchUserDataOnEnterHadler()"
                    style="border-radius: 0.5rem;border: 1px solid #499e30b8;width: 10%; display: flex;align-items: baseline;justify-content: center;">
                    <img src="../../../../assets/svgIcons/search.svg" style="width: 1rem;height: 100%;">
                </div>
            </div>
            <div *ngIf="listOfUser.length==0 && isUserDataSearched">
                <div style="color: gray;">
                    No user Found with given detail
                </div>
            </div>
            <div *ngIf="listOfUser.length > 1 && isUserDataSearched">
                <div style="color: gray;">
                    user Found with given detail
                </div>
            </div>
            <div>
                <form name="userDetailForm" [formGroup]="userDetailForm" class="detail-form">
                    <div>
                        <label for="name" class="label required">User Name</label> <br>
                        <input type="text" class="input-style" placeholder="Enter name" formControlName="name">
                        <div class="err"
                            *ngIf="this.userDetailForm.get('name')?.touched && (this.userDetailForm.get('name')?.hasError('required') || this.userDetailForm.get('name')?.hasError('pattern'))">
                            Enter valid name.
                        </div>
                    </div>
                    <!-- <div class="phone-and-email"> -->
                    <div>
                        <label for="phone" class="label required">Phone number</label> <br>
                        <input type="text" class="input-style" placeholder="Enter phone number" formControlName="phone"
                            maxlength="10" minlength="10">
                        <div class="err"
                            *ngIf="this.userDetailForm.get('phone')?.touched && (this.userDetailForm.get('phone')?.hasError('required') || this.userDetailForm.get('phone')?.hasError('pattern'))">
                            Enter valid phone.
                        </div>
                    </div>
                    <div>
                        <label for="email" class="label">Email Id</label> <br>
                        <input type="text" class="input-style" placeholder="Enter email id" formControlName="email">
                        <div class="err"
                            *ngIf="this.userDetailForm.get('email')?.touched && (this.userDetailForm.get('email')?.hasError('required') || this.userDetailForm.get('email')?.hasError('pattern'))">
                            Enter valid email.
                        </div>
                    </div>
                    <div *ngIf="selectedVenue!=null && selectedVenue!=undefined &&  selectedTurfSize!=null && selectedTurfSize!=undefined && isAdvancePayment()"
                        style="display: flex;align-items: center;justify-content: space-between ;">
                        <div class="payment-option" [ngClass]="{'isSelected':isFullPaymentSelected}"
                            (click)="setPaymentOption('Full Payment')">
                            <div style="font-size: 13px;font-weight: 500;">
                                Full Payment
                            </div>
                            <div style="font-size: 12px;font-weight: 500;">
                                {{'\u20b9 '+ ' '+getTotalAmount()}}
                            </div>
                        </div>
                        <div class="payment-option" [ngClass]="{'isSelected':!isFullPaymentSelected}"
                            (click)="setPaymentOption('Advance Payment')">
                            <div style="font-size: 13px;font-weight: 500;">
                                Advance Payment
                            </div>
                            <div style="font-size: 12px;font-weight: 500;">
                                {{'\u20b9 '+ ' '+getAdvanceAmount()}}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div (click)="getTotalAmount()>0?applyCouponHandler():null">
                            <span *ngIf="selectedCoupon!=null && selectedCoupon!=undefined">
                                <span class="apply-coupon">
                                    Change or Clear Coupon
                                </span>
                                <span *ngIf="getDiscountAmount()>0">
                                    (Discount of {{'\u20b9'+getDiscountAmount()}} applied)
                                </span>
                            </span>
                            <span class="apply-coupon" *ngIf="selectedCoupon==null || selectedCoupon==undefined">
                                Apply Coupon
                            </span>
                        </div>
                    </div>
                    <!-- </div> -->
                    <button type="button" class="send-payment-link-btn"
                        [ngClass]="{'send-payment-link-btn-selected':getPaidAmount()>0 && userDetailForm.valid}"
                        (click)="getPaidAmount()>0 && userDetailForm.valid?createBookingAndSendPaymentLink():null">
                        <div *ngIf="!isBookingLoading">
                            Send {{isFullPaymentSelected?'Full':'Advance'}} Payment Link {{'\u20b9\
                            '+((getPaidAmount()+getConvenienceAmt())-(isFullPaymentSelected?getDiscountAmount():0))}}
                        </div>
                        <div *ngIf="isBookingLoading">
                            <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
                            </mat-progress-spinner>
                        </div>
                    </button>
                </form>
                <div>
                    <div class="label"
                        style="margin-top: 1.5rem;display: flex;align-items: center;justify-content: space-between;">
                        <div>
                            Previous Booking Detail
                        </div>
                        <div style="cursor: pointer;" (click)="getPreviousBookingsOfUser()">
                            <img src="../../../../assets/svgIcons/subway_round-arrow-1.svg" style="height: 1.5em;">
                            Refresh
                        </div>
                    </div>
                    <div class="status" *ngIf="isPreviousBookingLoad">
                        <mat-progress-spinner mode="indeterminate" diameter="30" style="margin:0 auto;">
                        </mat-progress-spinner>
                    </div>
                    <div class="status" *ngIf="!isPreviousBookingLoad">
                        <div *ngIf="listOfPreviousBookings.length==0" class="status-not-avl">
                            <!-- <img src="../../../../assets/svgIcons/subway_round-arrow-1.svg" style="height: 2em;"> -->
                            <div class="status-msg">No booking available</div>
                        </div>
                        <div *ngIf="listOfPreviousBookings.length>0" style="width: 100%;">

                            <div *ngFor="let dt of listOfPreviousBookings" class="status-avl">
                                <div>
                                    <div style="font-size: 20px;color: black;">
                                        {{dt['turfId']['name']}}
                                    </div>
                                    <div style="font-size: 11px;color: rgb(161, 161, 161);">
                                        {{dt['turfId']['address']['streetName']+',\
                                        '+dt['turfId']['address']['landmark']+', '
                                        +dt['turfId']['address']['city']+',\
                                        '+dt['turfId']['address']['pincode']}}
                                    </div>

                                </div>
                                <div
                                    style="display: flex;align-items: center;width: 100%;justify-content: space-between;">
                                    <div>
                                        <div style="color: gray;">
                                            Date
                                        </div>
                                        <div style="color: black;font-weight: 500;">
                                            {{dt['ogBookingDate'] | date}}
                                        </div>
                                    </div>
                                    <div>
                                        <div style="color: gray;">
                                            Time
                                        </div>
                                        <div style="color: black;font-weight: 500;">
                                            {{(dt['bookingSlotStartTime']>12?(dt['bookingSlotStartTime']-12).toFixed(2)+'
                                            '+(dt['bookingSlotStartTime']>12?'PM':'AM'):dt['bookingSlotStartTime'].toFixed(2)+'
                                            '+(dt['bookingSlotStartTime']>12?'PM':'AM') )}}
                                            -
                                            {{dt['bookingSlotEndTime']>12?(dt['bookingSlotEndTime']-12).toFixed(2)+'
                                            '+(dt['bookingSlotEndTime']>12?'PM':'AM'):dt['bookingSlotEndTime'].toFixed(2)+'
                                            '+ (dt['bookingSlotEndTime']>12?'PM':'AM')}}
                                        </div>
                                    </div>
                                    <div>
                                        <div style="color: gray;">
                                            Sport
                                        </div>
                                        <div style="color: black;font-weight: 500;">
                                            {{dt['sport']['sport']}}
                                        </div>
                                    </div>
                                    <div>
                                        <div style="color: gray;">
                                            Source
                                        </div>
                                        <div style="color: black;font-weight: 500;">
                                            {{dt['bookedBy']}}
                                        </div>
                                    </div>
                                    <div>
                                        <div style="color: gray;">
                                            Payment
                                        </div>
                                        <div style="color: black;font-weight: 500;">
                                            {{dt['paymentStatus']}}
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="dt['paymentStatus']=='SUCCESS' && !isUpcomingBooking(dt['ogBookingDate'])">
                                        <div style="color: gray;">
                                            Action
                                        </div>
                                        <div style="color: black;font-weight: 500;">

                                            <div mat-button [matMenuTriggerFor]="menu"
                                                style="cursor: pointer;    text-align: center;">
                                                <img class="dots"
                                                    style="width: 2rem; cursor: pointer;    width: 1.2rem;"
                                                    src="../../../../assets/svgIcons/verticleDots.svg" alt=""
                                                    srcset="" />
                                            </div>

                                            <mat-menu #menu="matMenu">
                                                <button class="menu" mat-menu-item (click)="cancelBooking(dt)">
                                                    Cancel
                                                </button> </mat-menu>

                                        </div>
                                    </div>
                                    <div
                                        *ngIf="(dt['bookingPaymentLink']!=null &&dt['bookingPaymentLink']!=undefined) && dt['paymentStatus']!='SUCCESS' && dt['paymentStatus']!='REFUND'">
                                        <div style="color: gray;">
                                            Link
                                        </div>
                                        <div style="color: blue;cursor: pointer;font-weight: 500;"
                                            [cdkCopyToClipboard]="dt['bookingPaymentLink']">
                                            Copy
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>