import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { webApi } from 'src/app/api';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { AppService } from 'src/app/app.service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-add-edit-business',
  templateUrl: './add-edit-business.component.html',
  styleUrls: ['./add-edit-business.component.scss'],
})
export class AddEditBusinessComponent implements OnInit {
  @Input() dataToEdit: any;
  @Input() isAdd: boolean = false;
  @ViewChild('stepper') stepper: MatStepper | undefined;
  @Output() backToPreviousStepper: EventEmitter<any> = new EventEmitter();
  @Output() closeEdit: EventEmitter<any> = new EventEmitter();

  selectedStepperIndex: number = 0;
  // businessForm!: FormGroup;


  isLoading: boolean = true;
  userDetails: any;

  businessId: any;
  userId: any;
  submitted: boolean = false;

  selectedPancardImage: any = null;
  selectedPancardImageName: any = null;
  pancardPreview: any;

  cancelledChequeLaefPreview: any;
  selectedChequeLeafImage: any = null;
  selectedChequeLeafImageName: any = null;
 
  businessInfo: any;

  isAddEditAboutLoading: boolean = false;
  showOpenCloseFields: boolean = false;

  addBusinessForm!: FormGroup;

  constructor(
    private _formBuilder: FormBuilder, private _location: Location,
    private router: Router, private _activatedRoute: ActivatedRoute,
    private appService: AppService,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
  }

  ngOnInit(): void {
    // this._activatedRoute.queryParams.subscribe(params => {
    //   this.businessId = params['id'];
    //   console.log('ID from queryParams:', this.businessId);
    // });

    this.businessId = this._activatedRoute.snapshot.paramMap.get('id');
    console.log(this.businessId);

    this.addBusinessForm = this._formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      pancardNumber: new FormControl('', [Validators.required]),
      accountHolderName: new FormControl('', [Validators.required]),
      bankName: new FormControl('', [Validators.required]),
      accountNumber: new FormControl('', [Validators.required]),
      ifscCode: new FormControl('', [Validators.required]),
    });

    if (this.businessId) {
      this.fetchBusinessByIdv2();
    }else{
      this.isLoading = false;
    }
    
  }

  createForm() {
    return this._formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      pancardNumber: ['', [Validators.required]],
      accountHolderName: ['', [Validators.required]],
      bankName: ['', [Validators.required]],
      accountNumber: ['', [Validators.required]],
      ifscCode: ['', [Validators.required]],
    });
  }


  fetchBusinessByIdv2() {
    this.isLoading = true;
    try {
      let URL = '';
      URL = `${webApi.domain + webApi.endPoint.fetchBusinessByIdv2 + `/${this.businessId}`}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      this.appService.getMethodWithToken(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.businessInfo = data.result;
          console.log(this.businessInfo);
          if (this.businessInfo && this.businessInfo.user && this.businessInfo.user._id) {
            this.userId = this.businessInfo.user._id;
            console.log(this.userId);
          this.addBusinessForm
            .get('firstName')
            ?.setValue(this.businessInfo['user']['firstName']);
          this.addBusinessForm.get('lastName')?.setValue(this.businessInfo['user']['lastName']);
          this.addBusinessForm.get('email')?.setValue(this.businessInfo['user']['email']);
          this.addBusinessForm.get('phone')?.setValue(this.businessInfo['user']['phone']);
          this.addBusinessForm.get('pancardNumber')?.setValue(this.businessInfo['document'][0]['docNumber']);
          this.addBusinessForm.get('accountHolderName')?.setValue(this.businessInfo['bankDetails'][0]['accountHolderName']);
          this.addBusinessForm.get('bankName')?.setValue(this.businessInfo['bankDetails'][0]['bankName']);
          this.addBusinessForm.get('accountNumber')?.setValue(this.businessInfo['bankDetails'][0]['accountNumber']);
          this.addBusinessForm.get('ifscCode')?.setValue(this.businessInfo['bankDetails'][0]['ifscCode']);

          this.cancelledChequeLaefPreview = this.businessInfo['bankDetails'][0].image;
          this.pancardPreview =  this.businessInfo['bankDetails'][0].image;
        } else {
          console.error('User or user._id is undefined or null');
        }
        }
        this.isLoading = false;
      });
    } catch (err) {
      this.isLoading = false;
      console.log(err);
    }
  }

  cancel() {
    this._location.back();
  }

  stepperBackHandler() {
    if (this.selectedStepperIndex == 0) {
      this.cancel();
    } else {
      this.stepper?.previous();
    }
  }

  onStepperChangeHandler(event: any) {
    console.log(event);
    this.selectedStepperIndex = event.selectedIndex;
  }

  selectCancelledLeafChequeFile(event: any) {
    this.selectedChequeLeafImage = event.target.files[0];
    this.selectedChequeLeafImageName =this.selectedChequeLeafImage['name'];
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.cancelledChequeLaefPreview = event.target.result;
    };
    -reader.readAsDataURL(this.selectedChequeLeafImage);
  }

  selectPancardFile(event: any) {
    this.selectedPancardImage = event.target.files[0];
    this.selectedPancardImageName = this.selectedPancardImage['name'];
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.pancardPreview = event.target.result;
    };
    -reader.readAsDataURL(this.selectedPancardImage);
  }

  removeCancelledLeafChequeImage() {
    this.cancelledChequeLaefPreview = null;
    this.selectedChequeLeafImage = null;
  }
  removePancardImage() {
    this.pancardPreview = null;
    this.selectedPancardImage = null;
  }

  downloadImage(url: any) {
    window.open(url);
  }

  backToList() {
    this.router.navigateByUrl('/admin/business-management');
  }


  onSubmitBusiness() {
    try{
    this.isLoading = true;
    this.isAddEditAboutLoading = true

    var formdata = new FormData();

    console.log(this.businessId);

    if(this.businessId){
      formdata.set('businessId', this.businessId);
      formdata.set('userId', this.userId);
      if (this.dataToEdit) {
        // formdata.append('userId', this.userId);
        formdata.append(
          'panCardUrl',
          this.dataToEdit['document'][0]['docUrl']
        );
        formdata.append(
          'chequeImageUrl',
          this.dataToEdit['bankDetails'][0]['chequeImageUrl']
        );
      }
    }else{
      formdata.append('businessId', '0');
    }

    if (this.selectedPancardImage != null) {
      formdata.append(
        'panCardUrl',
        this.selectedPancardImage,
        this.selectedPancardImageName
      );
    }
    if (this.selectedChequeLeafImage != null) {
      formdata.append(
        'chequeImageUrl',
        this.selectedChequeLeafImage,
        this.selectedChequeLeafImageName
      );
    }

    formdata.set(
      'firstName',
      this.addBusinessForm.get('firstName')?.value
    );
    formdata.set(
      'lastName',
      this.addBusinessForm.get('lastName')?.value
    );
    formdata.set('email', this.addBusinessForm.get('email')?.value);
    formdata.set('phone', this.addBusinessForm.get('phone')?.value);
    formdata.set(
      'pancardNumber',
      this.addBusinessForm.get('pancardNumber')?.value
    );
    formdata.set(
      'accountHolderName',
      this.addBusinessForm.get('accountHolderName')?.value
    );
    formdata.set(
      'bankName',
      this.addBusinessForm.get('bankName')?.value
    );
    formdata.set(
      'accountNumber',
      this.addBusinessForm.get('accountNumber')?.value
    );
    formdata.set(
      'ifscCode',
      this.addBusinessForm.get('ifscCode')?.value
    );

    var requestOptions: RequestInit = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
      headers: new Headers({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      }),
    };
    var URL;
    if (this.businessId != null) {
      URL = `${webApi.domain + webApi.endPoint.createAndEditUserAndBusiness}`;
    } else {
      URL = `${webApi.domain + webApi.endPoint.createAndEditUserAndBusiness}`;
    }

    try {
      fetch(URL, requestOptions).then((result) => {
        if (result) {
          let data = result.json();
          data.then((res) => {
            if (res['success']) {
              this.isAddEditAboutLoading = false;
              if (this.businessId != null) {
                this.showSuccessToaster('Store edited successfully', 'Success');
              } else {
                this.showSuccessToaster('Store added successfully', 'Success');
              }
              window.history.back();
            } else {
              this.isAddEditAboutLoading = false;
              if (this.businessId != null) {
                this.showErrorToaster(res['message'], 'Warning');
              } else {
                this.showErrorToaster(res['message'], 'Warning');
              }
            }
          });
        }
      });
    }
    catch (error) {
      this.isAddEditAboutLoading = false;
      if (this.businessId) {
        this.showWarningToaster('Unable to edit Employee details', 'Warning');
      } else {
        this.showWarningToaster('Unable to add Employee', 'Warning');
      }
    } 
  }catch (error) {
      this.isAddEditAboutLoading = false;
      this.showWarningToaster('Unable to add Employees', 'Warning');
    }
  }

  cancelBtnHandler() {
    this.backToPreviousStepper.emit();
  }

  closeEditBtnHandler() {
    this.closeEdit.emit();
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title, {
      titleClass: 'titleClass',
      messageClass: 'messageClass',
    });
  }

}
