<div class="container_addressDetail" *ngIf="!isLoading">
    <div class="container_addressDetail_main">
        <div class="container_addressDetail_title">Opened On Days</div>
        <div>
            <form>
                <div class="row">
                    <div class="col-md-6">
                        <label class="label mt-5">Days</label>

                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Days'"
                            [ngModelOptions]="{standalone: true}" [settings]="daysDropdownSettings" [data]="listOfDays"
                            [(ngModel)]="selectedDays" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)"
                            (onSelectAll)="onSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="col-md-6">
                        <label class="label mt-5">Select Sports Category</label>

                        <!-- <div class="row">
                            <div class="col-md-12" margin style="display: flex;align-items: center;margin-bottom: .5rem;"
                                *ngFor="let data of sportCategory;">
                                <option type="radio" name="radio-btn" style="margin-right: .5rem;" [id]="data['id']"
                                     (change)="radioHandlerSportCategory($event)">{{data['categoryName']}} </option>
                                <label [for]="data['categoryName']" class="lbl-radio">{{data['categoryName']}}</label>
                            </div>
                        </div> -->
                        <select id="business" name="business" class="input-style" formControlName="categoryName">
                            <option value="Select Business">Select Sport Category</option>
                            <option *ngFor="let data of sportCategory" [value]="data['_id']">{{data['categoryName']}}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="label mt-5">Available Sports</label>
                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Sport'"
                        [ngModelOptions]="{standalone: true}" [settings]="sportsTypeDropdownSettings" [data]="getSports()"
                        [(ngModel)]="selectedSport" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)"
                        (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
                    </div>
                </div>

                <div class="dashedLine"></div>

                <div class="row">
                    <div class="col-md-12 venue-timing"  style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em; ">
                        Venue Timing & Pricing:-
                    </div>
                </div>
                <div style="display: flex;">
                    <div style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em;">
                        <input type="checkbox" [checked]="isMorningSlotSelected"
                            (change)="changeSlotSelection('Morning',$event)">
                        <label class="checkbox-label" style="margin-left: 1em;">Morning Slot</label>
                    </div>
                    <div style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em; margin-left: 1rem;">
                        <input type="checkbox" [checked]="isAfternoonSlotSelected"
                            (change)="changeSlotSelection('Afternoon',$event)"> 
                            <label class="checkbox-label" style="margin-left: 1em;">AfternoonSlot</label>
                    </div>    
                    <div style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em; margin-left: 1rem;">
                        <input type="checkbox" [checked]="isEveningSlotSelected"
                            (change)="changeSlotSelection('Evening',$event)"> 
                            <label class="checkbox-label" style="margin-left: 1em;">Evening Slot</label>
                    </div>        
                    <div style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em; margin-left: 1rem;">
                        <input type="checkbox" [checked]="isNightSlotSelected" (change)="changeSlotSelection('Night',$event)">
                        <label class="checkbox-label" style="margin-left: 1em;">Night Slot</label>
        
                    </div>        
                </div>
                <div *ngIf="isMorningSlotSelected && selectedSportCategory['categoryName']=='Outdoor'" class="row"
                    style="width: 38em;">
                    <div class="col-md-12" style="margin-bottom: 1em;">
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekday price</div>
                            <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekend price</div>
                        </div>
                    </div>
                    <div class="col-md-12" *ngFor="let data of listOfPriceForMorning;">
                        <div>
                            <input type="checkbox" [checked]="data['isSelected']"
                                (change)="changePricingSelectionForMorning(data['title'],$event)">
                            <label style=" width: 3em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                            <input *ngIf="checkForWeekday()" type="number" placeholder="Enter weekdays price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Morning',false)">
                            <input *ngIf="checkForWeekend()" type="number" placeholder="Enter weekend price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                                style="width: 25%;" maxlength="5"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Morning',true)">
                        </div>
                    </div>
                </div>
                <div *ngIf="isMorningSlotSelected && selectedSportCategory['categoryName']!='Outdoor'" class="row"
                    style="width: 38em;">
                    <div class="col-md-12" style="margin-bottom: 1em;">
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekday price</div>
                            <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;">Weekend price</div>
                        </div>
                    </div>
                    <div class="col-md-12" *ngFor="let data of listofSlotForMorning;">
                        <div>
                            <input type="checkbox" [checked]="data['isSelected']"
                                (change)="changePricingSelectionForMorning(data['title'],$event)">
                            <label style=" width: 6em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                            <input *ngIf="checkForWeekday()" type="number" placeholder="Enter weekdays price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Morning',false)">
                            <input *ngIf="checkForWeekend()" type="number" placeholder="Enter weekend price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                                style="width: 25%;" maxlength="5"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Morning',true)">
                        </div>
                    </div>
                </div>
                <div *ngIf="isAfternoonSlotSelected && selectedSportCategory['categoryName']=='Outdoor'" class="row"
                    style="width: 38em;">
                    <div class="col-md-12" style="margin-bottom: 1em;">
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekday price</div>
                            <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekend price</div>
                        </div>
                    </div>

                    <div class="col-md-12" *ngFor="let data of listOfPriceForAfternoon;">
                        <div>
                            <input type="checkbox" [checked]="data['isSelected']"
                                (change)="changePricingSelectionForAfternoon(data['title'],$event)">
                            <label style=" width: 3em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                            <input *ngIf="checkForWeekday()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Afternoon',false)">
                            <input *ngIf="checkForWeekend()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Afternoon',true)">
                        </div>
                    </div>
                </div>
                <div *ngIf="isAfternoonSlotSelected && selectedSportCategory['categoryName']!='Outdoor'" class="row"
                    style="width: 38em;">
                    <div class="col-md-12" style="margin-bottom: 1em;">
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekday price</div>
                            <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;">Weekend price</div>
                        </div>
                    </div>

                    <div class="col-md-12" *ngFor="let data of listofSlotForAfternoon;">
                        <div>
                            <input type="checkbox" [checked]="data['isSelected']"
                                (change)="changePricingSelectionForAfternoon(data['title'],$event)">
                            <label style=" width: 6em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                            <input *ngIf="checkForWeekday()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Afternoon',false)">
                            <input *ngIf="checkForWeekend()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Afternoon',true)">
                        </div>
                    </div>
                </div>
                <div *ngIf="isEveningSlotSelected && selectedSportCategory['categoryName']=='Outdoor'" class="row"
                    style="width: 38em;">

                    <div class="col-md-12" style="margin-bottom: 1em;">
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekday price</div>
                            <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekend price</div>
                        </div>
                    </div>

                    <div class="col-md-12" *ngFor="let data of listOfPriceForEvening;">
                        <div>
                            <input type="checkbox" [checked]="data['isSelected']"
                                (change)="changePricingSelectionForEvening(data['title'],$event)">
                            <label style=" width: 3em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                            <input *ngIf="checkForWeekday()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Evening',false)">
                            <input *ngIf="checkForWeekend()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Evening',true)">
                        </div>
                    </div>
                </div>
                <div *ngIf="isEveningSlotSelected && selectedSportCategory['categoryName']!='Outdoor'" class="row"
                    style="width: 38em;">

                    <div class="col-md-12" style="margin-bottom: 1em;">
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekday price</div>
                            <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;">Weekend price</div>
                        </div>
                    </div>

                    <div class="col-md-12" *ngFor="let data of listofSlotForEvening;">
                        <div>
                            <input type="checkbox" [checked]="data['isSelected']"
                                (change)="changePricingSelectionForEvening(data['title'],$event)">
                            <label style=" width: 6em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                            <input *ngIf="checkForWeekday()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Evening',false)">
                            <input *ngIf="checkForWeekend()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Evening',true)">
                        </div>
                    </div>
                </div>
                <div *ngIf="isNightSlotSelected && selectedSportCategory['categoryName']=='Outdoor'" class="row"
                    style="width: 38em;">
                    <div class="col-md-12" style="margin-bottom: 1em;">
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekday price</div>
                            <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekend price</div>
                        </div>
                    </div>

                    <div class="col-md-12" *ngFor="let data of listOfPriceForNight;">
                        <div>
                            <input type="checkbox" [checked]="data['isSelected']"
                                (change)="changePricingSelectionForNight(data['title'],$event)">
                            <label style=" width: 3em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                            <input *ngIf="checkForWeekday()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                                style="width: 25%;margin-right: 1rem;" maxlength="5"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Night',false)">
                            <input *ngIf="checkForWeekend()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                                style="width: 25%;margin-right: 1rem;" maxlength="5"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Night',true)">
                        </div>
                    </div>
                </div>
                <div *ngIf="isNightSlotSelected && selectedSportCategory['categoryName']!='Outdoor'" class="row"
                    style="width: 38em;">
                    <div class="col-md-12" style="margin-bottom: 1em;">
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekday price</div>
                            <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;">Weekend price</div>
                        </div>
                    </div>
                    <div class="col-md-12" *ngFor="let data of listofSlotForNight;">
                        <div>
                            <input type="checkbox" [checked]="data['isSelected']"
                                (change)="changePricingSelectionForNight(data['title'],$event)">
                            <label style=" width: 6em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                            <input *ngIf="checkForWeekday()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                                style="width: 25%;margin-right: 1rem;" maxlength="5"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Night',false)">
                            <input *ngIf="checkForWeekend()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                                style="width: 25%;margin-right: 1rem;" maxlength="5"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Night',true)">
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="isMorningSlotSelected">
                    <div class=" col-md-12 slot-time" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                        Morning Slot Time :-
                    </div>
                        <div class="col-md-12" style="display: flex;">
                            <div class="col-md-6">
                                <div>
                                    <label  class="time" style=" width: 6em; margin-right: 1em;" for="">Start Time</label>
                                    <input type="number" placeholder="Start Time" class="input-style"
                                        [value]="morningSlotTime['startTime']" maxlength="5"
                                        (input)="addTimeForSlot($event.target,'Morning',true)">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div>
                                    <label class="time" style=" width: 6em; margin-right: 1em;" for="">End Time</label>
                                    <input type="number" placeholder="End Time" class="input-style"
                                        [value]="morningSlotTime['endTime']" maxlength="5"
                                        (input)="addTimeForSlot($event.target,'Morning',false)">
                                </div>
                            </div>
                        </div>
                </div>
                <div class="row" *ngIf="isAfternoonSlotSelected">
                    <div class=" col-md-12 slot-time" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                        Afternoon Slot Time :-
                    </div>
                        <div class="col-md-12" style="display: flex;">
                            <div class="col-md-6">
                                <div>
                                    <label  class="time" style=" width: 6em; margin-right: 1em;" for="">Start Time</label>
                                    <input type="number" placeholder="Start Time" class="input-style"
                                        [value]="afternoonSlotTime['startTime']" maxlength="5"
                                        (input)="addTimeForSlot($event.target,'Afternoon',true)">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div>
                                    <label class="time" style=" width: 6em; margin-right: 1em;" for="">End Time</label>
                                    <input type="number" placeholder="End Time" class="input-style"
                                        [value]="afternoonSlotTime['endTime']" maxlength="5"
                                        (input)="addTimeForSlot($event.target,'Afternoon',false)">
                                </div>
                            </div>
                        </div>
                </div>
                <div class="row" *ngIf="isEveningSlotSelected">
                    <div class=" col-md-12 slot-time" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                        Evening Slot Time :-
                    </div>
                        <div class="col-md-12" style="display: flex;">
                            <div class="col-md-6">
                                <div>
                                    <label class="time" style=" width: 6em; margin-right: 1em;" for="">Start Time</label>
                                    <input type="number" placeholder="Start Time" class="input-style"
                                        [value]="eveningSlotTime['startTime']" maxlength="5"
                                        (input)="addTimeForSlot($event.target,'Evening',true)">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div>
                                    <label class="time" style=" width: 6em; margin-right: 1em;" for="">End Time</label>
                                    <input type="number" placeholder="End Time" class="input-style"
                                        [value]="eveningSlotTime['endTime']" maxlength="5"
                                        (input)="addTimeForSlot($event.target,'Evening',false)">
                                </div>
                            </div>
                        </div>
                </div>
                <div class="row" *ngIf="isNightSlotSelected">
                    <div class=" col-md-12 slot-time" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                        Night Slot Time :-
                    </div>
                            <div class="col-md-12" style="display: flex;">
                                <div class="col-md-6">
                                    <div>
                                        <label class="time" style=" width: 6em; margin-right: 1em;" for="">Start Time</label>
                                        <input type="number" placeholder="Start Time" class="input-style"
                                            [value]="nightSlotTime['startTime']" maxlength="5"
                                            (input)="addTimeForSlot($event.target,'Night',true)">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div>
                                        <label class="time" style=" width: 6em; margin-right: 1em;" for="">End Time</label>
                                        <input type="number" placeholder="End Time" class="input-style"
                                            [value]="nightSlotTime['endTime']" maxlength="5"
                                            (input)="addTimeForSlot($event.target,'Night',false)">
                                    </div>
                                </div>
                            </div>
                </div>

                <div class="dashedLine"></div>

                <div class="row">
                    <div class="col-md-12 venue-timing" style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em; ">
                        Venue Facility:-
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label for="facility" class="label required">Facility</label>
                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Facility'"
                            [ngModelOptions]="{standalone: true}" [settings]="facilitiesDropdownSettings"
                            [data]="listOfFacilities" [(ngModel)]="selectedFacilities" (onSelect)="onItemSelect($event)"
                            (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="onSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                


            </form>
        </div>
        <div class="buttons">
            <button class="btn-1" mat-button (click)="cancelBtnHandler()">
                Cancel
              </button>
              <button class="btn" mat-button (click)="isAddEditAddressLoading?null:addTurfSlots()">
                <div *ngIf="!isAddEditAddressLoading">
                  {{'Save Details & Continue'}}
                </div>
                <div *ngIf="isAddEditAddressLoading">
                  <mat-progress-spinner mode="indeterminate" diameter="20" style="margin: 0 auto">
                  </mat-progress-spinner>
                </div>
              </button>
        </div>
    </div>
</div>