<div class="container_editUser" *ngIf="isLoading">

</div>
<div class="container_editUser" *ngIf="!isLoading">
    <div class="container_editUser_main">
        <div class="container_editUser_title">About Venue</div>
        <div>
            <form [formGroup]="addTurfForm">
                <div class="row">
                    <div class="col-md-6">
                        <label class="label mt-5">Add Venue Name</label>
                        <input type="text" class="input-style"  placeholder="Enter store name" formControlName="name"/>
                    </div>
                    <div class="col-md-6">
                        <label class="label mt-5">Add Contact Number</label>
                        <input type="text" class="input-style"  placeholder="Add Contact Number" formControlName="phone" maxlength="10" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="label mt-5">Select Business</label>

                        <select id="business" name="business" class="input-style" formControlName="business">
                            <option value="Select Business">Select Business</option>
                            <option *ngFor="let data of listOfBusiness" [value]="data['_id']">{{data['user']['firstName']+'
                                '+data['user']['lastName']}}</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <label class="label mt-5">Select Venue Type</label>
                        <select id="turfType" name="turfType" class="input-style" formControlName="turfType">
                            <option value="Select Turf Type">Select Turf Type</option>
                            <option value="BOOK">BOOK</option>
                            <option value="CALL">CALL</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="label mt-5">Advance Payment Amount</label>

                        <div style="display: flex">
                            <input type="text" class="input-style" placeholder="Advance Amount" id="advanceAmount" formControlName="advanceAmount" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="label mt-5">Commission Rate</label>
                        <input type="text" class="input-style"
                        placeholder="Commission" id="commission" formControlName="commission" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="label mt-5">Venue Status</label>

                        <select id="turfType" name="turfType" class="input-style" formControlName="status" id="turfType">
                            <option value="APPROVED">APPROVED</option>
                            <option value="REJECTED">REJECTED</option>
                            <option value="PENDING">PENDING</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <label class="label mt-5">Recommended Venue</label>
                        <select id="turfType" name="turfType" class="input-style" formControlName="recommendedVenue">
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label class="label mt-5">Add Descripton</label>
                        <textarea type="text" placeholder="Description" id="description" formControlName="description"></textarea>
                    </div>
                </div>
            </form>
        </div>
        <div class="buttons">
            <button *ngIf="isAdd" class="btn-1" mat-button (click)="cancelBtnHandler()">
                Cancel
            </button>
            <button *ngIf="!isAdd" class="btn-1" mat-button (click)="closeEditBtnHandler()">
                Cancel
            </button>
            <button class="btn" mat-button (click)="isAddEditAboutLoading?null:onSubmitAboutTurf()">
                <div *ngIf="!isAddEditAboutLoading">
                    {{isAdd?'Save Details & Continue':'Save Changes'}}
                </div>
                <div *ngIf="isAddEditAboutLoading">
                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin: 0 auto">
                    </mat-progress-spinner>
                </div>
            </button>
        </div>
    </div>
</div>

<!-- (click)="isAddEditAboutLoading?null:onSubmitAboutTurf()" -->