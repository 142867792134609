<div style="position: relative;">
    <div class="clear-button" (click)="close()">
        <mat-icon class="mat-clear-icon">clear</mat-icon>
    </div>
    <h2 mat-dialog-title style="font-weight: 500;margin-bottom: 5px;">Venue Detail</h2>
    <hr>
    <div class="col-md-12"
        *ngIf="this.data['turf']['business']['status']=='PENDING' || this.data['turf']['business']['status']=='REJECTED' ">
        <h3 *ngIf="this.data['turf']['business']['status']=='PENDING'" style=" margin-bottom: 2rem; text-align: center; font-weight:
            bold;">
            Business approval for this venue is pending. Please approve the business for activating the venue.
        </h3>
        <h3 *ngIf="this.data['turf']['business']['status']=='REJECTED'" style=" margin-bottom: 2rem; text-align: center;color:red; font-weight:
            bold;">
            Business for this venue is rejected.
        </h3>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12" style="display: flex;">

                    <div style="width: 7em;">
                        Venue ID:
                    </div>
                    <div style="width: 9em;font-size: 80%;">
                        {{data['turf']['_id'] }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display: flex;">

                    <div style="width: 7em;">
                        Owner Name:
                    </div>
                    <div style="width: 9em;font-size: 100%">
                        {{data['turf']['ownerName'] }}
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12" style="display: flex;">

                    <div style="width: 7em;">
                        Owner Phone:
                    </div>
                    <div style="width: 9em;font-size: 100%">
                        {{data['turf']['ownerPhone'] }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display: flex;">
                    <div style="width: 7em;">
                        Registration Date:
                    </div>
                    {{data['turf']['createdAt'] | date}}
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12" style="display: flex;">
                    <div style="font-weight: 600;">
                        Address:-
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display: flex;">
                    <div style="font-weight: 500;margin-bottom: 5px;">
                        {{data['turf']['address']['streetName']}} , {{data['turf']['address']['landmark']}} ,
                        {{data['turf']['address']['city']}} , {{data['turf']['address']['state']}} ,
                        {{data['turf']['address']['pincode']}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div>
        <h3 style="font-weight: 600;margin-bottom: 5px;">Available
            {{data['turf']['sportCategory']['categoryName']=='Outdoor'?"Sports":"Games"}}:</h3>
        <div class="row">
            <div class="col-md-12" style="display: flex;align-items: center;">

                <div *ngFor="let sport of data['turf']['sportsType']; let i=index"
                    style="display: flex;align-items: center;margin-right: 1em;">
                    {{sport['sport']}},
                </div>
            </div>
        </div>
    </div>

    <hr>
    <div>
        <h3 style="font-weight: 600;margin-bottom: 5px;">Open Days</h3>
        <div class="row">
            <div class="col-md-12" style="display: flex;align-items: center;">

                <div *ngFor="let day of data['turf']['days']; let i=index"
                    style="display: flex;align-items: center;margin-right: 1em">
                    {{day}} ,

                </div>
            </div>
        </div>
    </div>
    <hr>
    <div>
        <h3 style="font-weight: 600;margin-bottom: 5px;">Available Slots & Size</h3>
        <div class="row" *ngFor="let turfSize of data['turf']['slots']; ">
            <div class="col-md-12" style="display: flex;align-items: center;margin-bottom: 5px;font-weight: 500;">
                {{turfSize['session']}} Slot:
            </div>
            <div class="col-md-12" style="display: flex;align-items: center;margin-bottom: 5px;font-weight: 500;">
                Start Time: {{getTimePeriod(turfSize['startTime'])}}
            </div>
            <div class="col-md-12" style="display: flex;align-items: center;margin-bottom: 5px;font-weight: 500;">
                End Time: {{getTimePeriod(turfSize['endTime'])}}
            </div>

            <div class="col-md-12" style="display: flex;align-items: center;margin-bottom: 5px;font-weight: 500;">
                {{data['turf']['sportCategory']['categoryName']=='Outdoor'?"Size & Pricing":"Game & Pricing"}}:
            </div>
            <div class="row" style="margin-bottom: 1.5rem;">
                <div class="col-md-12" style="margin-bottom: 5px;margin-left: 1.7rem;"
                    *ngFor="let size of turfSize['priceAndQuantity']; let i=index">
                    {{i+1}}) {{size['title']}} {{size['label']=='' ||size['label']==null
                    ||size['label']==undefined?'':'('+size['label']+')'}} at {{'\u20b9'+ size['price']}} per
                    {{data['turf']['slotTimeDifference']}}
                    minutes
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div>
        <h3 style="font-weight: 600;margin-bottom: 5px;">Available Facilities</h3>
        <div class="row">
            <div class="col-md-12" style="display: flex;align-items: center;">
                <div *ngFor="let facility of data['turf']['facilities']; let i=index"
                    style="display: flex;align-items: center;margin-right: 1em">
                    {{facility}} ,
                </div>
            </div>
        </div>
    </div>
    <hr>

    <div *ngIf="data['turf']['sportCategory']['categoryName']=='Outdoor'">
        <div class="row">
            <h3 style="display: flex;align-items: center;margin-left: 1em;font-weight: 500;"> Is Turf Netting?
                {{data['turf']['isNet']?'Yes':'No'}}</h3>
        </div>
    </div>
    <hr *ngIf="data['turf']['sportCategory']['categoryName']=='Outdoor'">
    <div>
        <h3 style="font-weight: 600;margin-bottom: 5px;">Turf Images</h3>
        <div class="row">
            <div class="col-md-12" style="display: flex;align-items: center;">
                <div *ngFor="let item of _albums; let i=index"
                    style="display: flex;align-items: center;margin-right: 1em">
                    <img class="prod-img" [src]="item.thumb" (click)="openPopup(i)" />
                    <div class="prod-name" style="margin-left: 1em;">
                        {{item['docNumber']}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>

    <div
        *ngIf="this.data['turf']['business']['status']!='PENDING' && this.data['turf']['business']['status']!='REJECTED' ">
        <div style="align-items: baseline; margin-bottom: .5rem; margin-left: 1rem;"
            *ngIf="data['turf']['status']!='PENDING'">
            <div class="row" style="align-items: baseline;">
                <h3 style="font-weight: 600;margin-bottom: 5px; margin-right: .5rem;">Venue Type:</h3>
                <h4 style="margin-bottom: 5px;" *ngIf="data['turf']['turfType']!=null">{{data['turf']['turfType']}}</h4>
            </div>
            <div class="row" style="align-items: baseline;">

                <h3 style="font-weight: 600;margin-bottom: 5px; margin-right: .5rem;">Commission Rate:</h3>
                <h4 style="margin-bottom: 5px;" *ngIf="data['turf']['commission']!=null">{{data['turf']['commission']}}
                    %
                </h4>
            </div>
            <div class="row" style="align-items: baseline;">
                <h3 style="font-weight: 600;margin-bottom: 5px; margin-right: .5rem;">Advance Payment:</h3>
                <h4 style="margin-bottom: 5px;">&#8377;{{data['turf']['advanceAmount']}}
                </h4>
            </div>
        </div>
        <div class="row" style="align-items: baseline;" *ngIf="data['turf']['status']=='PENDING'">
            <div class="col-md-6" *ngIf="data['turf']['turfType']==null">
                <h3 style="font-weight: 600;margin-bottom: 5px; margin-right: .5rem;">Venue Type:</h3>
                <!-- <h4 style="margin-bottom: 5px;" *ngIf="data['turf']['turfType']!=null">{{data['turf']['turfType']}}</h4> -->
                <div>
                    <select id="turfType" name="turfType" (change)="selectTypeDropdown($event.target)"
                        style=" padding-left: 0rem;" class="input-style">
                        <option value="Select Type" selected disabled>Select Type</option>
                        <option value="BOOK">BOOK</option>
                        <option value="CALL">CALL</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6" *ngIf="data['turf']['commission']==null">
                <h3 style="font-weight: 600;margin-bottom: 5px; margin-right: .5rem;">Commission Rate:</h3>
                <div>
                    <input type="number" placeholder="Commission" id="commission" class="input-style"
                        (input)="setCommission($event.target)">
                </div>
            </div>
        </div>
        <div>
            <h3 style="font-weight: 600;margin-bottom: 5px;">Status</h3>
            <div class="row" *ngIf="data['turf']['status']!='PENDING'">
                <div class="col-md-12">

                    <div style="display: contents;" *ngIf="data['turf']['status']!='PENDING'"
                        [ngClass]="{'rejected-order':data['turf']['status']=='REJECTED','accepted-order':data['turf']['status']=='PENDING' }">
                        {{data['turf']['status']=='REJECTED'?'Rejected':(data['turf']['status']=='APPROVED'? 'Approved'
                        :'Pending')}}
                    </div>

                </div>
            </div>
        </div>

        <div class="row" *ngIf="data['turf']['status']=='PENDING'">
            <div class="col-md-12" style="display: flex;align-items: center;">
                <input type="radio" name="radio-btn" id="Approve" name="Approve" [checked]="isAcceptButtonSelected"
                    (change)="radioChangeHandler($event)">
                <label for="Approve" class="lbl-radio">Approve</label>
                <input type="radio" name="radio-btn" id="Reject" name="Reject" [checked]="isRejectButtonSelected"
                    (change)="radioChangeHandler($event)">
                <label for="Reject" class="lbl-radio">Reject</label>
            </div>
        </div>
        <div *ngIf="isAcceptButtonSelected">
            <div>
                <div style="width: 25em;font-size: medium;margin-top: 1em;" mat-dialog-content>Are you sure you want to
                    approve this Venue?</div>
            </div>
            <div mat-dialog-actions>
                <button class="canel-btn" mat-dialog-close (click)="close()">No</button>
                <button class="ok-btn" [disabled]="selectedTurfType==null || commission==null"
                    (click)=" selectedTurfType==null || commission==null? null:acceptTurf()">Yes</button>
            </div>
        </div>
        <div *ngIf="isRejectButtonSelected">
            <div>
                <label for="remark" class="label required">Remark</label>
                <input type="text" id="remark" class="input-style" placeholder="Enter remark" [(ngModel)]="remark"
                    [ngModelOptions]="{standalone: true}">
            </div>
            <div mat-dialog-actions>
                <button class="no-btn" mat-dialog-close (click)="close()">No</button>
                <button class="yes-btn" [disabled]="remark==null" (click)="remark==null? null:rejectTurf()">Yes</button>
            </div>
        </div>
    </div>
</div>