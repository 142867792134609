<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Sport
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addSportForm" [formGroup]="addSportForm">
        <div class="row">
            <div class="col-md-12">
                <label for="sport" class="label required">Sports Category:</label>
                <select name="type" id="sports" (change)="categoryChangeHandler($event.target)"
                    formControlName="sportCategory" class="input-style">
                    <option *ngFor="let category of listOfSports" [value]="category['_id']" class="levels">
                        {{category['categoryName']}}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="sportName" class="label required">Sport name</label>
                <input type="text" placeholder="Sport name" id="sportName" formControlName="sportName"
                    class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="Product Image" class="label required">Sport Image</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultItemImage!=null" src="{{defaultItemImage}}" class="proofImage">
                                <label *ngIf="defaultItemImage==null" class="imageLabel"> Upload
                                    <input (change)="displayPancard($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultItemImage!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayPancard($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultItemImage!=null  && defaultItemImage.includes('https')"
                                    class="imageLabelDownload" (click)="downloadImage(defaultItemImage)">
                                    <mat-icon class="matIcon">download</mat-icon>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="save">

            <input *ngIf="dataToEdit!=null" type="submit"
                [ngClass]="{'save-btn-disabled':addSportForm.invalid  ,'save-btn':addSportForm.valid }"
                value="{{'Save'}}" [disabled]="addSportForm.invalid " (click)="addEditItem()" />
            <input *ngIf="dataToEdit==null" type="submit"
                [ngClass]="{'save-btn-disabled':addSportForm.invalid || selectedItemImage==null  ,'save-btn':addSportForm.valid  && selectedItemImage!=null }"
                value="{{'Save'}}" [disabled]="addSportForm.invalid || selectedItemImage==null "
                (click)="addEditItem()" />

        </div>

    </form>
</div>