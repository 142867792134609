import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarModule } from 'ng-sidebar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxPaginationModule } from 'ngx-pagination';
import { ListTurfComponent } from './list-turf/list-turf.component';
import { TurfComponent } from './turf.component';
import { TurfRoutingModule } from './turf-routing.module';
import { SuspendTurfDialogComponent } from './suspend-turf-dialog/suspend-turf-dialog.component';
import { SortDirective } from './sort.directive';
import { LightboxModule } from 'ngx-lightbox';
import { ViewTurfComponent } from './view-turf/view-turf.component';
import { AddEditTurfSidebarComponent } from './add-edit-turf-sidebar/add-edit-turf-sidebar.component';
import { AddEditViewConvenienceFeeComponent } from './add-edit-view-convenience-fee/add-edit-view-convenience-fee.component';

@NgModule({
  declarations: [TurfComponent, ListTurfComponent, SuspendTurfDialogComponent,SortDirective,ViewTurfComponent, AddEditTurfSidebarComponent, AddEditViewConvenienceFeeComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    NgMultiSelectDropDownModule,
    SidebarModule.forRoot(),
    NgxPaginationModule,
    TurfRoutingModule,
    LightboxModule
  ],
})
export class TurfModule {}
