import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { SuspendBusinessDialogComponent } from '../../business/suspend-business-dialog/suspend-business-dialog.component';

@Component({
  selector: 'app-delete-rental-item',
  templateUrl: './delete-rental-item.component.html',
  styleUrls: ['./delete-rental-item.component.scss'],
})
export class DeleteRentalItemComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<DeleteRentalItemComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    // console.log(data);
    dialogRef.disableClose = true;

    // this.userDetail = JSON.parse(localStorage.getItem("userDetails"));
  }
  ngOnInit(): void {}
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }

  deleteItem() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Delete Item',
      value: this.data,
    });
  }
}
