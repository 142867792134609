<!-- Coupon -->
<div *ngIf="data['source']=='Active Coupon'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['coupon']['isActive']?'active': 'inactive'}} this coupon?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeCoupon()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Delete Coupon'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this coupon?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteCoupon()">Yes</button>
    </div>
</div>

<!-- Banner -->
<div *ngIf="data['source']=='Active Banner'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['banner']['isActive']?'active': 'inactive'}} this banner?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeBanner()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Delete Banner'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this banner?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteBanner()">Yes</button>
    </div>
</div>


<!-- Help Video -->
<div *ngIf="data['source']=='Active Help'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['help']['isActive']?'active': 'inactive'}} this help?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeHelp()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Delete Help'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this help?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteHelp()">Yes</button>
    </div>
</div>


<!-- Webinar -->
<div *ngIf="data['source']=='Active Webinar'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['webinar']['isActive']?'active': 'inactive'}} this webinar?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeWebinar()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Delete Webinar'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this webinar?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteWebinar()">Yes</button>
    </div>
</div>



<!-- Plan -->
<div *ngIf="data['source']=='Active Plan'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['plan']['isActive']?'active': 'inactive'}} this plan?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activePlan()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Delete Plan'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this plan?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deletePlan()">Yes</button>
    </div>
</div>

<!-- TradeAdvisory -->
<div *ngIf="data['source']=='Active TradeAdvisory'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['advisory']['isActive']?'active': 'inactive'}} this advisory?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeTradeAdvisory()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Delete TradeAdvisory'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this advisory?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteTradeAdvisory()">Yes</button>
    </div>
</div>

<!-- Ticker -->
<div *ngIf="data['source']=='Active Ticker'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['ticker']['isActive']?'active': 'inactive'}} this ticker?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeTicker()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Delete Ticker'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this ticker?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteTicker()">Yes</button>
    </div>
</div>


<!-- Employee -->
<div *ngIf="data['source']=='Active Employee'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['employee']['isActive']?'active': 'inactive'}} this employee?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeEmployee()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Delete Employee'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this employee?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteEmployee()">Yes</button>
    </div>
</div>


<!-- Short Video -->
<div *ngIf="data['source']=='Active Video'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        {{!data['video']['isActive']?'active': 'inactive'}} this video?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="activeVideo()">Yes</button>
    </div>
</div>

<div *ngIf="data['source']=='Delete Video'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this video?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteVideo()">Yes</button>
    </div>
</div>