import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListOfferComponent } from './list-offer/list-offer.component';
import { OfferManagementRoutingModule } from './offer-management-routing.module';
import { OfferManagementComponent } from './offer-management.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { SidebarModule } from 'ng-sidebar';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatTabsModule } from '@angular/material/tabs';
import { SortDirective } from './sort.directive';
import { AddEditOfferSidebarComponent } from './add-edit-offer-sidebar/add-edit-offer-sidebar.component';
import { DeleteOfferDialogComponent } from './delete-offer-dialog/delete-offer-dialog.component';
import { Ng2MultiSelectModule } from 'ng2-multiselect-dropdown';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [ListOfferComponent, OfferManagementComponent,SortDirective, AddEditOfferSidebarComponent, DeleteOfferDialogComponent],
  imports: [
    CommonModule,
    OfferManagementRoutingModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatRadioModule,
    MatSidenavModule,
    MatSliderModule,
    MatTabsModule,
    MatSlideToggleModule,
    SidebarModule.forRoot(),
    NgxPaginationModule,
    MatProgressSpinnerModule,
    NgMultiSelectDropDownModule,
    // Ng2MultiSelectModule
  ],
})
export class OfferManagementModule {}
