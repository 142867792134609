import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Lightbox } from 'ngx-lightbox';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
@Component({
  selector: 'app-view-venue-pricing',
  templateUrl: './view-venue-pricing.component.html',
  styleUrls: ['./view-venue-pricing.component.scss']
})
export class ViewVenuePricingComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<ViewVenuePricingComponent>,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }

}
