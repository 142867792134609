<div class="main-container">
    <div class="contents">
        <div class="row">
            <div class="col-md-6" style="align-self: center;margin-bottom: 1em;">
                <div class="w-60">
                    <h2 class="welcome-text">Welcome to Book Your Slot Admin</h2>
                </div>
                <div class="mini-welcome w-60">
                    {{'Log In'}} and start managing
                </div>
                <div>
                    <form name="loginForm" [formGroup]="loginForm">
                        <!-- <div class="w-60">
                            
                            <select class="input-field w-60" name="userRole" id="userRole" aria-placeholder="Select Role" (change)="onRoleSelectionChange($event)">
                                <option value="Select Role" selected disabled>Select Role</option>
                                <option value="Franchise">Franchise</option>
                                <option value="Vendor">Vendor</option>
                                <option value="Admin">Admin</option>
                            </select>
                        </div>
                        <div *ngIf="!isAdmin" class="w-60">
                            <input class="input-field w-60" placeholder="Enter Mobile Number" type="text" formControlName="mobileNo" id="mobileNo" maxlength="10" required>
                        </div> -->
                        <div class="w-60">
                            <input class="input-field w-60" placeholder="Enter Email" type="text" formControlName="email" id="email" required>
                        </div>
                        <div class="w-60">
                            <input class="input-field w-60" placeholder="Enter password" type="password" formControlName="password" id="password" required>
                        </div>
                    </form>
                </div>
                <div id="recaptcha-container" style="margin-top: 1em;display: flex;justify-content: center;width: 60%;">
                </div>

                <div class="w-60">
                    <button *ngIf="!isContinueClicked" [ngClass]="{ 'continue-btn-disabled': loginForm.invalid, 'continue-btn-enabled': loginForm.valid }" type="submit" (click)="checkForValidUser()" [disabled]="loginForm.invalid || this.selectedRole=='Select Role'">Continue</button>
                    <div class="continue-btn-enabled" *ngIf="isContinueClicked">
                        <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;height:20px;width:20px;color: white;">
                        </mat-progress-spinner>
                    </div>
                </div>
                <div class="w-60">
                    <div class="policies m-auto">By continuing you agree to the <strong style="color:black">Terms of
                            Services</strong> and
                        <strong style="color:black">Privacy
                            policy.</strong>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <img class="phone-img-size" src="../../../assets/img/login.jpg" alt="phoneimg" srcset="">
            </div>
        </div>
    </div>
</div>