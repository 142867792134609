<div style="position: relative;">
    <div class="clear-button" (click)="close()">
        <mat-icon class="mat-clear-icon">clear</mat-icon>
    </div>
    <h2 mat-dialog-title style="font-weight: 500;margin-bottom: 5px;">Venue Pricing</h2>
    <hr>

    <div>
        <div>
            <div class="row" *ngFor="let turfSize of data['turf']['slots']; ">
                <div class="col-md-12" style="display: flex;align-items: center;margin-bottom: 5px;font-weight: 500;">
                    {{turfSize['session']}} Slot:
                </div>
                <div class="col-md-12" style="display: flex;align-items: center;margin-bottom: 5px;font-weight: 500;">
                    {{data['turf']['sportCategory']['categoryName']=='Outdoor'?"Size & Pricing":"Game & Pricing"}}:
                </div>
                <div class="row" style="margin-bottom: 1.5rem;">
                    <div class="col-md-12" style="margin-bottom: 5px;margin-left: 1.7rem;"
                        *ngFor="let size of turfSize['priceAndQuantity']; let i=index">
                        {{i+1}}) {{size['title']}} {{size['label']=='' ||size['label']==null
                        ||size['label']==undefined?'':'('+size['label']+')'}} at {{'\u20b9'+ size['price']}} per
                        {{data['turf']['slotTimeDifference']}}
                        minutes
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>