<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Employee
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body" *ngIf="!isLoading">
    <form name="addEmployeeForm" [formGroup]="addEmployeeForm">
        <div class="row">
            <div class="col-md-12">
                <label for="employeeType" class="label required">Employee Type</label>
                <select name="employeeType" id="employeeType" formControlName="employeeType" class="input-style">
                    <option value="Select Type" selected disabled>Select Type</option>
                    <option value="Admin">Admin</option>
                    <option value="Employee">Employee</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="fullName" class="label required">Full Name</label>
                <input type="text" placeholder="Full Name" id="fullName" formControlName="fullName" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="email" class="label required">Email</label>
                <input type="email" placeholder="Email" id="email" formControlName="email" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="phone" class="label required">Phone Number</label>
                <input type="number" placeholder="Phone Number" id="phone" formControlName="phone" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="password" class="label required">Password</label>
                <input type="text" placeholder="Password" id="password" formControlName="password" class="input-style">
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12">
                <label for="franchise" class="label required" style="margin-bottom: 1em;">Select Access List</label>
                <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Access List'" [ngModelOptions]="{standalone: true}" [settings]="lngDropdownSettings" [data]="listOfRights" [(ngModel)]="selectedRights" >
                </ng-multiselect-dropdown>
            </div>
        </div>
        
        <div class="save">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addEmployeeForm.invalid || selectedRights.length==0,'save-btn':addEmployeeForm.valid && selectedRights.length!=0}"
                value="{{'Save'}}" [disabled]="addEmployeeForm.invalid || selectedRights.length==0 " (click)="editUser()" />
        </div>
    </form>
</div>