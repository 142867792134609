<div class="sidebar">
    <div class="display-flex align-items-center justify-content-center p-3">
        <img src="../../../assets/logo/logo.png" style="height: 2em;" alt="" srcset="">
    </div>
    <div class="components">
        <div *ngFor="let data of this.appService.sidebarOptions">


            <div *ngIf="data!=null && data!=undefined"
                [ngClass]="{'selected':data['isSelected'],'unselected':!data['isSelected']}">
                <div class="div-style" [routerLink]="data['route']"
                    (click)="this.appService.gotoComponent(data['title'],'Admin')">
                    <img style="height: 1.5em;" [src]="data['svgUrl']" class="img" alt=""
                        srcset=""><span>{{data['title']}}</span>
                </div>
            </div>
            <!-- <div *ngIf="data!=null && data!=undefined" [ngClass]="{'selected':data['isSelected'],'unselected':!data['isSelected']}" >
                <div class="div-style" [routerLink]="data['route']" (click)="navigateHandler(data)">
                    <img [src]="data['svgUrl']" class="img" alt="" srcset=""><span>{{data['title']}}</span>
                </div>
            </div> -->
            <!-- <div *ngIf="data['label']=='Logout'" style="position: absolute;bottom: 20px;" [ngClass]="{'selected':data['isSelected'],'unselected':!data['isSelected']}" >
                <div class="div-style" [routerLink]="data['route']" (click)="navigateHandler(data)">
                    <img [src]="data['img']" class="img" alt="" srcset=""><span>{{data['label']}}</span>
                </div>
            </div> -->
        </div>
    </div>

</div>