<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Business
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addBusinessForm" [formGroup]="addBusinessForm">
        <div class="row">
            <div class="col-md-6">
                <label for="firstName" class="label required">First name</label>
                <input type="text" placeholder="First name" id="firstName" formControlName="firstName"
                    class="input-style">
            </div>
            <div class="col-md-6">
                <label for="lastName" class="label required">Last name</label>
                <input type="text" placeholder="Last name" id="lastName" formControlName="lastName" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="phone" class="label required">Phone Number</label>
                <input type="text" placeholder="Phone Number" id="phone" formControlName="phone" class="input-style"
                    maxlength="10">
            </div>
            <div class="col-md-6">
                <label for="email" class="label required">Email</label>
                <input type="text" placeholder="Email" id="email" formControlName="email" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" style="
            margin-bottom: 0.6em;
            font-weight: 700;
        ">
                Pan Card Detail:-
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="pancardNumber" class="label required">Pancard Number</label>
                <input type="text" placeholder="Pancard Number" id="pancardNumber" formControlName="pancardNumber"
                    class="input-style" maxlength="10">
            </div>

        </div>
        <div class="row">
            <div class="col-md-12" style="
            margin-bottom: 0.6em;
            font-weight: 700;
        ">
                Bank Detail:-
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label for="accountHolderName" class="label required">Account Holder Name</label>
                <input type="text" placeholder="Account Holder Name" id="accountHolderName"
                    formControlName="accountHolderName" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="bankName" class="label required">Bank Name</label>
                <input type="text" placeholder="Bank Name" id="bankName" formControlName="bankName" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="accountNumber" class="label required">Account Number</label>
                <input type="text" placeholder="Account Number" id="accountNumber" formControlName="accountNumber"
                    class="input-style">
            </div>
            <div class="col-md-6">
                <label for="ifscCode" class="label required">IFSC Code</label>
                <input type="text" placeholder="IFSC Code" id="ifscCode" formControlName="ifscCode" class="input-style"
                    maxlength="11">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="Cancelled Cheque Leaf" class="label required">Cancelled Cheque Leaf</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultChequeLeafImage!=null &&defaultChequeLeafImage!=''"
                                    src="{{defaultChequeLeafImage}}" class="proofImage">
                                <label *ngIf="defaultChequeLeafImage==null ||defaultChequeLeafImage==''"
                                    class="imageLabel"> Upload
                                    <input (change)="displayChequeLeaf($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultChequeLeafImage!=null &&defaultChequeLeafImage!=''"
                                    class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayChequeLeaf($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultChequeLeafImage!=null && defaultChequeLeafImage.includes('https')"
                                    class="imageLabelDownload" (click)="downloadImage(defaultChequeLeafImage)">
                                    <mat-icon class="matIcon">download</mat-icon>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <label for="Pancard" class="label required">Pancard</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultPancardImage!=null &&defaultPancardImage!=''  "
                                    src="{{defaultPancardImage}}" class="proofImage">
                                <label *ngIf="defaultPancardImage==null ||defaultPancardImage==''" class="imageLabel">
                                    Upload
                                    <input (change)="displayPancard($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultPancardImage!=null && defaultPancardImage!=''"
                                    class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayPancard($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultPancardImage!=null  && defaultPancardImage.includes('https')"
                                    class="imageLabelDownload" (click)="downloadImage(defaultPancardImage)">
                                    <mat-icon class="matIcon">download</mat-icon>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="save">

            <input *ngIf="dataToEdit!=null" type="submit"
                [ngClass]="{'save-btn-disabled':addBusinessForm.invalid  ,'save-btn':addBusinessForm.valid }"
                value="{{'Save'}}" [disabled]="addBusinessForm.invalid " (click)="addBusiness()" />
            <input *ngIf="dataToEdit==null" type="submit"
                [ngClass]="{'save-btn-disabled':addBusinessForm ,'save-btn':addBusinessForm.valid  }" value="{{'Save'}}"
                [disabled]="addBusinessForm.invalid" (click)="addBusiness()" />
            <!-- || selectedPancardImage==null || selectedChequeLeafImage==null -->
            <!-- && selectedPancardImage!=null && selectedChequeLeafImage!=null -->
        </div>

    </form>
</div>