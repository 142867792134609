<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Rental Item
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addRentalForm" [formGroup]="addRentalForm">
        <div class="row">
            <div class="col-md-12">
                <label for="productName" class="label required">Product name</label>
                <input type="text" placeholder="Product name" id="productName" formControlName="productName"
                    class="input-style">
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="price" class="label required">Product Price</label>
                <input type="text" placeholder="Item Price" id="price" formControlName="price" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="duration" class="label required">Duration</label>
                <input type="text" placeholder="Enter Minutes" id="duration" formControlName="duration" class="input-style"
                    maxlength="10">
            </div>

        </div>

        <div class="row">
            <div class="col-md-12">
                <label for="sport" class="label required">Sports:</label>
                <select name="type"  id="cars" (change)="searchSportChangeHandler($event.target)"
                    formControlName="sport" class="input-style">
                    <option value="Select Sport" selected disabled>Select Sport</option>
                    <option *ngFor="let sport of listOfSports" [value]="sport['_id']" class="levels">
                        {{sport['sport']}}</option>
                </select>
            </div>
        </div>

        <div class="row" style="margin-bottom:1.2rem">
            <div class="col-md-12">
                <label for="days" class="label required">Venues</label>
                <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Venues'"
                    [ngModelOptions]="{standalone: true}" [settings]="venueDropdownSetting" [data]="listOfVenue"
                    [(ngModel)]="selectedVenues">
                </ng-multiselect-dropdown>
            </div>
        </div>

        <div class="row">

            <div class="col-md-6">
                <label for="Product Image" class="label required">Product Image</label>
                <div class="row">
                    <div class="col-md-12">
                        <div class="image">
                            <div class="proof">
                                <img *ngIf="defaultItemImage!=null" src="{{defaultItemImage}}" class="proofImage">
                                <label *ngIf="defaultItemImage==null" class="imageLabel"> Upload
                                    <input (change)="displayPancard($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultItemImage!=null" class="imageLabelEdit">
                                    <mat-icon class="matIcon">edit</mat-icon>
                                    <input (change)="displayPancard($event)" type="file" accept="image/*">
                                </label>
                                <label *ngIf="defaultItemImage!=null  && defaultItemImage.includes('https')"
                                    class="imageLabelDownload" (click)="downloadImage(defaultItemImage)">
                                    <mat-icon class="matIcon">download</mat-icon>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="save">

            <input *ngIf="dataToEdit!=null" type="submit"
                [ngClass]="{'save-btn-disabled':addRentalForm.invalid || selectedVenues.length==0  ,'save-btn':addRentalForm.valid && selectedVenues.length!=0}"
                value="{{'Save'}}" [disabled]="addRentalForm.invalid || selectedVenues.length==0 " (click)="addEditItem()" />
            <input *ngIf="dataToEdit==null" type="submit"
                [ngClass]="{'save-btn-disabled':addRentalForm.invalid || selectedVenues.length==0 || selectedItemImage==null  ,'save-btn':addRentalForm.valid && selectedVenues.length!=0 && selectedItemImage!=null }"
                value="{{'Save'}}" [disabled]="addRentalForm.invalid || selectedVenues.length==0 || selectedItemImage==null " (click)="addEditItem()" />

        </div>

    </form>
</div>