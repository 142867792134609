import { Component, OnInit } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ViewTurfComponent } from '../../turf/view-turf/view-turf.component';
import { SuspendTurfDialogComponent } from '../../turf/suspend-turf-dialog/suspend-turf-dialog.component';
import { AddEditViewConvenienceFeeComponent } from '../../turf/add-edit-view-convenience-fee/add-edit-view-convenience-fee.component';

export interface IBusiness {
  _id: string;
  id: string;
  name: string;
  phone: string;
  description: string;
  address: Object;
  fullAddress: string;
  createdAt: string;
  remark: string;
  option: string;

  user: Object;
  ownerName: string;
  ownerPhone: string;

  sportsType: Object;
  sportsName: string;
  sportCategory: any;
  sports: any;
  commission: number;
  advanceAmount: number;
  convenienceFeeForAdvancePay: number;
  convenienceFeeForFullPay: number;
  days: any;
  slots: any;
  slotTimeDifference: string;
  images: string[];
  facilities: Object[];
  rentedItem: Object[];
  turfType: string;
  business: Object;
  convenience: Object;

  block: boolean;
  isNet: boolean;
  commisionAccepted: boolean;
  enableWhatsApp: boolean;

  isRecommended: boolean;
  recommendedPosition: string;

  status: string;
  isActive: boolean;
  isDeleted: boolean;

  link: string;
  previewLink: string;
}

@Component({
  selector: 'app-list-venue-management',
  templateUrl: './list-venue-management.component.html',
  styleUrls: ['./list-venue-management.component.scss']
})
export class ListVenueManagementComponent implements OnInit {
  selectedBusiness: any;

  isAddEditCalled: boolean = false;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  isLoadingExtraData: boolean = false;

  selectedStatus: any = 'All';

  locationId: any;
  p: any = 1;
  count: any = 50;
  dataToEdit: any;
  userDetails: any;
  isLoading: boolean = true;
  listOfOriginalTurf: any = [];
  listOfTurf: Array<IBusiness> = [];
  listOfTurfToDisplay: Array<IBusiness> = [];
  listOfSports: any = [];

  listOfBusiness: any = [];
  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private clipboard: Clipboard,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
  }
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.locationId = params.id; // same as :username in route
    });
    this.fetchAllBusniess();
  }

  navigateToAddVenue() {
    this.router.navigateByUrl('/admin/venue-management/add-venue');
  }
  
  async fetchAllBusniess() {
    this.isLoading = true;
    this.listOfBusiness = [];
    try {
      let param = {};
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchApprovedBusiness}`;
      param = {};
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfBusiness = data['result'];
            console.log('business', this.listOfBusiness);

            this.fetchAllSportsCategory();
          } else {
            this.listOfBusiness = [];
            this.fetchAllSportsCategory();
          }
        });
    } catch (error) {
      this.listOfBusiness = [];
      this.fetchAllSportsCategory();
    }
  }
  async fetchAllSportsCategory() {
    this.isLoading = true;
    this.listOfSports = [];
    try {
      let param = {};
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchSport}`;
      param = {};
      await this.appService
        .fetchDetailViaGet(URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfSports = data['result'];
            console.log(this.listOfSports);
            this.fetchAllTurf();
          } else {
            this.listOfSports = [];
            this.fetchAllTurf();
          }
        });
    } catch (error) {
      this.listOfSports = [];
      this.fetchAllTurf();
    }
  }
  async fetchAllTurf() {
    this.isLoading = true;
    this.listOfTurf = [];
    let param = {};
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    let URL;
    URL = `${webApi.domain + webApi.endPoint.fetchAllTurf}`;
    param = {};
    await this.appService
      .fetchDetail(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfOriginalTurf = data['result'];
          data['result'].forEach((turf: any) => {
            let sportsName = '';
            turf['sportsType'].forEach((spt: any) => {
              sportsName = sportsName + spt['sport'] + ',';
            });
            if (turf['user'] != null) {
              this.listOfTurf.push({
                _id: turf['_id'],
                id: turf['_id'],
                name: turf['name'],
                phone: turf['phone'],
                address: turf['address'],
                createdAt: turf['createdAt'],
                fullAddress: turf['address'] ?
                `${turf['address']['streetName'] || ''}, ${turf['address']['landmark'] || ''}, ${turf['address']['city'] || ''}, ${turf['address']['pincode'] || ''}, ${turf['address']['state'] || ''}` : 'Address Not Available',
                description: turf['description'],
                user: turf['user'],
                ownerName:
                  turf['user']['firstName'] + ' ' + turf['user']['lastName'],
                ownerPhone: turf['user']['phone'],

                sportsType: turf['sportsType'],
                commission: turf['commission'],
                link: turf['link'] ?? '',
                previewLink: turf['previewLink'] ?? '',
                sports: turf['sportsType'],
                convenienceFeeForAdvancePay:
                  turf['convenienceFeeForAdvancePay'],
                convenienceFeeForFullPay: turf['convenienceFeeForFullPay'],
                advanceAmount: turf['advanceAmount'],
                turfType: turf['turfType'],
                option: turf['option'],
                sportsName: sportsName,

                days: turf['days'],
                slots: turf['slots'],
                slotTimeDifference: turf['slotTimeDifference'],
                images: turf['images'],
                facilities: turf['facilities'],
                rentedItem: turf['rentedItem'],
                sportCategory: turf['sportCategory'],
                business: turf['business'],
                block: turf['block'],
                isNet: turf['isNet'],
                commisionAccepted: turf['commisionAccepted'],
                isRecommended: turf['isRecommended'],
                recommendedPosition: turf['recommendedPosition'],

                status: turf['status'],
                isActive: turf['isActive'],
                enableWhatsApp: turf['enableWhatsApp'],
                isDeleted: turf['isDeleted'],
                remark: turf['remark'],
                convenience: turf['convenience'],
              });
            }
          });
          // this.listOfTurf = data['result'];
          console.log(this.listOfTurf);
          this.listOfTurfToDisplay = this.listOfTurf;
          if (
            this.appService.selectedBusiness != null &&
            this.listOfBusiness.length > 0
          ) {
            let tempSearchList: any = [];
            this.listOfTurfToDisplay = [];

            this.listOfTurf.forEach((turf: any) => {
              if (turf['business']['_id'] == this.appService.selectedBusiness) {
                this.selectedBusiness = turf['business']['_id'];
                tempSearchList.push(turf);
              }
            });
            this.listOfTurfToDisplay = tempSearchList;

            this.appService.selectedBusiness = null;
          }
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.listOfTurf = [];
          this.listOfTurfToDisplay = this.listOfTurf;
        }
      });
  }
  searchData(target: any) {
    // console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfTurfToDisplay = [];

      this.listOfTurf.forEach((turf: any, index: number) => {
        if (
          String(turf['name'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(turf['phone'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(turf['fullAddress'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(turf['ownerName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(turf['ownerPhone'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(turf['sportsName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(turf['status'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(turf);
        }
      });
      this.listOfTurfToDisplay = [];
      this.listOfTurfToDisplay = tempSearchList;
    } else {
      this.listOfTurfToDisplay = this.listOfTurf;
    }
  }
  onBusinessSelectionChange(event: any) {
    console.log(event.target.value);
    let tempSearchList: any = [];
    this.listOfTurfToDisplay = [];
    this.selectedBusiness = event.target.value;
    if (this.selectedBusiness == 'All') {
      this.listOfTurfToDisplay = this.listOfTurf;
    } else {
      this.listOfTurf.forEach((turf: any) => {
        if (turf['business']['_id'] == this.selectedBusiness) {
          tempSearchList.push(turf);
        }
      });
      this.listOfTurfToDisplay = tempSearchList;
    }
  }
  actionAcceptDelete(turf: any, action: any) { }
  addNewTurf() {
    this.toggleOpened();
  }
  viewTurfDetails(turf: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      turf: turf,
    };
    dialogConfig.width = `50vw`;
    dialogConfig.maxWidth = `70vw`;
    dialogConfig.maxHeight = `90vh`;
    let dialogRef = this.matDialog.open(ViewTurfComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Accept Turf') {
          const param = {
            turfId: res['value']['_id'],
            status: 'APPROVED',
            turfType: res['turfType'],
            commission: res['commission'],
            convenienceFeeForFullPay: res['convenienceFeeForFullPay'],
            convenienceFeeForAdvancePay: res['convenienceFeeForAdvancePay'],
            remark: 'Approved by admin successfully',
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.verifyAndUnVerifyTurf
            }`;
          await this.appService
            .updateDataViaPut(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfTurf.forEach((turf: any, index: number) => {
                  if (turf['_id'] == res['value']['_id']) {
                    this.listOfTurf[index]['status'] = data['result']['status'];
                    this.listOfTurf[index]['remark'] = data['result']['remark'];
                    this.listOfTurf[index]['turfType'] =
                      data['result']['turfType'];
                    this.listOfTurf[index]['commission'] =
                      data['result']['commission'];
                    this.listOfTurf[index]['convenienceFeeForFullPay'] =
                      data['result']['convenienceFeeForFullPay'];
                    this.listOfTurf[index]['convenienceFeeForAdvancePay'] =
                      data['result']['convenienceFeeForAdvancePay'];
                  }
                });
                this.listOfTurfToDisplay = [...this.listOfTurf];

                this.showSuccessToaster(
                  'Turf Accepted Successfully',
                  'Success'
                );
              } else {
                this.showErrorToaster(
                  'Error Occurred while accepting Turf. Try again',
                  'Error'
                );
              }
            });
        } else if (res['action'] == 'Reject Turf') {
          const param = {
            turfId: res['value']['_id'],
            status: 'REJECTED',
            remark: res['moreVal'],
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.verifyAndUnVerifyTurf
            }`;
          await this.appService
            .updateDataViaPut(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfTurf.forEach((turf: any, index: number) => {
                  if (turf['_id'] == res['value']['_id']) {
                    this.listOfTurf[index]['status'] = data['result']['status'];
                    this.listOfTurf[index]['remark'] = data['result']['remark'];
                  }
                });
                this.listOfTurfToDisplay = this.listOfTurf;

                this.showSuccessToaster(
                  'Turf rejected Successfully',
                  'Success'
                );
              } else {
                this.showErrorToaster(
                  'Error Occurred while rejecting Turf. Try again',
                  'Error'
                );
              }
            });
        }
      }
    });
  }
  // editTurf(soc: any) {
  //   this.listOfOriginalTurf.forEach((data: any) => {
  //     if (data['_id'] == soc['_id']) {
  //       this.dataToEdit = data;
  //     }
  //   });
  //   this.toggleOpened();
  // }

  editTurf(info: any) {
    this.router.navigate(['/admin/venue-management/edit-venue', { id: info._id }]);
  }
  deleteTurf(trf: any) {
    let turf;
    this.listOfOriginalTurf.forEach((data: any) => {
      if (data['_id'] == trf['_id']) {
        turf = data;
      }
    });
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { turf: turf, source: 'Delete' };

    let dialogRef = this.matDialog.open(
      SuspendTurfDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Delete Turf') {
          const param = {
            turfId: res['value']['_id'],
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.deleteTurf}`;
          await this.appService
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfTurf.forEach((business: any, index: number) => {
                  if (business['_id'] == res['value']['_id']) {
                    this.listOfTurf.splice(index, 1);
                  }
                });
                this.listOfTurfToDisplay = this.listOfTurf;

                this.showSuccessToaster(
                  'Turf Suspended Successfully',
                  'Success'
                );
              } else {
                this.showErrorToaster(
                  'Error Occurred while suspending Turf. Try again',
                  'Error'
                );
              }
            });
        } else {
          this.toggleClose(null);
        }
      }
    });
  }
  suspendTurf(trf: any, actionToUpdate: boolean) {
    let turf;
    this.listOfTurf.forEach((data: any) => {
      if (data['_id'] == trf['_id']) {
        turf = data;
      }
    });
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { turf: trf, source: 'Suspend' };

    let dialogRef = this.matDialog.open(
      SuspendTurfDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Suspend Turf') {
          const param = {
            turfId: res['value']['_id'],
            action: actionToUpdate,
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.suspendTurf}`;
          await this.appService
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfTurf.forEach((business: any, index: number) => {
                  if (business['_id'] == res['value']['_id']) {
                    this.listOfTurf[index].isActive = actionToUpdate;
                  }
                });
                this.listOfTurfToDisplay = this.listOfTurf;
                if (!actionToUpdate) {
                  this.showSuccessToaster(
                    'Turf Inactivated Successfully',
                    'Success'
                  );
                } else {
                  this.showSuccessToaster(
                    'Turf Activated Successfully',
                    'Success'
                  );
                }
              } else {
                this.showErrorToaster(
                  'Error Occurred while suspending Turf. Try again',
                  'Error'
                );
              }
            });
        } else {
          this.toggleClose(null);
        }
      }
    });
  }

  async enableWhatsApp(turfId: any, actionToUpdate: boolean) {
    const param = {
      turfId: turfId,
      enable: actionToUpdate,
    };
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.enableWhatsApp}`;
    await this.appService
      .addData(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfTurf.forEach((turf: any, index: number) => {
            if (turf['_id'] == turfId) {
              this.listOfTurf[index].enableWhatsApp = actionToUpdate;
            }
          });
          this.listOfTurfToDisplay = this.listOfTurf;
          if (!actionToUpdate) {
            this.showSuccessToaster(
              'Whatsapp disabled Successfully',
              'Success'
            );
          } else {
            this.showSuccessToaster(
              'Whatsapp enabled Successfully',
              'Success'
            );
          }
        } else {
          this.showErrorToaster(
            'Error Occurred while enabling Whatsapp. Try again',
            'Error'
          );
        }
      });
  }
  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showErrorToaster(
            'Error while editing Turf details.Kindly try again',
            'Error'
          );
        } else {
          this.showErrorToaster(
            'Error while adding Turf details.Kindly try again',
            'Error'
          );
        }

        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
      if (this.dataToEdit != null) {
        let isExist = false;
        this.listOfOriginalTurf.forEach((business: any, index: number) => {
          if (business['_id'] == event['_id']) {
            this.listOfOriginalTurf[index] = event;
          }
        });
        this.listOfTurf.forEach((business: any, index: number) => {
          if (business['_id'] == event['_id']) {
            this.listOfOriginalTurf[index] = event;
            let sportsName = '';
            event['sportsType'].forEach((spt: any) => {
              sportsName = sportsName + spt['sport'] + ',';
            });
            this.listOfTurf[index] = {
              _id: event['_id'],
              id: event['_id'],
              name: event['name'],
              phone: event['phone'],
              createdAt: event['createdAt'],
              commission: event['commission'],
              convenienceFeeForAdvancePay: event['convenienceFeeForAdvancePay'],
              convenienceFeeForFullPay: event['convenienceFeeForFullPay'],
              advanceAmount: event['advanceAmount'],
              sports: event['sportsType'],

              address: event['address'],
              fullAddress:
                event['address']['streetName'] +
                ' , ' +
                event['address']['landmark'] +
                ' , ' +
                event['address']['city'] +
                ' , ' +
                event['address']['pincode'] +
                ' , ' +
                event['address']['state'],
              description: event['description'],
              user: event['user'],
              ownerName:
                event['user']['firstName'] + ' ' + event['user']['lastName'],
              ownerPhone: event['user']['phone'],

              sportsType: event['sportsType'],
              sportsName: sportsName,
              turfType: event['turfType'],
              link: event['link'] ?? '',
              previewLink: event['previewLink'] ?? '',
              option: event['option'],
              days: event['days'],
              slots: event['slots'],
              sportCategory: event['sportCategory'],
              slotTimeDifference: event['slotTimeDifference'],
              images: event['images'],
              facilities: event['facilities'],
              rentedItem: event['rentedItem'],
              remark: event['remark'],
              convenience: event['convenience'],

              business: event['business'],
              block: event['block'],
              isNet: event['isNet'],
              commisionAccepted: event['commisionAccepted'],

              status: event['status'],
              isActive: event['isActive'],
              enableWhatsApp: event['enableWhatsApp'],
              isDeleted: event['isDeleted'],
              isRecommended: event['isRecommended'],
              recommendedPosition: event['recommendedPosition'],
            };
            isExist = true;
          }
        });
        if (!isExist) {
          this.listOfTurf.push(event);
        }
        this.showSuccessToaster('Business Edited Successfully', 'Success');
      } else {
        let isExist = false;
        this.listOfOriginalTurf.forEach((business: any, index: number) => {
          if (business['_id'] == event['_id']) {
            this.listOfOriginalTurf[index] = event;
          }
        });
        this.listOfTurf.forEach((business: any, index: number) => {
          if (business['_id'] == event['_id']) {
            // this.listOfTurf[index] = event;
            let sportsName = '';
            event['sportsType'].forEach((spt: any) => {
              sportsName = sportsName + spt['sport'] + ',';
            });
            this.listOfTurf[index] = {
              _id: event['_id'],
              id: event['_id'],
              name: event['name'],
              remark: event['remark'],
              createdAt: event['createdAt'],
              phone: event['phone'],
              address: event['address'],
              fullAddress:
                event['address']['streetName'] +
                ' , ' +
                event['address']['landmark'] +
                ' , ' +
                event['address']['city'] +
                ' , ' +
                event['address']['pincode'] +
                ' , ' +
                event['address']['state'],
              description: event['description'],
              sports: event['sports'],
              user: event['user'],
              ownerName:
                event['user']['firstName'] + ' ' + event['user']['lastName'],
              ownerPhone: event['user']['phone'],
              commission: event['commission'],
              convenienceFeeForAdvancePay: event['convenienceFeeForAdvancePay'],
              convenienceFeeForFullPay: event['convenienceFeeForFullPay'],
              advanceAmount: event['advanceAmount'],
              convenience: event['convenience'],
              option: event['option'],
              link: event['link'] ?? '',
              previewLink: event['previewLink'] ?? '',
              sportsType: event['sportsType'],
              sportsName: sportsName,
              turfType: event['turfType'],
              days: event['days'],
              slots: event['slots'],
              sportCategory: event['sportCategory'],
              slotTimeDifference: event['slotTimeDifference'],
              images: event['images'],
              facilities: event['facilities'],
              rentedItem: event['rentedItem'],

              business: event['business'],
              block: event['block'],
              isNet: event['isNet'],

              commisionAccepted: event['commisionAccepted'],

              status: event['status'],
              isActive: event['isActive'],
              enableWhatsApp: event['enableWhatsApp'],
              isDeleted: event['isDeleted'],
              isRecommended: event['isRecommended'],
              recommendedPosition: event['recommendedPosition'],
            };
            isExist = true;
          }
        });
        if (!isExist) {
          this.listOfOriginalTurf.push(event);
          let sportsName = '';
          event['sportsType'].forEach((spt: any) => {
            sportsName = sportsName + spt['sport'] + ',';
          });
          this.listOfTurf.push({
            _id: event['_id'],
            id: event['_id'],
            name: event['name'],
            phone: event['phone'],
            createdAt: event['createdAt'],
            remark: event['remark'],
            address: event['address'],
            fullAddress:
              event['address']['streetName'] +
              ' , ' +
              event['address']['landmark'] +
              ' , ' +
              event['address']['city'] +
              ' , ' +
              event['address']['pincode'] +
              ' , ' +
              event['address']['state'],
            description: event['description'],
            user: event['user'],
            ownerName:
              event['user']['firstName'] + ' ' + event['user']['lastName'],
            ownerPhone: event['user']['phone'],
            commission: event['commission'],
            convenienceFeeForAdvancePay: event['convenienceFeeForAdvancePay'],
            convenienceFeeForFullPay: event['convenienceFeeForFullPay'],
            advanceAmount: event['advanceAmount'],
            sportsType: event['sportsType'],
            sportsName: sportsName,
            link: event['link'] ?? '',
            previewLink: event['previewLink'] ?? '',
            turfType: event['turfType'],
            days: event['days'],
            slots: event['slots'],
            sportCategory: event['sportCategory'],
            slotTimeDifference: event['slotTimeDifference'],
            sports: event['sportsType'],
            images: event['images'],
            facilities: event['facilities'],
            rentedItem: event['rentedItem'],
            option: event['option'],

            business: event['business'],
            block: event['block'],
            isNet: event['isNet'],
            convenience: event['convenience'],

            commisionAccepted: event['commisionAccepted'],

            status: event['status'],
            enableWhatsApp: event['enableWhatsApp'],
            isActive: event['isActive'],
            isDeleted: event['isDeleted'],
            isRecommended: event['isRecommended'],
            recommendedPosition: event['recommendedPosition'],
          });
        }
        this.showSuccessToaster('Society Added Successfully', 'Success');
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  onStatusSelectionChange(event: any) {
    let tempSearchList: any = [];
    this.listOfTurfToDisplay = [];
    this.selectedStatus = event.target.value;
    if (this.selectedStatus == 'All') {
      this.listOfTurfToDisplay = this.listOfTurf;
    } else {
      this.listOfTurf.forEach((turf: any) => {
        if (turf['status'] == this.selectedStatus) {
          tempSearchList.push(turf);
        }
      });
      this.listOfTurfToDisplay = tempSearchList;
    }
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  viewConvenience(turf: any, view: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      turf: turf,
      view: view,
    };
    dialogConfig.width = `35vw`;
    dialogConfig.maxWidth = `50vw`;
    dialogConfig.height = `70vh`;
    dialogConfig.maxHeight = `90vh`;
    let dialogRef = this.matDialog.open(AddEditViewConvenienceFeeComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        let index = this.listOfTurfToDisplay.findIndex((data: any) => data['_id'] == res['_id']);

        if (index != -1) {
          this.listOfTurfToDisplay[index]['convenience'] = res['convenience']
        }
      }
    });
  }

  async createLink(venue: any) {
    try {
      if (venue.link == '') {
        let URL = `${webApi.domain + webApi.endPoint.createDynamicLinksFoVenue}`;
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        await this.appService
          .postMethodWithToken({ venueId: venue._id }, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.clipboard.copy(data['result']['shortLink']);
              this.showSuccessToaster('Link Copied', 'Success');
            } else {
              this.showErrorToaster('Error occur while creating link', 'Error');
            }
            this.isLoading = false;
          });
      } else {
        this.clipboard.copy(venue.link);
        this.showSuccessToaster('Link Copied', 'Success')
      }

    } catch (error) {
      this.isLoading = false;
      this.showErrorToaster('Something went wrong', 'Error');
    }
  }

}
