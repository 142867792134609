<div class="container_addressDetail" *ngIf="isLoading">
    <div class="container_addressDetail_main">
        <div class="container_addressDetail_title">Venue Images</div>
        <div>
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <label class="label mt-5">Photos</label>
                        <div>
                            <label class="label">3 Photos are mandotory*</label>
                        </div>
                        <div class="row">

                            <div style="margin-left: 2rem;">
                                <label for="turfImage1" class="label" style="margin-top: 1em;">Venue Image 1</label>
                                <div class="row">
                                    <div>
                                        <div class="image">
                                            <div class="proof">
                                                <img *ngIf="defaultTurfImage[0]!=null" src="{{defaultTurfImage[0]}}" class="proofImage">
                                                <label *ngIf="defaultTurfImage[0]==null" class="imageLabel">
                                                    <img
                                                        src="../../../../../assets/svgIcons/upload-img.svg"
                                                        alt=""
                                                        srcset=""
                                                    />
                                                    <input (change)="displayTurf($event,0)" type="file" accept="image/*">
                                                </label>
                                                <div *ngIf="defaultTurfImage[0]==null" style="color: #5e5e5e; font-size: 1rem; font-weight: 400; padding: 2rem 2rem 1rem 2rem;">
                                                    <span style="color: #798dff">Browse files</span>,
                                                    Supports jpeg and jpg format
                                                  </div>
                                                  <div *ngIf="defaultTurfImage[0]==null" style="color: #acacb4; font-size: 1rem; font-weight: 400; padding: 0rem 2rem 1rem 2rem;">
                                                    Less than 2 Mb. 280 x 203 px
                                                  </div>
                                                <label *ngIf="defaultTurfImage[0]!=null" class="imageLabelEdit">
                                                    <mat-icon class="matIcon edit">edit</mat-icon>
                                                    <input (change)="displayTurf($event,0)" type="file" accept="image/*">
                                                </label>
                                                <!-- <label *ngIf="defaultTurfImage[0]!=null  && defaultTurfImage[0].includes('https')"
                                                    class="imageLabelDownload" (click)="downloadImage(defaultTurfImage[0])">
                                                    <mat-icon class="matIcon">download</mat-icon>
                                                </label> -->
                                                <label *ngIf="defaultTurfImage[0]!=null  && defaultTurfImage[0]!=''"
                                                    class="imageLabelDownload" (click)="deleteImage(0)">
                                                    <mat-icon class="matIcon delete">delete</mat-icon>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-left: 2rem;">
                                <label for="turfImage2" class="label" style="margin-top: 1em;">Venue Image 2</label>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="image">
                                            <div class="proof">
                                                <img *ngIf="defaultTurfImage[1]!=null" src="{{defaultTurfImage[1]}}" class="proofImage">
                                                <label *ngIf="defaultTurfImage[1]==null" class="imageLabel">
                                                    <img
                                                        src="../../../../../assets/svgIcons/upload-img.svg"
                                                        alt=""
                                                        srcset=""
                                                    />
                                                    <input (change)="displayTurf($event,1)" type="file" accept="image/*">
                                                </label>
                                                <div *ngIf="defaultTurfImage[1]==null"  style="color: #5e5e5e; font-size: 1rem; font-weight: 400; padding: 2rem 2rem 1rem 2rem;">
                                                    <span style="color: #798dff">Browse files</span>,
                                                    Supports jpeg and jpg format
                                                  </div>
                                                  <div *ngIf="defaultTurfImage[1]==null"  style="color: #acacb4; font-size: 1rem; font-weight: 400; padding: 0rem 2rem 1rem 2rem;">
                                                    Less than 2 Mb. 280 x 203 px
                                                  </div>
                                                <label *ngIf="defaultTurfImage[1]!=null" class="imageLabelEdit">
                                                    <mat-icon class="matIcon edit">edit</mat-icon>
                                                    <input (change)="displayTurf($event,1)" type="file" accept="image/*">
                                                </label>
                                                <!-- <label *ngIf="defaultTurfImage[1]!=null  && defaultTurfImage[1].includes('https')"
                                                    class="imageLabelDownload" (click)="downloadImage(defaultTurfImage[1])">
                                                    <mat-icon class="matIcon">download</mat-icon>
                                                </label> -->
                                                <label *ngIf="defaultTurfImage[1]!=null &&   defaultTurfImage[1]!=''"
                                                    class="imageLabelDownload" (click)="deleteImage(1)">
                                                    <mat-icon class="matIcon delete">delete</mat-icon>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style="margin-left: 2rem;">
                                <label for="turfImage3" class="label" style="margin-top: 1em;">Venue Image 3</label>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="image">
                                            <div class="proof">
                                                <img *ngIf="defaultTurfImage[2]!=null" src="{{defaultTurfImage[2]}}" class="proofImage">
                                                <!-- <label *ngIf="defaultTurfImage[2]==null" class="imageLabel"> Upload
                                                    <input (change)="displayTurf($event,2)" type="file" accept="image/*">
                                                </label> -->
                                                <label *ngIf="defaultTurfImage[2]==null" class="imageLabel">
                                                    <img
                                                        src="../../../../../assets/svgIcons/upload-img.svg"
                                                        alt=""
                                                        srcset=""
                                                    />
                                                    <input (change)="displayTurf($event,2)" type="file" accept="image/*">
                                                </label>
                                                <div *ngIf="defaultTurfImage[2]==null" style="color: #5e5e5e; font-size: 1rem; font-weight: 400; padding: 2rem 2rem 1rem 2rem;">
                                                    <span style="color: #798dff">Browse files</span>,
                                                    Supports jpeg and jpg format
                                                  </div>
                                                  <div *ngIf="defaultTurfImage[2]!=null" style="color: #acacb4; font-size: 1rem; font-weight: 400; padding: 0rem 2rem 1rem 2rem;">
                                                    Less than 2 Mb. 280 x 203 px
                                                  </div>
                                                <label *ngIf="defaultTurfImage[2]!=null" class="imageLabelEdit">
                                                    <mat-icon class="matIcon edit">edit</mat-icon>
                                                    <input (change)="displayTurf($event,2)" type="file" accept="image/*">
                                                </label>
                                                <!-- <label *ngIf="defaultTurfImage[2]!=null  && defaultTurfImage[2].includes('https')"
                                                    class="imageLabelDownload" (click)="downloadImage(defaultTurfImage[2])">
                                                    <mat-icon class="matIcon">download</mat-icon>
                                                </label> -->
                                                <label *ngIf="defaultTurfImage[2]!=null   && defaultTurfImage[2]!=''"
                                                    class="imageLabelDownload" (click)="deleteImage(2)">
                                                    <mat-icon class="matIcon delete">delete</mat-icon>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="buttons">
            <button class="btn-1" mat-button (click)="cancelBtnHandler()">
                Cancel
              </button>
              <button class="btn" mat-button (click)="isAddEditAddressLoading?null:addTurfImages()">
                <div *ngIf="!isAddEditAddressLoading">
                    {{'Save Details & Continue'}}
                </div>
                <div *ngIf="isAddEditAddressLoading">
                  <mat-progress-spinner mode="indeterminate" diameter="20" style="margin: 0 auto">
                  </mat-progress-spinner>
                </div>
              </button>
        </div>
    </div>
</div>