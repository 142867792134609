import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Lightbox } from 'ngx-lightbox';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-view-booking',
  templateUrl: './view-booking.component.html',
  styleUrls: ['./view-booking.component.scss'],
})
export class ViewBookingComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  isAcceptButtonSelected: boolean = false;
  isRejectButtonSelected: boolean = false;
  remark: any;

  constructor(
    public dialogRef: MatDialogRef<ViewBookingComponent>,
    private _lightbox: Lightbox,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    console.log(this.data);
    dialogRef.disableClose = true;
  }
  // openPopup(index: number): void {
  //   // open lightbox
  //   this._lightbox.open(this._albums, index);
  // }

  // closePopup(): void {
  //   // close lightbox programmatically
  //   this._lightbox.close();
  // }
  ngOnInit(): void {}
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  radioChangeHandler(event: any) {
    let radioSelectedValue = event.target.name;
    if (radioSelectedValue == 'Approve') {
      this.onAcceptClick();
    } else {
      this.onRejectClick();
    }
  }
  onAcceptClick() {
    this.isAcceptButtonSelected = true;
    this.isRejectButtonSelected = false;
  }
  onRejectClick() {
    this.isRejectButtonSelected = true;
    this.isAcceptButtonSelected = false;
  }
  getTimeFormat(time: any) {
    if (time >= 12) {
      return `${parseFloat(time).toFixed(2)} pm`;
    } else {
      return `${parseFloat(time).toFixed(2)} am`;
    }
  }
  acceptBooking() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Accept Booking',
      value: this.data['booking'],
    });
  }
  rejectBooking() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Reject Booking',
      value: this.data['booking'],
      moreVal: this.remark,
    });
  }
}
