import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { webApi } from 'src/app/api';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-about-venue',
  templateUrl: './about-venue.component.html',
  styleUrls: ['./about-venue.component.scss'],
})
export class AboutVenueComponent implements OnInit {
  @Input() dataToEdit: any;
  @Input() listOfBusiness: any;
  @Input() isAdd: boolean = false;
  @Output() backToPreviousStepper: EventEmitter<any> = new EventEmitter();
  @Output() closeEdit: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = true;
  userDetails: any;
  turfId: any;
  turfInfo: any;
  formdata = new FormData();
  isAddEditAboutLoading: boolean = false;

  myHeaders = new Headers();

  turfNameControl = new FormControl('', [Validators.required]);
  turfContactControl = new FormControl('', [Validators.required]);
  descriptionControl = new FormControl('', [Validators.required]);
  businessControl = new FormControl('Select Business', [Validators.required]);
  turfTypeControl = new FormControl('BOOK', [Validators.required]);
  recommendedVenueControl = new FormControl(false);
  commissionControl = new FormControl('', [Validators.required]);
  advanceAmountControl = new FormControl('', [Validators.required]);
  statusControl = new FormControl('Select Status', [Validators.required]);

  addTurfForm: FormGroup = new FormGroup({
    turfName: this.turfNameControl,
    recommendedVenue: this.recommendedVenueControl,
    turfContact: this.turfContactControl,
    description: this.descriptionControl,
    business: this.businessControl,
    turfType: this.turfTypeControl,
    status: this.statusControl,
    advanceAmount: this.advanceAmountControl,
    commission: this.commissionControl,
  });

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private appService: AppService,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
  }

  ngOnInit(): void {
    // console.log('this.listOfBusiness', this.listOfBusiness);
    this.turfId = this._activatedRoute.snapshot.paramMap.get('id');

    this.addTurfForm = this._formBuilder.group({
      name: this.turfNameControl,
      phone: this.turfContactControl,
      recommendedVenue: this.recommendedVenueControl,
      description: this.descriptionControl,
      business: this.businessControl,
      turfType: this.turfTypeControl,
      commission: this.commissionControl,
      status: this.statusControl,
      advanceAmount: this.advanceAmountControl,
    });
    if (this.isAdd) {
      this.isLoading = false;
    }
    if(this.turfId){
      this.fetchTurfByIdV2();
    }
  }

  fetchTurfByIdV2() {
    this.isLoading = true;
    try {
      let URL = '';
      URL = `${webApi.domain + webApi.endPoint.fetchTurfByIdV2 + `/${this.turfId}`}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      this.appService.getMethodWithToken(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.turfInfo = data.result;
          console.log('turfInfo', this.turfInfo);
          this.addTurfForm
            .get('name')
            ?.setValue(this.turfInfo['name']);
          this.addTurfForm.get('phone')?.setValue(this.turfInfo['phone']);
          this.addTurfForm.get('isRecommended')?.setValue(this.turfInfo['isRecommended']);
          this.addTurfForm.get('description')?.setValue(this.turfInfo['description']);
          this.addTurfForm.get('business')?.setValue(this.turfInfo['business']['_id']);
          this.addTurfForm.get('turfType')?.setValue(this.turfInfo['turfType']);
          this.addTurfForm.get('commission')?.setValue(this.turfInfo['commission']);
          this.addTurfForm.get('status')?.setValue(this.turfInfo['status']);
          this.addTurfForm.get('advanceAmount')?.setValue(this.turfInfo['advanceAmount']);
        }
        this.isLoading = false;
      });
    } catch (err) {
      this.isLoading = false;
      console.log(err);
    }
  }

  recommendedVenueChange(event: any) {
    console.log(event.target.checked);
    if (event.target.checked) {
      this.addTurfForm.addControl(
        'recommendedPosition',
        new FormControl('', [Validators.required])
      );
    } else {
      this.addTurfForm.removeControl('recommendedPosition');
    }
  }

  onSubmitAboutTurf() {
      this.isLoading = true;

      if (this.dataToEdit != null) {
        this.formdata.set('turfId', this.dataToEdit['_id']);
      } else {
        this.formdata.set('turfId', '0');
      }
      this.formdata.set('businessId', this.addTurfForm.get('business')?.value);
      this.formdata.set(
        'isRecommended',
        this.addTurfForm.get('recommendedVenue')?.value
      );
      this.formdata.set(
        'recommendedPosition',
        this.addTurfForm.get('recommendedVenue')?.value
          ? this.addTurfForm.get('recommendedPosition')?.value
          : 'NA'
      );

      this.formdata.set('turfName', this.addTurfForm.get('turfName')?.value);
      this.formdata.set('turfType', this.addTurfForm.get('turfType')?.value);
      this.formdata.set('status', this.addTurfForm.get('status')?.value);
      this.formdata.set(
        'turfContact',
        this.addTurfForm.get('turfContact')?.value
      );
      this.formdata.set('landmark', this.addTurfForm.get('landmark')?.value);
      this.formdata.set(
        'commission',
        this.addTurfForm.get('commission')?.value
      );
      this.formdata.set(
        'advanceAmount',
        this.addTurfForm.get('advanceAmount')?.value
      );
      this.formdata.set(
        'description',
        this.addTurfForm.get('description')?.value
      );

      this.myHeaders.set(
        'Authorization',
        `Bearer ${this.userDetails['accessToken']}`
      );
      var requestOptions: RequestInit = {
        method: 'POST',
        headers: this.myHeaders,
        body: this.formdata,
        redirect: 'follow',
      };
      var URL;
      if (this.dataToEdit != null) {
        URL = `${webApi.domain + webApi.endPoint.addEditTurfAboutInfoV2}`;
      } else {
        URL = `${webApi.domain + webApi.endPoint.addEditTurfAboutInfoV2}`;
      }
      try {
        fetch(URL, requestOptions)
          .then((result) => {
            if (result) {
              let data = result.json();
              data.then((res) => {
                if (res['success']) {
                  this.isAddEditAboutLoading = false;
                  if (this.turfId != null) {
                    this.showSuccessToaster(
                      'Turf edited successfully',
                      'Success'
                    );
                  } else {
                    this.showSuccessToaster(
                      'Turf added successfully',
                      'Success'
                    );
                  }
                  this.closeEdit.emit({ turfId: res['result']['_id'] });
                } else {
                  this.isAddEditAboutLoading = false;
                  if (this.turfId != null) {
                    this.showWarningToaster(res['message'], 'Warning');
                  } else {
                    this.showWarningToaster(res['message'], 'Warning');
                  }
                }
              });
            }
          })
      } catch (error) {
        this.isAddEditAboutLoading = false;
        if (this.turfId != null) {
          this.showWarningToaster('Unable to edit Turf', 'Warning');
        } else {
          this.showWarningToaster('Unable to add Turf', 'Warning');
        }
      }
  }

  cancelBtnHandler() {
    this.backToPreviousStepper.emit();
  }

  closeEditBtnHandler() {
    this.closeEdit.emit();
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
}
