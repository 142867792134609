import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-offer-sidebar',
  templateUrl: './add-edit-offer-sidebar.component.html',
  styleUrls: ['./add-edit-offer-sidebar.component.scss'],
})
export class AddEditOfferSidebarComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();
  @Input() category: any;
  @Input() product: any;
  isLoading: boolean = false;
  myHeaders = new Headers();
  formdata = new FormData();
  isForNewUser: boolean = false;

  maxStartDate: any;
  minEndDate: any;

  listOfVenue: any = [];

  selectedBooking = '';

  createdSourceControl = new FormControl('Select Offer Source', [Validators.required]);
  offerCodeControl = new FormControl('', [Validators.required]);
  offerStartDateControl = new FormControl('', [Validators.required]);
  offerEndDateControl = new FormControl('', [Validators.required]);
  descriptionControl = new FormControl('', [Validators.required]);
  discountPercentageControl = new FormControl('', [Validators.required]);
  benefitAmountControl = new FormControl('', [Validators.required]);
  turfControl = new FormControl('Select Venue', [Validators.required]);
  bookingTypeControl = new FormControl('Select Booking Type', [Validators.required]);
  benefitTypeControl = new FormControl('Select Benefit Type', [Validators.required]);

  addEditOfferForm: FormGroup = new FormGroup({
    createdSource: this.createdSourceControl,
    offerCode: this.offerCodeControl,
    startDate: this.offerStartDateControl,
    endDate: this.offerEndDateControl,
    discountPercentage: this.discountPercentageControl,
    description: this.descriptionControl,
    // turf: this.turfControl,
    // bookingType: this.bookingTypeControl,
  });
  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
  }
  dropdownCategorySettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 6,
    allowSearchFilter: true,
  };
  ngOnInit(): void {
    this.addEditOfferForm = this.formBuilder.group({
      createdSource: this.createdSourceControl,
      offerCode: this.offerCodeControl,
      startDate: this.offerStartDateControl,
      endDate: this.offerEndDateControl,
      benefitType: this.benefitTypeControl,
      description: this.descriptionControl,
      discountPercentage: this.discountPercentageControl,
      // bookingType: this.bookingTypeControl,
    });
    this.assignEditValue();
    this.fetchVenueAdmin();
  }

  assignEditValue() {
    if (this.dataToEdit != null) {
      this.addEditOfferForm.get('createdSource')?.setValue(this.dataToEdit['createdSource']);
      this.addEditOfferForm.get('offerCode')?.setValue(this.dataToEdit['code']);
      if (this.dataToEdit['createdSource'] != 'CRM') {
        this.addEditOfferForm.addControl('turf', this.turfControl);
        this.addEditOfferForm.addControl('bookingType', this.bookingTypeControl);
        if (this.dataToEdit['turf'] != null) {
          this.addEditOfferForm
            .get('turf')
            ?.setValue(this.dataToEdit['turf']['_id']);
        }
        this.addEditOfferForm.get('bookingType')?.setValue(this.dataToEdit['bookingType']);
        this.selectedBooking = this.dataToEdit['bookingType'];
      } else {
        this.addEditOfferForm.addControl('benefitType', this.benefitTypeControl);
        this.addEditOfferForm.get('benefitType')?.setValue(this.dataToEdit['benefitType']);

        this.addEditOfferForm.addControl('benefitAmount', this.benefitAmountControl);
        this.addEditOfferForm.get('benefitAmount')?.setValue(this.dataToEdit['benefitAmount']);
      }

      if (this.dataToEdit['benefitType'] == 'Percentage') {
        this.addEditOfferForm.addControl('discountPercentage', this.discountPercentageControl);
        this.addEditOfferForm.get('discountPercentage')?.setValue(this.dataToEdit['discountPercentage']);
      }

      this.addEditOfferForm
        .get('description')
        ?.setValue(this.dataToEdit['description']);
      this.addEditOfferForm.get('type')?.setValue(this.dataToEdit['type']);
      this.addEditOfferForm
        .get('startDate')
        ?.setValue(
          new Date(this.dataToEdit['startDate']).toISOString().slice(0, 10)
        );
      if (this.dataToEdit['endDate'] != undefined) {
        this.addEditOfferForm
          .get('endDate')
          ?.setValue(
            new Date(this.dataToEdit['endDate']).toISOString().slice(0, 10)
          );
      }
      if (this.dataToEdit['isForNewUser']) {
        this.isForNewUser = true;
      } else {
        this.isForNewUser = false;
      }
    }
  }

  async fetchVenueAdmin() {
    this.isLoading = true;
    this.listOfVenue = [];
    try {
      let URL;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchTurfAdmin}?admin=1`;
      await this.as.fetchDetailViaGet(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfVenue = data['result'];
          if (this.dataToEdit != null) {
            if (this.dataToEdit['createdSource'] != 'CRM') {
              if (this.dataToEdit['turf'] != null) {
                this.addEditOfferForm
                  .get('turf')
                  ?.setValue(this.dataToEdit['turf']['_id']);
              }
            }

          }
        }
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
    }
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  isForNewUserChange(event: any) {
    this.isForNewUser = event.target.checked;
  }


  selectSource(event: any) {
    if (event.value == 'CRM') {
      this.addEditOfferForm.addControl('benefitAmount', this.benefitAmountControl);
      this.addEditOfferForm.removeControl('turf');
      this.addEditOfferForm.removeControl('bookingType');
    } else {
      this.addEditOfferForm.addControl('discountPercentage', this.discountPercentageControl);
      this.addEditOfferForm.addControl('turf', this.turfControl);
      this.addEditOfferForm.addControl('bookingType', this.bookingTypeControl);

    }
  }
  selectBenefitType(event: any) {
    if (event.value == 'Percentage') {
      this.addEditOfferForm.addControl('discountPercentage', this.discountPercentageControl);
    } else {
      this.addEditOfferForm.removeControl('discountPercentage');

    }
  }

  selectType(event: any) {
    this.selectedBooking = event.value;
  }

  startDateChange(event: any) {
    console.log(event.target.value);
    this.minEndDate = event.target.value;
  }
  endDateChange(event: any) {
    console.log(event.target.value);
    this.maxStartDate = event.target.value;
  }

  addEditOffer() {

    var myHeaders = new Headers();
    var formdata = new FormData();

    if (this.dataToEdit != null) {
      formdata.set('couponId', this.dataToEdit['_id']);
    } else {
      formdata.set('couponId', '');
    }

    formdata.set('createdSource', this.addEditOfferForm.get('createdSource')?.value);
    formdata.set('benefitType', this.addEditOfferForm.get('benefitType')?.value);
    if (this.addEditOfferForm.get('benefitType')?.value == 'Percentage' || this.addEditOfferForm.get('createdSource')?.value != 'CRM') {
      formdata.set('discountPercentage', this.addEditOfferForm.get('discountPercentage')?.value);
    } else {
      formdata.set('discountPercentage', '0');
    }
    formdata.set('benefitAmount', (this.addEditOfferForm.get('benefitAmount')?.value != null && this.addEditOfferForm.get('benefitAmount')?.value != undefined && this.addEditOfferForm.get('benefitAmount')?.value != '' ? this.addEditOfferForm.get('benefitAmount')?.value : '0'));

    formdata.set('code', this.addEditOfferForm.get('offerCode')?.value);
    formdata.set('description', this.addEditOfferForm.get('description')?.value);
    formdata.set('startDate', this.addEditOfferForm.get('startDate')?.value);
    formdata.set('endDate', this.addEditOfferForm.get('endDate')?.value);
    if (this.addEditOfferForm.get('createdSource')?.value != 'CRM') {
      formdata.set('benefitType', 'Percentage');
      formdata.set('bookingType', this.addEditOfferForm.get('bookingType')?.value);
      formdata.set('turf', this.addEditOfferForm.get('turf')?.value);
    } else {
      formdata.set('createdSource', this.addEditOfferForm.get('createdSource')?.value);
    }

    if (this.dataToEdit != null && this.dataToEdit['business'] != null && this.dataToEdit['business'] != undefined) {
      formdata.set('business', this.dataToEdit['business']['_id']);
    }

    formdata.set('isForNewUser', String(this.isForNewUser));

    myHeaders.set(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };
    let URL: any;
    URL = `${webApi.domain + webApi.endPoint.createAndUpdateCoupon}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addEditOfferForm.reset();
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                if (res['message'] == 'DuplicateCode') {
                  this.showErrorToaster('Coupon Code Already Exists.', 'Duplicate Code')
                } else {
                  this.cancel('Error');
                }
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
