import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'bys-admin-web';
  userDetail: any;
  constructor(private router: Router, public as: AppService) {
    this.userDetail = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);

    this.as.setSideBarState(window.location.href.split('/'));

  }

  ngOnInit(): void { }
}
