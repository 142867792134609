import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
})
export class LeftSidebarComponent implements OnInit {
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  constructor(
    public routes: ActivatedRoute,
    private router: Router,
    public appService: AppService
  ) {
    if (this.appService.userDetail != null) {
      // this.as.setSideBarState(window.location.href.split('/'));
    } else {
      // this.router.navigate(['/layout/course']);
    }
  }
  ngOnInit(): void { }
  navigateHandler(data:any) {
    this.closeSideForm.emit('');
    // this.appService.navigateHandler(data);
    this.appService.gotoComponent(data['title'],'Admin');
  }
 
  closeSideBarManually() {
    this.closeSideForm.emit('');
  }
  

  gotoLink(link: any) {
    window.open(link, '_blank');
  }
}
