import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { IBooking } from '../../booking/list-booking/list-booking.component';
import { ViewBookingComponent } from '../../booking/view-booking/view-booking.component';

@Component({
  selector: 'app-list-withdrawal',
  templateUrl: './list-withdrawal.component.html',
  styleUrls: ['./list-withdrawal.component.scss']
})
export class ListWithdrawalComponent implements OnInit {

  selectedStatus: any = 'All';
  Page = 1;
  count = 50
  isLoadingExtraData: boolean = false;
  limit = 250;
  skip = 0;

  dateResult: any;
  startDate: any;
  endDate: any;
  dataToEdit: any;
  userDetails: any;
  isLoading: boolean = true;
  searchStr: string = '';
  listOfWithdrawal: any = [];
  listOfWithdrawalToDisplay: any = [];
  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
  }
  ngOnInit(): void {
    this.fetchAllWithdrawal();
  }

  async fetchAllWithdrawal() {
    this.isLoading = true;
    try {
      this.listOfWithdrawal = [];
      this.listOfWithdrawalToDisplay = [];
      let param = {
        limit: this.limit,
        skip: this.skip,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchAllWithdrawal}`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfWithdrawal = data['result'];
            this.listOfWithdrawalToDisplay = this.listOfWithdrawal;
          }
          this.isLoading = false;
        });
    } catch (error) {
      this.listOfWithdrawal = [];
      this.listOfWithdrawalToDisplay = [];
      this.isLoading = false;
    }
  }
  async fetchExtraListData(load: boolean) {
    this.isLoadingExtraData = load;
    try {
      let param = {
        limit: this.limit,
        skip: this.skip,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchAllWithdrawal}`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            data['result'].forEach((booking: any) => {
              this.listOfWithdrawal.push(booking);
            });
            this.listOfWithdrawalToDisplay = this.listOfWithdrawal;
            this.isLoadingExtraData = false;
          } else {
          }
        });
    } catch (err) {
      this.isLoadingExtraData = false;
      console.log(err);
    }
  }
  pageChanged(event: any) {
    this.Page = event;
    console.log(event);

    if (
      this.Page * this.count >=
      this.listOfWithdrawal.length - this.count * 3
    ) {
      this.skip = this.skip + this.limit;
      if (!this.isLoadingExtraData) {
        if (this.searchStr == '') {
          this.fetchExtraListData(false)
        } else {
          this.fetchExtraSearchListData(false);
        }
      };
    } else if (this.Page * this.count >= this.listOfWithdrawal.length) {
      this.skip = this.skip + this.limit;
      if (this.searchStr == '') {
        this.fetchExtraListData(true)
      } else {
        this.fetchExtraSearchListData(true);
      }
    }
  }

  fetchDataBasedOndate(event: any) {
    // console.log(event.value, this.dateResultForCash);
    let dateVal = new Date(this.dateResult);
    if (this.dateResult == '') {
      this.startDate = null;
      this.endDate = null;
    } else {
      this.startDate = new Date(dateVal.setHours(0, 0, 0, 0));
      this.endDate = new Date(dateVal.setHours(23, 59, 59, 59));
    }
    this.fetchAllWithdrawal();
  }

  async searchWithdrawalData() {
    this.isLoading = true;
    if (this.searchStr == '') {
      this.limit = 250;
      this.skip = 0;
      this.fetchAllWithdrawal();
    } else {
      this.limit = 250;
      this.skip = 0;
      try {
        this.listOfWithdrawal = [];
        let param = {
          searchString: this.searchStr, limit: this.limit,
          skip: this.skip,
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        let URL;
        URL = `${webApi.domain + webApi.endPoint.searchWithdrawal}`;
        await this.appService
          .fetchDetail(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfWithdrawal = data['result'];
              this.listOfWithdrawalToDisplay = this.listOfWithdrawal;

            } else {
            }
            this.isLoading = false;
          });
      } catch (error) {
        this.isLoading = false;
      }
    }
  }
  async fetchExtraSearchListData(load: boolean) {
    this.isLoadingExtraData = load;
    try {
      let param = {
        searchString: this.searchStr, limit: this.limit,
        skip: this.skip,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.searchWithdrawal}`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            data['result'].forEach((booking: any) => {
              this.listOfWithdrawal.push(booking);
            });
            this.listOfWithdrawalToDisplay = this.listOfWithdrawal;
            this.isLoadingExtraData = false;
          } else {
          }
        });
    } catch (err) {
      this.isLoadingExtraData = false;
      console.log(err);
    }
  }
  searchData(target: any) {
    let serachQuery = target.value;
    if (serachQuery == '') {
      this.limit = 250;
      this.skip = 0;
      this.fetchAllWithdrawal();
    }
  }

  getTimeFormat(time: any) {
    if (time >= 12) {
      return `${parseFloat(time).toFixed(2)} pm`;
    } else {
      return `${parseFloat(time).toFixed(2)} am`;
    }
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
