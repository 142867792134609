import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Lightbox } from 'ngx-lightbox';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-view-turf',
  templateUrl: './view-turf.component.html',
  styleUrls: ['./view-turf.component.scss'],
})
export class ViewTurfComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  isAcceptButtonSelected: boolean = false;
  isRejectButtonSelected: boolean = false;
  commission: any;
  selectedTurfType: any;
  remark: any;
  _albums: any = [];

  constructor(
    public dialogRef: MatDialogRef<ViewTurfComponent>,
    private _lightbox: Lightbox,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    console.log(this.data);
    dialogRef.disableClose = true;
    for (let i = 0; i < this.data['turf']['images'].length; i++) {
      const src = this.data['turf']['images'][i]['url'];
      const caption = `${this.data['turf']['name']} Image`;
      const thumb = this.data['turf']['images'][i]['url'];
      const album = {
        src: src,
        caption: caption,
        thumb: thumb,
      };

      this._albums.push(album);
    }
    this.selectedTurfType=this.data['turf']['turfType']
    this.commission=this.data['turf']['commission']
  }
  openPopup(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  selectTypeDropdown(event: any) {
    this.selectedTurfType = event.value;
  }

  setCommission(event: any) {
    this.commission = event.value;
  }

  getTimePeriod(text: number) {
    if (text >= 12) {
      return `${text.toFixed(2)} pm`;
    } else {
      return `${text.toFixed(2)} am`;
    }
  }

  closePopup(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
  ngOnInit(): void {}
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  radioChangeHandler(event: any) {
    let radioSelectedValue = event.target.name;
    if (radioSelectedValue == 'Approve') {
      this.onAcceptClick();
    } else {
      this.onRejectClick();
    }
  }
  onAcceptClick() {
    this.isAcceptButtonSelected = true;
    this.isRejectButtonSelected = false;
  }
  onRejectClick() {
    this.isRejectButtonSelected = true;
    this.isAcceptButtonSelected = false;
  }
  onFranchiseSelectionChange(event: any) {
    console.log();
  }
  acceptTurf() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Accept Turf',
      turfType: this.selectedTurfType,
      commission: parseInt(this.commission),
      value: this.data['turf'],
    });
  }
  rejectTurf() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Reject Turf',
      value: this.data['turf'],
      moreVal: this.remark,
    });
  }
}
