import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ViewBookingComponent } from '../view-booking/view-booking.component';
export interface IBooking {
  _id: string;
  id: string;
  name: string;
  phone: string;

  bookingMode: string;
  bookingStatus: string;
  paymentStatus: string;
  totalAmount: string;
  tax: string;
  amountPaid: string;
  transactionId: string;

  sizeOrSport: string;
  ogBookingDate: Date;
  bookingDate: Date;
  createdAt: Date;
  sport: Object;
  sportName: string;
  label: string;
  rentedItem: Object[];

  user: Object;
  business: Object;
  turfId: Object;
  turfName: Object;
  sportCategory: any;

  bookingSlotStartTime: string;
  bookingSlotEndTime: string;
}
@Component({
  selector: 'app-list-booking',
  templateUrl: './list-booking.component.html',
  styleUrls: ['./list-booking.component.scss'],
})
export class ListBookingComponent implements OnInit {
  selectedStatus: any = 'All';
  Page = 1;
  count = 50
  isLoadingExtraData: boolean = false;
  limit = 250;
  skip = 0;

  dateResult: any;
  startDate: any;
  endDate: any;
  dataToEdit: any;
  userDetails: any;
  isLoading: boolean = true;
  isSendMessageLoading: boolean = false;
  searchStr: string = '';
  listOfOriginalTurf: any = [];
  listOfBooking: Array<IBooking> = [];
  listOfBookingToDisplay: Array<IBooking> = [];
  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
  }
  ngOnInit(): void {
    this.fetchAllBooking(0);
  }
  getTimeFormat(time: any) {
    if (time >= 12) {
      return `${parseFloat(time).toFixed(2)} pm`;
    } else {
      return `${parseFloat(time).toFixed(2)} am`;
    }
  }
  onPaymentStatusSelectionChange(event: any) {
    console.log(event.target.value);
    let tempSearchList: any = [];
    // this.listOfBookingToDisplay = [];
    this.selectedStatus = event.target.value;
    // if (this.selectedStatus == 'All') {
    //   this.listOfBookingToDisplay = this.listOfBooking;
    // } else {
    //   this.listOfBooking.forEach((booking: any) => {
    //     if (booking['paymentStatus'] == this.selectedStatus) {
    //       tempSearchList.push(booking);
    //     }
    //   });
    //   this.listOfBookingToDisplay = tempSearchList;
    // }
    this.limit = 250;
    this.skip = 0;
    this.fetchAllBooking(0);
  }
  fetchDataBasedOndate(event: any) {
    // console.log(event.value, this.dateResultForCash);
    let dateVal = new Date(this.dateResult);
    if (this.dateResult == '') {
      this.fetchAllBooking(0);
    } else {
      this.startDate = new Date(dateVal.setHours(0, 0, 0, 0));
      this.endDate = new Date(dateVal.setHours(23, 59, 59, 59));
      this.fetchParticularDayAllBooking(this.startDate, this.endDate, 0);
    }
  }
  async fetchAllBooking(businessId: any) {
    this.isLoading = true;
    this.dateResult = null;

    this.listOfBooking = [];
    let param = {
      businessId: businessId, limit: this.limit,
      skip: this.skip,
      paymentStatus: this.selectedStatus,
    };
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    let URL;
    URL = `${webApi.domain + webApi.endPoint.fetchAllBooking}`;
    await this.appService
      .fetchDetail(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfOriginalTurf = data['result'];
          data['result'].forEach((booking: any) => {
            this.listOfBooking.push({
              _id: booking['_id'],
              id: booking['_id'],
              name:
                booking['user']['firstName'] +
                ' ' +
                booking['user']['lastName'],
              phone: booking['user']['phone'],
              bookingMode: booking['bookingMode'],
              bookingStatus: booking['bookingStatus'],
              paymentStatus: booking['paymentStatus'],
              totalAmount: booking['totalAmount'],
              tax: booking['tax'],
              label: booking['label'],
              amountPaid: booking['amountPaid'],
              transactionId: booking['transactionId'],
              sizeOrSport: booking['sizeOrSport'],
              bookingDate: booking['bookingDate'],
              createdAt: booking['createdAt'],
              ogBookingDate: booking['ogBookingDate'],
              sport: booking['sport'],
              sportName: booking['sport']['sport'],
              sportCategory: booking['sportCategory'],
              rentedItem: booking['rentedItem'],
              user: booking['user'],
              business: booking['business'],
              turfId: booking['turfId'],
              turfName: booking['turfId']['name'],
              bookingSlotStartTime: booking['bookingSlotStartTime'],
              bookingSlotEndTime: booking['bookingSlotEndTime'],
            });
          });
          this.listOfBookingToDisplay = this.listOfBooking;
          this.isLoading = false;
        } else {
        }
      });
  }
  async fetchParticularDayAllBooking(start: any, end: any, businessId: any) {
    this.isLoading = true;
    this.listOfBooking = [];
    let param = { businessId: businessId, startDate: start, endDate: end };
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    let URL;
    URL = `${webApi.domain + webApi.endPoint.fetchParticularDayAllBooking}`;
    await this.appService
      .fetchDetail(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfOriginalTurf = data['result'];
          data['result'].forEach((booking: any) => {
            this.listOfBooking.push({
              _id: booking['_id'],
              id: booking['_id'],
              name:
                booking['user']['firstName'] +
                ' ' +
                booking['user']['lastName'],
              phone: booking['user']['phone'],
              bookingMode: booking['bookingMode'].toLocaleUpperCase(),
              bookingStatus: booking['bookingStatus'],
              paymentStatus: booking['paymentStatus'],
              totalAmount: booking['totalAmount'],
              tax: booking['tax'],
              amountPaid: booking['amountPaid'],
              transactionId: booking['transactionId'],
              sizeOrSport: booking['sizeOrSport'],
              bookingDate: booking['bookingDate'],
              createdAt: booking['createdAt'],
              ogBookingDate: booking['ogBookingDate'],
              label: booking['label'],
              sport: booking['sport'],
              sportName: booking['sport']['sport'],
              rentedItem: booking['rentedItem'],
              user: booking['user'],
              sportCategory: booking['sportCategory'],
              business: booking['business'],
              turfId: booking['turfId'],
              turfName: booking['turfId']['name'],
              bookingSlotStartTime: booking['bookingSlotStartTime'],
              bookingSlotEndTime: booking['bookingSlotEndTime'],
            });
          });
          this.listOfBookingToDisplay = this.listOfBooking;
          this.isLoading = false;
        } else {
        }
      });
  }

  async fetchExtraListData(load: boolean) {
    this.isLoadingExtraData = load;
    try {
      let param = {
        businessId: 0, limit: this.limit,
        skip: this.skip,
        paymentStatus: this.selectedStatus,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchAllBooking}`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            data['result'].forEach((booking: any) => {
              this.listOfOriginalTurf.push(booking);
              this.listOfBooking.push({
                _id: booking['_id'],
                id: booking['_id'],
                name:
                  booking['user']['firstName'] +
                  ' ' +
                  booking['user']['lastName'],
                phone: booking['user']['phone'],
                bookingMode: booking['bookingMode'],
                bookingStatus: booking['bookingStatus'],
                paymentStatus: booking['paymentStatus'],
                totalAmount: booking['totalAmount'],
                tax: booking['tax'],
                label: booking['label'],
                amountPaid: booking['amountPaid'],
                transactionId: booking['transactionId'],
                sizeOrSport: booking['sizeOrSport'],
                bookingDate: booking['bookingDate'],
                createdAt: booking['createdAt'],
                ogBookingDate: booking['ogBookingDate'],
                sport: booking['sport'],
                sportName: booking['sport']['sport'],
                sportCategory: booking['sportCategory'],
                rentedItem: booking['rentedItem'],
                user: booking['user'],
                business: booking['business'],
                turfId: booking['turfId'],
                turfName: booking['turfId']['name'],
                bookingSlotStartTime: booking['bookingSlotStartTime'],
                bookingSlotEndTime: booking['bookingSlotEndTime'],
              });
            });
            this.listOfBookingToDisplay = this.listOfBooking;
            this.isLoadingExtraData = false;
          } else {
          }
        });
    } catch (err) {
      this.isLoadingExtraData = false;
      console.log(err);
    }
  }
  pageChanged(event: any) {
    this.Page = event;
    console.log(event);

    if (
      this.Page * this.count >=
      this.listOfBooking.length - this.count * 3
    ) {
      this.skip = this.skip + this.limit;
      if (!this.isLoadingExtraData) {
        if (this.searchStr == '') {
          this.fetchExtraListData(false)
        } else {
          this.fetchExtraSearchListData(false);
        }
      };
    } else if (this.Page * this.count >= this.listOfBooking.length) {
      this.skip = this.skip + this.limit;
      if (this.searchStr == '') {
        this.fetchExtraListData(true)
      } else {
        this.fetchExtraSearchListData(true);
      }
    }
  }

  async searchBookingData() {
    this.isLoading = true;
    if (this.searchStr == '') {
      this.limit = 250;
      this.skip = 0;
      this.fetchAllBooking(0);
    } else {
      this.limit = 250;
      this.skip = 0;
      try {
        this.listOfBooking = [];
        let param = {
          searchedString: this.searchStr, limit: this.limit,
          skip: this.skip,
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        let URL;
        URL = `${webApi.domain + webApi.endPoint.searchBookingFromAdmin}`;
        await this.appService
          .fetchDetail(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfOriginalTurf = data['result'];
              data['result'].forEach((booking: any) => {
                this.listOfBooking.push({
                  _id: booking['_id'],
                  id: booking['_id'],
                  name:
                    booking['user']['firstName'] +
                    ' ' +
                    booking['user']['lastName'],
                  phone: booking['user']['phone'],
                  bookingMode: booking['bookingMode'].toLocaleUpperCase(),
                  bookingStatus: booking['bookingStatus'],
                  paymentStatus: booking['paymentStatus'],
                  totalAmount: booking['totalAmount'],
                  tax: booking['tax'],
                  amountPaid: booking['amountPaid'],
                  transactionId: booking['transactionId'],
                  sizeOrSport: booking['sizeOrSport'],
                  bookingDate: booking['bookingDate'],
                  createdAt: booking['createdAt'],
                  ogBookingDate: booking['ogBookingDate'],
                  label: booking['label'],
                  sport: booking['sport'],
                  sportName: booking['sport']['sport'],
                  rentedItem: booking['rentedItem'],
                  user: booking['user'],
                  sportCategory: booking['sportCategory'],
                  business: booking['business'],
                  turfId: booking['turfId'],
                  turfName: booking['turfId']['name'],
                  bookingSlotStartTime: booking['bookingSlotStartTime'],
                  bookingSlotEndTime: booking['bookingSlotEndTime'],
                });
              });
              this.listOfBookingToDisplay = this.listOfBooking;

            } else {
            }
            this.isLoading = false;
          });
      } catch (error) {
        this.isLoading = false;
      }
    }
  }
  async fetchExtraSearchListData(load: boolean) {
    this.isLoadingExtraData = load;
    try {
      let param = {
        searchedString: this.searchStr, limit: this.limit,
        skip: this.skip,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.searchBookingFromAdmin}`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            data['result'].forEach((booking: any) => {
              this.listOfOriginalTurf.push(booking);
              this.listOfBooking.push({
                _id: booking['_id'],
                id: booking['_id'],
                name:
                  booking['user']['firstName'] +
                  ' ' +
                  booking['user']['lastName'],
                phone: booking['user']['phone'],
                bookingMode: booking['bookingMode'],
                bookingStatus: booking['bookingStatus'],
                paymentStatus: booking['paymentStatus'],
                totalAmount: booking['totalAmount'],
                tax: booking['tax'],
                label: booking['label'],
                amountPaid: booking['amountPaid'],
                transactionId: booking['transactionId'],
                sizeOrSport: booking['sizeOrSport'],
                bookingDate: booking['bookingDate'],
                createdAt: booking['createdAt'],
                ogBookingDate: booking['ogBookingDate'],
                sport: booking['sport'],
                sportName: booking['sport']['sport'],
                sportCategory: booking['sportCategory'],
                rentedItem: booking['rentedItem'],
                user: booking['user'],
                business: booking['business'],
                turfId: booking['turfId'],
                turfName: booking['turfId']['name'],
                bookingSlotStartTime: booking['bookingSlotStartTime'],
                bookingSlotEndTime: booking['bookingSlotEndTime'],
              });
            });
            this.listOfBookingToDisplay = this.listOfBooking;
            this.isLoadingExtraData = false;
          } else {
          }
        });
    } catch (err) {
      this.isLoadingExtraData = false;
      console.log(err);
    }
  }
  searchData(target: any) {
    let serachQuery = target.value;
    if (serachQuery == '') {
      this.limit = 250;
      this.skip = 0;
      this.fetchAllBooking(0);
    }
    // if (serachQuery != '') {
    //   let tempSearchList: any = [];
    //   this.listOfBookingToDisplay = [];

    //   this.listOfBooking.forEach((booking: any, index: number) => {
    //     if (
    //       String(booking['name'])
    //         .toLowerCase()
    //         .includes(String(serachQuery).toLowerCase()) ||
    //       String(booking['phone'])
    //         .toLowerCase()
    //         .includes(String(serachQuery).toLowerCase()) ||
    //       String(booking['sportName'])
    //         .toLowerCase()
    //         .includes(String(serachQuery).toLowerCase()) ||
    //       String(booking['turfName'])
    //         .toLowerCase()
    //         .includes(String(serachQuery).toLowerCase())
    //     ) {
    //       tempSearchList.push(booking);
    //     }
    //   });
    //   this.listOfBookingToDisplay = [];
    //   this.listOfBookingToDisplay = tempSearchList;
    // } else {
    //   this.listOfBookingToDisplay = this.listOfBooking;
    // }
  }

  // actionAcceptDelete(booking: any, action: any) {}
  viewBookingDetail(booking: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      booking: booking,
    };
    dialogConfig.width = `50vw`;
    dialogConfig.maxWidth = `70vw`;
    dialogConfig.maxHeight = `90vh`;
    let dialogRef = this.matDialog.open(ViewBookingComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Accept Turf') {
          // const param = {
          //   turfId: res['value']['_id'],
          //   status: 'APPROVED',
          //   remark: 'Approved by admin successfully',
          // };
          // const httpHeaders = new HttpHeaders({
          //   Authorization: `Bearer ${this.userDetails['accessToken']}`,
          // });
          // const URL = `${
          //   webApi.domain + webApi.endPoint.verifyAndUnVerifyTurf
          // }`;
          // await this.appService
          //   .updateDataViaPut(param, URL, httpHeaders)
          //   .then((data: any) => {
          //     if (data['success']) {
          //       this.listOfTurf.forEach((turf: any, index: number) => {
          //         if (turf['_id'] == res['value']['_id']) {
          //           this.listOfTurf[index]['status'] = data['result']['status'];
          //           this.listOfTurf[index]['remark'] = data['result']['remark'];
          //         }
          //       });
          //       this.listOfTurfToDisplay = this.listOfTurf;
          //       this.showSuccessToaster(
          //         'Turf Accepted Successfully',
          //         'Success'
          //       );
          //     } else {
          //       this.showErrorToaster(
          //         'Error Occurred while accepting Turf. Try again',
          //         'Error'
          //       );
          //     }
          //   });
        }
      }
    });
  }
  async sendWhatsAppMessage(info: any, src: any) {
    this.isSendMessageLoading = true;
    try {
      let URL;
      if (src == 'User') {
        URL = `${webApi.domain + webApi.endPoint.sendUserMsgManually}/${info._id}`;
      } else {
        URL = `${webApi.domain + webApi.endPoint.sendOwnerMsgManually}/${info._id}`;
      }

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.appService.getMethodWithToken(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.showSuccessToaster(
            'Message Sent Successfully',
            'Success'
          );
          this.isSendMessageLoading = false;
        } else {
          this.showErrorToaster(
            'unable to send message',
            'Error'
          );
        }
        this.isSendMessageLoading = false;

      });
    } catch (error) {
      this.showErrorToaster(
        'unable to send message',
        'Error'
      );
      this.isSendMessageLoading = false;

    }
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
