import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-sport',
  templateUrl: './add-edit-sport.component.html',
  styleUrls: ['./add-edit-sport.component.scss'],
})
export class AddEditSportComponent implements OnInit {
  @Input() dataToEdit: any;
  @Input() listOfBusiness: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;

  // Pan Card Image
  selectedItemImage: any = null;
  selectedItemName: any = null;
  defaultItemImage: any;

  myHeaders = new Headers();
  formdata = new FormData();
  listOfSports: any = [];

  sportNameController = new FormControl('', [Validators.required]);
  sportCategoryController = new FormControl('', [Validators.required]);

  addSportForm: FormGroup = new FormGroup({
    sportName: this.sportNameController,
    sportCategory: this.sportCategoryController,
  });
  userDetails: any;
  selectedSportId: string = '';

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
  }
  ngOnInit(): void {
    this.addSportForm = this.formBuilder.group({
      sportName: this.sportNameController,
      sportCategory: this.sportCategoryController,
    });
    if (this.dataToEdit != null) {
      this.addSportForm
        .get('sportCategory')
        ?.setValue(this.dataToEdit['sportCategory']['_id']);
      this.addSportForm.get('sportName')?.setValue(this.dataToEdit['sport']);
      this.defaultItemImage = this.dataToEdit['image'];
    }
    this.fetchAllSportsCategory();
  }
  displayPancard(event: any) {
    this.selectedItemImage = event.target.files[0];
    this.selectedItemName = this.selectedItemImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultItemImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedItemImage);
  }

  downloadImage(url: any) {
    window.open(url);
  }

  categoryChangeHandler(event: any) {
    this.addSportForm.get('sportCategory')?.setValue(event.value);
  }

  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  async fetchAllSportsCategory() {
    this.isLoading = true;
    this.listOfSports = [];
    try {
      let param = {};
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchSportCategory}`;
      param = {};
      await this.as.fetchDetailViaGet(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfSports = data['result'];
          console.log(this.listOfSports);
          this.isLoading = false;
        } else {
          this.listOfSports = [];
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.listOfSports = [];
      this.isLoading = false;
    }
  }

  async addEditItem() {
    this.isLoading = true;
    if (this.dataToEdit != null) {
      this.formdata.append('sportId', this.dataToEdit['_id']);
      this.formdata.append('image', JSON.stringify(this.defaultItemImage));
    } else {
      this.formdata.append('sportId', '0');
    }
    if (this.selectedItemImage != null) {
      this.formdata.append(
        'image',
        this.selectedItemImage,
        this.selectedItemName
      );
      // this.formdata.append('productImage', this.selectedItemImage[0]);
    }

    this.formdata.append('sport', this.addSportForm.get('sportName')?.value);
    this.formdata.append(
      'sportCategory',
      this.addSportForm.get('sportCategory')?.value
    );

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    var URL;

    URL = `${webApi.domain + webApi.endPoint.insertAndUpdateSport}`;

    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addSportForm.reset();
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
}
