import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { webApi } from 'src/app/api';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-slots',
  templateUrl: './add-slots.component.html',
  styleUrls: ['./add-slots.component.scss']
})
export class AddSlotsComponent implements OnInit {
  @Input() dataToEdit: any;
  @Input() listOfSports: any;
  @Output() backToPreviousStepper: EventEmitter<any> = new EventEmitter();
  @Output() closeEdit: EventEmitter<any> = new EventEmitter();

  userDetails: any;
  isLoading: boolean = false;
  turfId: any;

  myHeaders = new Headers();
  formdata = new FormData();

  isAddEditAddressLoading: boolean = false;
  isChangingNetToNonNetting: boolean = false;

  // Days
  selectedDays: any = [];
  assignedDays: any = [];

   //Sport Category
   sportCategory: any = [];
   selectedSportCategory: any = {};

     // Sport Type
  selectedSport: any = [];
  assignedSport: any = [];

  // Facilities Type
  selectedFacilities: any = [];
  assignedFacilities: any = [];

  selectedTurfSize: any = [];

  listofSlotForMorning: any = [];
  listofSlotForAfternoon: any = [];
  listofSlotForEvening: any = [];
  listofSlotForNight: any = [];
  gameQuantity: any = [];

  isMorningSlotSelected: boolean = false;
  isAfternoonSlotSelected: boolean = false;
  isEveningSlotSelected: boolean = false;
  isNightSlotSelected: boolean = false;
  isValidTurfSlot: boolean = false;
  isValidTurfPrice: boolean = false;

  isNettingButtonSelected: boolean = true;
  isNonNettingButtonSelected: boolean = false;

  daysDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'dayLabel',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 7,
    allowSearchFilter: true,
  };

  sportsTypeDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'sport',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };

  sportCategoryDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'sport',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };

  facilitiesDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'facilityName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };

  listOfFacilities: any = [
    {
      _id: 1,
      facilityName: 'Washroom',
    },
    {
      _id: 2,
      facilityName: 'Cafe & Food court',
    },
    {
      _id: 3,
      facilityName: 'Power Backup',
    },
    {
      _id: 4,
      facilityName: 'Changing Room',
    },
    {
      _id: 5,
      facilityName: 'First Aid',
    },
    {
      _id: 6,
      facilityName: 'Parking',
    },
  ];

  listOfDays: any = [
    { _id: 1, val: 'Monday', dayLabel: 'Mon' },
    { _id: 2, val: 'Tueday', dayLabel: 'Tue' },
    { _id: 3, val: 'Wednesday', dayLabel: 'Wed' },
    { _id: 4, val: 'Thursday', dayLabel: 'Thu' },
    { _id: 5, val: 'Friday', dayLabel: 'Fri' },
    { _id: 6, val: 'Saturday', dayLabel: 'Sat' },
    { _id: 7, val: 'Sunday', dayLabel: 'Sun' },
  ];

  morningSlotTime: any = {
    startTime: 5.0,
    endTime: 12.0,
  };
  afternoonSlotTime: any = {
    startTime: 12.0,
    endTime: 16.0,
  };
  eveningSlotTime: any = {
    startTime: 16.0,
    endTime: 20.0,
  };
  nightSlotTime: any = {
    startTime: 20.0,
    endTime: 24.0,
  };

  listOfTurfSize: any = [
    {
      title: '1:1',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
    {
      title: '5:5',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
    {
      title: '6:6',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
    {
      title: '7:7',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
    {
      title: '8:8',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
    {
      title: '9:9',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
    {
      title: '11:11',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
  ];

  listOfPriceForMorningOriginal: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
  ];
  listOfPriceForAfternoonOriginal: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },

  ];
  listOfPriceForEveningOriginal: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
  ];
  listOfPriceForNightOriginal: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
  ];

  listOfPriceForMorning: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
  ];

  listOfPriceForAfternoon: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
  ];

  listOfPriceForEvening: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
  ];
  listOfPriceForNight: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
  ];

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private appService: AppService,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
    this._activatedRoute.queryParams.subscribe(params => {
      this.turfId = params['id'];
      console.log(this.turfId);
    });
  }

  ngOnInit(): void {
    this.fetchSport();
  }


  async fetchSport() {
    this.isLoading = true;
    this.listOfSports = [];
    try {
      let param = {};
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchSport}`;
      param = {};
      await this.appService.fetchDetailViaGet(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfSports = data['result'];
          this.listOfSports.forEach((sport: any) => {
            const index = this.sportCategory.findIndex(
              (data: any) => data['id'] == sport['sportCategory']['_id']
            );
            if (index == -1) {
              this.sportCategory.push({
                id: sport['sportCategory']['_id'],
                categoryName: sport['sportCategory']['categoryName'],
                isSelected: false,
              });
            }
          });
          this.sportCategory[0]['isSelected'] = true;
          this.selectedSportCategory = {
            categoryId: this.sportCategory[0]['id'],
            categoryName: this.sportCategory[0]['categoryName'],
          };
          this.isLoading = false;
  
        } else {
          this.isLoading = false;
          this.listOfSports = [];
  
        }
      });
    } catch (error) {
      this.isLoading = false;
      this.listOfSports = [];
    }
  }

  getSports() {
    return this.listOfSports.filter(
      (sport: any) =>
        sport['sportCategory']['_id'] ==
        this.selectedSportCategory['categoryId']
    );
  }

  radioHandlerSportCategory(event: any) {
    this.selectedSport = [];
    this.assignedSport = [];
    this.isMorningSlotSelected = false;
    this.isAfternoonSlotSelected = false;
    this.isEveningSlotSelected = false;
    this.isNightSlotSelected = false;
    this.listOfTurfSize.forEach((data: any) => {
      this.gameQuantity = [];
      data['isSelected'] = false;
      data['enabled'] = false;
    });

    this.sportCategory.forEach((data: any) => {
      if (data['id'] == event.target.name) {
        data['isSelected'] = true;
        this.selectedSportCategory = {
          categoryId: data['id'],
          categoryName: data['categoryName'],
        };
      } else {
        data['isSelected'] = false;
      }
    });
  }

  checkForWeekend() {
    var p = 0;
    for (let index = 0; index < this.selectedDays.length; index++) {
      const element = this.selectedDays[index];
      if (element['dayLabel'] == 'Sat' || element['dayLabel'] == 'Sun') {
        p = 1;
      }
    }
    if (p == 1) {
      return true;
    } else {
      return false;
    }
  }
  checkForWeekday() {
    var p = 0;
    for (let index = 0; index < this.selectedDays.length; index++) {
      const element = this.selectedDays[index];
      if (
        element['dayLabel'] == 'Mon' ||
        element['dayLabel'] == 'Tue' ||
        element['dayLabel'] == 'Wed' ||
        element['dayLabel'] == 'Thu' ||
        element['dayLabel'] == 'Fri'
      ) {
        p = 1;
      }
    }
    if (p == 1) {
      return true;
    } else {
      return false;
    }
  }

  onItemSelect(item: any) {
    console.log(item);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  onItemDeSelect(item: any) {
    console.log(item);
  }

  addOrRemoveSize(title: any, event: any) {
    console.log(event.target.checked);
    const index = this.selectedTurfSize.findIndex(
      (turfSize: any) => turfSize == title
    );
    if (index == -1) {
      this.selectedTurfSize.push(title);
    } else {
      this.selectedTurfSize.splice(index, 1);
    }
    this.listOfTurfSize.forEach((slot: any) => {
      if (slot.title == title) {
        slot.isSelected = event.target.checked;
      }
    });
    if (this.isNettingButtonSelected) {
      if (title == '8:8') {
        this.listOfTurfSize.forEach((slot: any) => {
          if (slot.title == '9:9' || slot.title == '7:7') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForMorning.forEach((slot: any) => {
          if (slot.title == '9:9' || slot.title == '7:7') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForAfternoon.forEach((slot: any) => {
          if (slot.title == '9:9' || slot.title == '7:7') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForEvening.forEach((slot: any) => {
          if (slot.title == '9:9' || slot.title == '7:7') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForNight.forEach((slot: any) => {
          if (slot.title == '9:9' || slot.title == '7:7') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
      } else if (title == '9:9' || title == '7:7') {
        this.listOfTurfSize.forEach((slot: any) => {
          if (slot.title == '8:8') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForMorning.forEach((slot: any) => {
          if (slot.title == '8:8') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForAfternoon.forEach((slot: any) => {
          if (slot.title == '8:8') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForEvening.forEach((slot: any) => {
          if (slot.title == '8:8') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForNight.forEach((slot: any) => {
          if (slot.title == '8:8') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
      }
    } else {
      this.listOfPriceForMorning.forEach((slot: any) => {
        slot.enabled = true;
      });
      this.listOfPriceForAfternoon.forEach((slot: any) => {
        slot.enabled = true;
      });
      this.listOfPriceForEvening.forEach((slot: any) => {
        slot.enabled = true;
      });
      this.listOfPriceForNight.forEach((slot: any) => {
        slot.enabled = true;
      });
    }
    let tempMorningPrice: any = [];
    this.listOfPriceForMorningOriginal.forEach((morningSlot: any) => {
      this.listOfTurfSize.forEach((element: any) => {
        if (element['title'] == morningSlot['title'] && element['isSelected']) {
          tempMorningPrice.push(morningSlot);
        }
      });
    });
    this.listOfPriceForMorning = tempMorningPrice;
    let tempAfternoonPrice: any = [];
    this.listOfPriceForAfternoonOriginal.forEach((afternoonSlot: any) => {
      this.listOfTurfSize.forEach((element: any) => {
        if (
          element['title'] == afternoonSlot['title'] &&
          element['isSelected']
        ) {
          tempAfternoonPrice.push(afternoonSlot);
        }
      });
    });
    this.listOfPriceForAfternoon = tempAfternoonPrice;
    let tempEveningPrice: any = [];
    this.listOfPriceForEveningOriginal.forEach((eveningSlot: any) => {
      this.listOfTurfSize.forEach((element: any) => {
        if (element['title'] == eveningSlot['title'] && element['isSelected']) {
          tempEveningPrice.push(eveningSlot);
        }
      });
    });
    this.listOfPriceForEvening = tempEveningPrice;
    let tempNightPrice: any = [];
    this.listOfPriceForNightOriginal.forEach((nightSlot: any) => {
      this.listOfTurfSize.forEach((element: any) => {
        if (element['title'] == nightSlot['title'] && element['isSelected']) {
          tempNightPrice.push(nightSlot);
        }
      });
    });
    this.listOfPriceForNight = tempNightPrice;
  }

  changeSlotSelection(slot: any, event: any) {
    let isSelected = event.target.checked;
    this.gameQuantity = [];
    this.selectedSport.forEach((data: any) => {
      this.gameQuantity.push({
        quantity: 0,
        title: data['sport'],
        isSelected: true,
        enabled: true,
        label: '',
      });
    });
    if (slot == 'Morning') {
      this.isMorningSlotSelected = isSelected;
      this.listOfPriceForMorning.forEach((element: any) => {
        element['isSelected'] = false;
      });
      if (this.selectedSportCategory['categoryName'] != 'Outdoor') {
        if (isSelected) {
          this.listofSlotForMorning = [];
          this.selectedSport.forEach((data: any) => {
            this.listofSlotForMorning.push({
              price: 0,
              quantity: 0,
              weekendPrice: 0,
              title: data['sport'],
              isSelected: true,
              enabled: false,
            });
          });
        } else {
          this.listofSlotForMorning = [];
        }
      }
    } else if (slot == 'Afternoon') {
      this.isAfternoonSlotSelected = isSelected;
      this.listOfPriceForAfternoon.forEach((element: any) => {
        element['isSelected'] = false;
      });
      if (this.selectedSportCategory['categoryName'] != 'Outdoor') {
        if (isSelected) {
          this.listofSlotForAfternoon = [];
          this.selectedSport.forEach((data: any) => {
            this.listofSlotForAfternoon.push({
              price: 0,
              quantity: 0,
              weekendPrice: 0,
              title: data['sport'],
              isSelected: true,
              enabled: false,
            });
          });
        } else {
          this.listofSlotForAfternoon = [];
        }
      }
    } else if (slot == 'Evening') {
      this.isEveningSlotSelected = isSelected;
      this.listOfPriceForEvening.forEach((element: any) => {
        element['isSelected'] = false;
      });
      if (this.selectedSportCategory['categoryName'] != 'Outdoor') {
        if (isSelected) {
          this.listofSlotForEvening = [];
          this.selectedSport.forEach((data: any) => {
            this.listofSlotForEvening.push({
              price: 0,
              quantity: 0,
              weekendPrice: 0,
              title: data['sport'],
              isSelected: true,
              enabled: false,
            });
          });
        } else {
          this.listofSlotForEvening = [];
        }
      }
    } else if (slot == 'Night') {
      this.isNightSlotSelected = isSelected;
      this.listOfPriceForNight.forEach((element: any) => {
        element['isSelected'] = false;
      });
      if (this.selectedSportCategory['categoryName'] != 'Outdoor') {
        if (isSelected) {
          this.listofSlotForNight = [];
          this.selectedSport.forEach((data: any) => {
            this.listofSlotForNight.push({
              price: 0,
              quantity: 0,
              weekendPrice: 0,
              title: data['sport'],
              isSelected: true,
              enabled: false,
            });
          });
        } else {
          this.listofSlotForNight = [];
        }
      }
    }

    if (
      this.isMorningSlotSelected ||
      this.isAfternoonSlotSelected ||
      this.isEveningSlotSelected ||
      this.isNightSlotSelected
    ) {
      this.isValidTurfSlot = true;
    }
  }

  addPriceForTurfSize(title: any, target: any, slot: any, weekend: boolean) {
    let price = target.value;
    if (slot == 'Morning') {
      if (this.selectedSportCategory['categoryName'] == 'Outdoor') {
        this.listOfPriceForMorning.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            if (weekend) {
              prc['weekendPrice'] = parseInt(price);
            } else {
              prc['price'] = parseInt(price);
            }
          }
        });
      } else {
        this.listofSlotForMorning.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            if (weekend) {
              prc['weekendPrice'] = parseInt(price);
            } else {
              prc['price'] = parseInt(price);
            }
          }
        });
      }
    } else if (slot == 'Afternoon') {
      if (this.selectedSportCategory['categoryName'] == 'Outdoor') {
        this.listOfPriceForAfternoon.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            if (weekend) {
              prc['weekendPrice'] = parseInt(price);
            } else {
              prc['price'] = parseInt(price);
            }
          }
        });
      } else {
        this.listofSlotForAfternoon.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            if (weekend) {
              prc['weekendPrice'] = parseInt(price);
            } else {
              prc['price'] = parseInt(price);
            }
          }
        });
      }
    } else if (slot == 'Evening') {
      if (this.selectedSportCategory['categoryName'] == 'Outdoor') {
        this.listOfPriceForEvening.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            if (weekend) {
              prc['weekendPrice'] = parseInt(price);
            } else {
              prc['price'] = parseInt(price);
            }
          }
        });
      } else {
        this.listofSlotForEvening.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            if (weekend) {
              prc['weekendPrice'] = parseInt(price);
            } else {
              prc['price'] = parseInt(price);
            }
          }
        });
      }
    } else if (slot == 'Night') {
      if (this.selectedSportCategory['categoryName'] == 'Outdoor') {
        this.listOfPriceForNight.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            if (weekend) {
              prc['weekendPrice'] = parseInt(price);
            } else {
              prc['price'] = parseInt(price);
            }
          }
        });
      } else {
        this.listofSlotForNight.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            if (weekend) {
              prc['weekendPrice'] = parseInt(price);
            } else {
              prc['price'] = parseInt(price);
            }
          }
        });
      }
    }
  }

  changePricingSelectionForMorning(title: any, event: any) {
    let isSelected = event.target.checked;
    this.listOfPriceForMorning.forEach((prc: any, index: number) => {
      if (prc['title'] == title) {
        prc['isSelected'] = isSelected;
      }
    });
    let isValid = false;
    this.listOfPriceForMorning.forEach((val: any) => {
      if (val['isSelected']) {
        isValid = true;
      }
    });
    if (isValid) {
      this.isValidTurfPrice = true;
    }
  }
  changePricingSelectionForAfternoon(title: any, event: any) {
    let isSelected = event.target.checked;
    this.listOfPriceForAfternoon.forEach((prc: any, index: number) => {
      if (prc['title'] == title) {
        prc['isSelected'] = isSelected;
      }
    });
    let isValid = false;
    this.listOfPriceForAfternoon.forEach((val: any) => {
      if (val['isSelected']) {
        isValid = true;
      }
    });
    if (isValid) {
      this.isValidTurfPrice = true;
    }
  }
  changePricingSelectionForEvening(title: any, event: any) {
    let isSelected = event.target.checked;
    this.listOfPriceForEvening.forEach((prc: any, index: number) => {
      if (prc['title'] == title) {
        prc['isSelected'] = isSelected;
      }
    });
    let isValid = false;
    this.listOfPriceForEvening.forEach((val: any) => {
      if (val['isSelected']) {
        isValid = true;
      }
    });
    if (isValid) {
      this.isValidTurfPrice = true;
    }
  }
  changePricingSelectionForNight(title: any, event: any) {
    let isSelected = event.target.checked;
    this.listOfPriceForNight.forEach((prc: any, index: number) => {
      if (prc['title'] == title) {
        prc['isSelected'] = isSelected;
      }
    });
    let isValid = false;
    this.listOfPriceForNight.forEach((val: any) => {
      if (val['isSelected']) {
        isValid = true;
      }
    });
    if (isValid) {
      this.isValidTurfPrice = true;
    }
  }

  addTimeForSlot(target: any, slot: any, startTime: boolean) {
    if (slot == 'Morning') {
      if (startTime) {
        this.morningSlotTime['startTime'] = target.value;
      } else {
        this.morningSlotTime['endTime'] = target.value;
      }
    } else if (slot == 'Afternoon') {
      if (startTime) {
        this.afternoonSlotTime['startTime'] = target.value;
      } else {
        this.afternoonSlotTime['endTime'] = target.value;
      }
    } else if (slot == 'Evening') {
      if (startTime) {
        this.eveningSlotTime['startTime'] = target.value;
      } else {
        this.eveningSlotTime['endTime'] = target.value;
      }
    } else if (slot == 'Night') {
      if (startTime) {
        this.nightSlotTime['startTime'] = target.value;
      } else {
        this.nightSlotTime['endTime'] = target.value;
      }
    }
  }

  cancelBtnHandler() {
    this.backToPreviousStepper.emit();
  }

  addTurfSlots() {
      this.isLoading = true;

      if (this.turfId != null) {
        this.formdata.set('turfId', this.turfId);
      }

      // Days
      this.assignedDays = [];
      this.selectedDays.forEach((day: any) => {
        this.assignedDays.push(day['dayLabel']);
      });
      this.formdata.set('days', JSON.stringify(this.assignedDays));
      // Facility
      this.selectedFacilities.forEach((facility: any) => {
        this.assignedFacilities.push(facility['facilityName']);
      });
      this.formdata.set('facilities', JSON.stringify(this.assignedFacilities));
      // Sport
      this.assignedSport = [];
      this.selectedSport.forEach((sport: any) => {
        this.assignedSport.push(sport['_id']);
      });
      this.formdata.set('sportsType', JSON.stringify(this.assignedSport));

      // Turf Price
      let priceToInsert: any = [];
      if (this.selectedSportCategory['categoryName'] == 'Outdoor') {
        if (
          this.listOfPriceForMorning.length > 0 &&
          this.isMorningSlotSelected
        ) {
          priceToInsert.push({
            session: 'Morning',
            startTime: this.morningSlotTime['startTime'],
            endTime: this.morningSlotTime['endTime'],
            priceAndQuantity: [],
          });
          let index = 0;
          priceToInsert.forEach((element: any, i: number) => {
            if (element['session'] == 'Morning') {
              index = i;
            }
          });
          this.listOfPriceForMorning.forEach((prc: any) => {
            if (prc['isSelected']) {
              priceToInsert[index]['priceAndQuantity'].push({
                title: prc['title'],
                price: prc['price'],
                weekendPrice: prc['weekendPrice'],
                quantity: prc['quantity'],
                label: prc['label'],
              });
            }
          });
        }
        if (
          this.listOfPriceForAfternoon.length > 0 &&
          this.isAfternoonSlotSelected
        ) {
          priceToInsert.push({
            session: 'Afternoon',
            startTime: this.afternoonSlotTime['startTime'],
            endTime: this.afternoonSlotTime['endTime'],
            priceAndQuantity: [],
          });
          let index = 0;
          priceToInsert.forEach((element: any, i: number) => {
            if (element['session'] == 'Afternoon') {
              index = i;
            }
          });
          this.listOfPriceForAfternoon.forEach((prc: any) => {
            if (prc['isSelected']) {
              priceToInsert[index]['priceAndQuantity'].push({
                title: prc['title'],
                price: prc['price'],
                weekendPrice: prc['weekendPrice'],
                quantity: prc['quantity'],
                label: prc['label'],
              });
            }
          });
        }
        if (
          this.listOfPriceForEvening.length > 0 &&
          this.isEveningSlotSelected
        ) {
          priceToInsert.push({
            session: 'Evening',
            startTime: this.eveningSlotTime['startTime'],
            endTime: this.eveningSlotTime['endTime'],
            priceAndQuantity: [],
          });
          let index = 0;
          priceToInsert.forEach((element: any, i: number) => {
            if (element['session'] == 'Evening') {
              index = i;
            }
          });
          this.listOfPriceForEvening.forEach((prc: any) => {
            if (prc['isSelected']) {
              priceToInsert[index]['priceAndQuantity'].push({
                title: prc['title'],
                price: prc['price'],
                weekendPrice: prc['weekendPrice'],
                quantity: prc['quantity'],
                label: prc['label'],
              });
            }
          });
        }
        if (this.listOfPriceForNight.length > 0 && this.isNightSlotSelected) {
          priceToInsert.push({
            session: 'Night',
            startTime: this.nightSlotTime['startTime'],
            endTime: this.nightSlotTime['endTime'],
            priceAndQuantity: [],
          });
          let index = 0;
          priceToInsert.forEach((element: any, i: number) => {
            if (element['session'] == 'Night') {
              index = i;
            }
          });
          this.listOfPriceForNight.forEach((prc: any) => {
            if (prc['isSelected']) {
              priceToInsert[index]['priceAndQuantity'].push({
                title: prc['title'],
                price: prc['price'],
                weekendPrice: prc['weekendPrice'],
                quantity: prc['quantity'],
              });
            }
          });
        }

        priceToInsert.forEach((price: any) => {
          price['priceAndQuantity'].forEach((quantity: any) => {
            this.listOfTurfSize.forEach((game: any) => {
              if (game['title'] == quantity['title']) {
                quantity['quantity'] = game['quantity'];
                quantity['label'] = game['label'];
              }
            });
          });
        });
      } else {
        if (this.isMorningSlotSelected) {
          priceToInsert.push({
            session: 'Morning',
            startTime: this.morningSlotTime['startTime'],
            endTime: this.morningSlotTime['endTime'],
            priceAndQuantity: [],
          });
          let index = 0;
          priceToInsert.forEach((element: any, i: number) => {
            if (element['session'] == 'Morning') {
              index = i;
            }
          });
          this.listofSlotForMorning.forEach((data: any) => {
            if (data['isSelected']) {
              priceToInsert[index]['priceAndQuantity'].push({
                title: data['title'],
                price: data['price'],
                weekendPrice: data['weekendPrice'],
                quantity: data['quantity'],
                label: data['label'],
              });
            }
          });
        }
        if (this.isAfternoonSlotSelected) {
          priceToInsert.push({
            session: 'Afternoon',
            startTime: this.afternoonSlotTime['startTime'],
            endTime: this.afternoonSlotTime['endTime'],
            priceAndQuantity: [],
          });
          let index = 0;
          priceToInsert.forEach((element: any, i: number) => {
            if (element['session'] == 'Afternoon') {
              index = i;
            }
          });
          this.listofSlotForAfternoon.forEach((prc: any) => {
            if (prc['isSelected']) {
              priceToInsert[index]['priceAndQuantity'].push({
                title: prc['title'],
                price: prc['price'],
                weekendPrice: prc['weekendPrice'],
                quantity: prc['quantity'],
                label: prc['label'],
              });
            }
          });
        }
        if (this.isEveningSlotSelected) {
          priceToInsert.push({
            session: 'Evening',
            startTime: this.eveningSlotTime['startTime'],
            endTime: this.eveningSlotTime['endTime'],
            priceAndQuantity: [],
          });
          let index = 0;
          priceToInsert.forEach((element: any, i: number) => {
            if (element['session'] == 'Evening') {
              index = i;
            }
          });
          this.listofSlotForEvening.forEach((prc: any) => {
            if (prc['isSelected']) {
              priceToInsert[index]['priceAndQuantity'].push({
                title: prc['title'],
                price: prc['price'],
                weekendPrice: prc['weekendPrice'],
                quantity: prc['quantity'],
                label: prc['label'],
              });
            }
          });
        }
        if (this.isNightSlotSelected) {
          priceToInsert.push({
            session: 'Night',
            startTime: this.nightSlotTime['startTime'],
            endTime: this.nightSlotTime['endTime'],
            priceAndQuantity: [],
          });
          let index = 0;
          priceToInsert.forEach((element: any, i: number) => {
            if (element['session'] == 'Night') {
              index = i;
            }
          });
          this.listofSlotForNight.forEach((prc: any) => {
            if (prc['isSelected']) {
              priceToInsert[index]['priceAndQuantity'].push({
                title: prc['title'],
                price: prc['price'],
                weekendPrice: prc['weekendPrice'],
                quantity: prc['quantity'],
                label: prc['label'],
              });
            }
          });
        }
        priceToInsert.forEach((price: any) => {
          price['priceAndQuantity'].forEach((quantity: any) => {
            this.gameQuantity.forEach((game: any) => {
              if (game['title'] == quantity['title']) {
                quantity['quantity'] = game['quantity'];
                quantity['label'] = game['label'];
              }
            });
          });
        });
      }

      console.log(priceToInsert);

      this.formdata.set('slots', JSON.stringify(priceToInsert));

      this.formdata.set(
        'sportCategory',
        this.selectedSportCategory['categoryId']
      );
      
      this.formdata.set('isNet', this.isNettingButtonSelected.toString());

      this.myHeaders.set(
        'Authorization',
        `Bearer ${this.userDetails['accessToken']}`
      );
      var requestOptions: RequestInit = {
        method: 'POST',
        headers: this.myHeaders,
        body: this.formdata,
        redirect: 'follow',
      };
      var URL;
        URL = `${webApi.domain + webApi.endPoint.createAndEditTurfSlotsV2}`;
      try {
        fetch(URL, requestOptions)
          .then((result) => {
            if (result) {
              let data = result.json();
              data.then((res) => {
                if (res['success']) {
                  this.isAddEditAddressLoading = false;
                  if (this.turfId != null) {
                    this.showSuccessToaster('Turf Slots edited successfully', 'Success');
                  } else {
                    this.showSuccessToaster('Turf Slots added successfully', 'Success');
                  }
                  this.closeEdit.emit();
                } else {
                  this.isAddEditAddressLoading = false;
                  if (this.turfId != null) {
                    this.showWarningToaster(res['message'], 'Warning');
                  } else {
                    this.showWarningToaster(res['message'], 'Warning');
                  }
                }
              });
            }
          })
      } catch (error) {
        this.isAddEditAddressLoading = false;
        if (this.turfId != null) {
          this.showWarningToaster('Unable to edit Turf Slots', 'Warning');
        } else {
          this.showWarningToaster('Unable to add Turf Slots', 'Warning');
        }
      }
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }

}
