import { formatDate } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-generate-report-dialof',
  templateUrl: './generate-report-dialof.component.html',
  styleUrls: ['./generate-report-dialof.component.scss'],
})
export class GenerateReportDialofComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  isLoading: boolean = false;

  minStartDate: any;
  minEndDate: any;
  maxStartDate: any;
  maxEndDate: any;

  turfdropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
    enableCheckAll: false,
  };
  listOfBusiness: any = [];
  listOfTurf: any = [];
  listOfTurfToDisplay: any = [];
  selectedTurf: any = [];

  listOfDuration: any = [
    {
      label: 'Past 7 Days',
      days: 7,
    },
    {
      label: 'Past 30 Days',
      days: 30,
    },
    {
      label: 'Past 90 Days',
      days: 90,
    },
    {
      label: 'Custom Range',
      days: 35,
    },
    {
      label: 'All time',
      days: 9999,
    },
  ];
  durationControl = new FormControl('Select Duration', [Validators.required]);
  businessControl = new FormControl('Select Business', [Validators.required]);
  fileNameControl = new FormControl('', [Validators.required]);
  startDateControl = new FormControl('', [Validators.required]);
  endDateControl = new FormControl('', [Validators.required]);

  generateReportForm: FormGroup = new FormGroup({
    duration: this.durationControl,
    fileName: this.fileNameControl,
    startDate: this.startDateControl,
    endDate: this.endDateControl,
    business: this.businessControl,
  });
  userDetails: any;
  constructor(
    public dialogRef: MatDialogRef<GenerateReportDialofComponent>,
    private toastr: ToastrService,
    public appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    dialogRef.disableClose = true;
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
    if (this.appService.listOfBusinessForReport != null) {
      this.listOfBusiness = this.appService.listOfBusinessForReport;
    }
    if (this.appService.listOfTurfForReport != null) {
      this.listOfTurf = this.appService.listOfTurfForReport;
      this.listOfTurfToDisplay = [...this.listOfTurf];
    }
  }

  ngOnInit(): void {
    this.generateReportForm = this.formBuilder.group({
      duration: this.durationControl,
      fileName: this.fileNameControl,
      startDate: this.startDateControl,
      endDate: this.endDateControl,
      business: this.businessControl,
    });
  }
  startDateChange(event: any) {
    console.log(event.target.value);
    this.minEndDate = event.target.value;
  }
  endDateChange(event: any) {
    console.log(event.target.value);
    this.maxStartDate = event.target.value;
  }
  onDurationSelectionChange(event: any) {
    console.log(event.target.value);
    // let selectedVendor = event.target.value;
    let todayDate = new Date();
    let lastEndDate = new Date(
      todayDate.setDate(todayDate.getDate() - event.target.value)
    );
    let lastStartDate = new Date();
    this.minEndDate = formatDate(new Date(lastEndDate), 'yyyy-MM-dd', 'en-US');
    this.maxStartDate = formatDate(
      new Date(lastStartDate),
      'yyyy-MM-dd',
      'en-US'
    );
    this.generateReportForm
      .get('startDate')
      ?.setValue(formatDate(new Date(lastEndDate), 'yyyy-MM-dd', 'en-US'));
    this.generateReportForm
      .get('endDate')
      ?.setValue(formatDate(new Date(lastStartDate), 'yyyy-MM-dd', 'en-US'));
  }
  onBusinessSelectionChange(event: any) {
    console.log(event.target.value);

    let bid = event.target.value;

    this.listOfTurfToDisplay = [];

    if (bid == 'All') {
      this.listOfTurfToDisplay = [...this.listOfTurf];
    } else {
      this.listOfTurf.forEach((element: any) => {
        if (element['business'] == bid) {
          this.listOfTurfToDisplay.push(element);
        }
      });
    }


  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onItemDeSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  async generateReport() {
    this.isLoading = true;
    let URL = '';
    let param: any = {};
    let startDateVal = new Date(
      this.generateReportForm.get('startDate')?.value
    );
    let startDate = new Date(startDateVal.setHours(0, 0, 0, 0));
    let endDateVal = new Date(this.generateReportForm.get('endDate')?.value);
    let endDate = new Date(endDateVal.setHours(23, 59, 59, 59));
    param = {
      fileName: this.generateReportForm.get('fileName')?.value,
      startDate: startDate,
      endDate: endDate,
    };

    if (this.data['type'] == 'Booking Report') {
      if (this.generateReportForm.get('business')?.value != 'All') {
        param['businessId'] = this.generateReportForm.get('business')?.value;
      }
      if (this.selectedTurf.length > 0) {
        let turfIds: any = [];
        this.selectedTurf.forEach((element: any) => {
          turfIds.push(element._id);
        });
        param['turfId'] = JSON.stringify(turfIds);
      }
    }


    if (this.data['type'] == 'User Registration') {
      URL = `${webApi.domain + webApi.endPoint.generateUserRegistrationReport}`;
    } else if (this.data['type'] == 'User Last Active') {
      URL = `${webApi.domain + webApi.endPoint.generateLastActiveReport}`;
    } else {
      URL = `${webApi.domain + webApi.endPoint.generateBookingReport}`;

    }
    this.showSuccessToaster(
      'Report is being generated.It will reflect within few minutes',
      'Please Wait'
    );
    this.dialogRef.close();
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    await this.appService
      .postMethodWithToken(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.isLoading = false;
          this.closeActivityAtDialog.emit({
            result: data['result'],
          });
        } else {
          this.isLoading = false;

          this.closeActivityAtDialog.emit({ error: 'Error' });
        }
        this.dialogRef.close();
      });
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarninigToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
