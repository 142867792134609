<div style="position: relative;">
    <div class="clear-button" (click)="close()">
        <mat-icon class="mat-clear-icon">clear</mat-icon>
    </div>
    <h2 mat-dialog-title style="font-weight: 600;margin-bottom: 5px;">Booking Detail</h2>
    <hr>
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12" style="display: flex;">

                    <div style="width: 10em;">
                        Booking ID:
                    </div>
                    <div style="width: 9em;font-size: 80%;">
                        {{data['booking']['_id'] }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display: flex;">
                    <div style="width: 10em;">
                        Client Name:
                    </div>
                    <div style="width: 9em;font-size: 90%;">
                        {{data['booking']['name'] }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display: flex;">

                    <div style="width: 10em;">
                        Client Phone:
                    </div>
                    <div style="width: 9em;font-size: 80%;">
                        {{data['booking']['phone'] }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display: flex;">
                    <div style="width: 10em;">
                        Booking Date:
                    </div>
                    {{data['booking']['createdAt'] | date}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display: flex;">
                    <div style="width: 10em;">
                        Slot Booking Date:
                    </div>
                    {{data['booking']['bookingDate'] | date}}
                </div>
            </div>
            <div class="row" *ngIf="data['booking']['sportCategory']['categoryName']=='Outdoor'">
                <div class="col-md-12" style="display: flex;">
                    <div style="width: 10em;">
                        Turf Size:
                    </div>
                    {{data['booking']['sizeOrSport'] }}
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display: flex;">
                    <div style="width: 10em;">
                        {{data['booking']['sportCategory']['categoryName']=='Outdoor'?'Sport':'Game'}}:
                    </div>
                    {{data['booking']['sport']['sport'] }}
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12" style="display: flex;">
                    <div style="font-weight: 600;">
                        Venue Detail:-
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display: flex;">
                    <div style="font-weight: 500;margin-bottom: 5px;"
                        *ngIf="data['booking']['label']=='' || data['booking']['label']==null || data['booking']['label']==undefined">
                        {{data['booking']['turfId']['name']}}
                    </div>
                    <div style="font-weight: 500;margin-bottom: 5px;"
                        *ngIf="data['booking']['label']!='' && data['booking']['label']!=null && data['booking']['label']!=undefined">
                        {{data['booking']['turfId']['name']}} ({{data['booking']['label']}})
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display: flex;">
                    <div style="font-weight: 500;margin-bottom: 5px;">
                        {{data['booking']['turfId']['address']['streetName']}} ,
                        {{data['booking']['turfId']['address']['landmark']}} ,
                        {{data['booking']['turfId']['address']['city']}} ,
                        {{data['booking']['turfId']['address']['state']}} ,
                        {{data['booking']['turfId']['address']['pincode']}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>

    <div>
        <h3 style="font-weight: 600;margin-bottom: 5px;">Slot Detail</h3>
        <div class="row">
            <div class="col-md-12" style="display: flex;align-items: center;">
                <div style="width: 7em;">
                    Start Time:
                </div>
                {{getTimeFormat(data['booking']['bookingSlotStartTime'])}}

            </div>
        </div>
        <div class="row">
            <div class="col-md-12" style="display: flex;align-items: center;">
                <div style="width: 7em;">
                    End Time:
                </div>
                {{getTimeFormat(data['booking']['bookingSlotEndTime'])}}

            </div>
        </div>
    </div>
    <hr *ngIf="data['booking']['rentedItem'].length>0">
    <div *ngIf="data['booking']['rentedItem'].length>0">
        <h3 style="font-weight: 600;margin-bottom: 5px;">Rented Item Detail</h3>
        <div class="row">
            <div class="col-md-12" style="display: flex;align-items: center;">
                <div *ngFor="let item of data['booking']['rentedItem']">
                    {{item['productName']}} x {{item['quantity']}} at {{'\u20b9'+item['price']}} for
                    {{item['duration']}} min
                </div>

            </div>
        </div>

    </div>
    <hr>
    <div>
        <h3 style="font-weight: 600;margin-bottom: 5px;">Transaction Detail</h3>
        <div class="row">
            <div class="col-md-12" style="display: flex;align-items: center;">
                <div style="width: 10em;">
                    Total Amount:
                </div>
                {{'\u20b9'+data['booking']['totalAmount'].toFixed(2)}}

            </div>
        </div>
        <div class="row">
            <div class="col-md-12" style="display: flex;align-items: center;">
                <div style="width: 10em;">
                    Paid Amount:
                </div>
                {{'\u20b9'+data['booking']['amountPaid'].toFixed(2)}}

            </div>
        </div>
        <div class="row">
            <div class="col-md-12" style="display: flex;align-items: center;">
                <div style="width: 10em;">
                    Remaining Amount:
                </div>
                {{'\u20b9'+(data['booking']['totalAmount']-data['booking']['amountPaid']).toFixed(2)}}

            </div>
        </div>
    </div>


    <hr>
    <!-- <div class="row">
            <div class="col-md-12">

                <div style="display: contents;">
                    {{data['booking']['bookingStatus']}}

                </div>
            </div>
        </div> -->
    <div>
        <h3 style="font-weight: 600;margin-bottom: 5px;">Status</h3>
        <div class="row">
            <div class="col-md-12" style="display: flex;align-items: center;">
                <div style="width: 9em;">
                    Booking STATUS:
                </div>
                {{data['booking']['bookingStatus']}}

            </div>
        </div>
        <div class="row">
            <div class="col-md-12" style="display: flex;align-items: center;">
                <div style="width: 9em;">
                    Payment Status:
                </div>
                {{data['booking']['paymentStatus']}}
            </div>
        </div>
    </div>
    <!-- <div class="row" *ngIf="data['booking']['bookingStatus']=='PENDING'">

        <div class="col-md-12" style="display: flex;align-items: center;">

            <input type="radio" name="radio-btn" id="Approve" name="Approve" [checked]="isAcceptButtonSelected"
                (change)="radioChangeHandler($event)">
            <label for="Approve" class="lbl-radio">Approve</label>
            <input type="radio" name="radio-btn" id="Reject" name="Reject" [checked]="isRejectButtonSelected"
                (change)="radioChangeHandler($event)">
            <label for="Reject" class="lbl-radio">Reject</label>
        </div>
    </div>

    
    <div *ngIf="isRejectButtonSelected">
        <div>
            <label for="remark" class="label required">Remark</label>
            <input type="text" id="remark" class="input-style" placeholder="Enter remark" [(ngModel)]="remark"
                [ngModelOptions]="{standalone: true}">
        </div>
        <div mat-dialog-actions>
            <button class="no-btn" mat-dialog-close (click)="close()">No</button>
            <button class="yes-btn" [disabled]="remark==null" (click)="remark==null? null:rejectbooking()">Yes</button>
        </div>
    </div> -->
</div>