import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-business-sidebar',
  templateUrl: './add-edit-business-sidebar.component.html',
  styleUrls: ['./add-edit-business-sidebar.component.scss'],
})
export class AddEditBusinessSidebarComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;

  // Pan Card Image
  selectedPancardImage: any = null;
  selectedPancardImageName: any = null;
  defaultPancardImage: any;
  // Cheques Leaf Image
  selectedChequeLeafImage: any = null;
  selectedChequeLeafImageName: any = null;
  defaultChequeLeafImage: any;

  myHeaders = new Headers();
  formdata = new FormData();

  firstNameControl = new FormControl('', [Validators.required]);
  lastNameControl = new FormControl('', [Validators.required]);
  emailControl = new FormControl('', [Validators.required]);
  phoneControl = new FormControl('', [Validators.required]);
  pancardNumberControl = new FormControl('', [Validators.required]);
  accountHolderNameControl = new FormControl('', [Validators.required]);
  bankNameControl = new FormControl('', [Validators.required]);
  accountNumberControl = new FormControl('', [Validators.required]);
  ifscCodeControl = new FormControl('', [Validators.required]);

  addBusinessForm: FormGroup = new FormGroup({
    firstName: this.firstNameControl,
    lastName: this.lastNameControl,
    email: this.emailControl,
    phone: this.phoneControl,
    pancardNumber: this.pancardNumberControl,
    accountHolderName: this.accountHolderNameControl,
    bankName: this.bankNameControl,
    accountNumber: this.accountNumberControl,
    ifscCode: this.ifscCodeControl,
  });
  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
  }
  ngOnInit(): void {
    this.addBusinessForm = this.formBuilder.group({
      firstName: this.firstNameControl,
      lastName: this.lastNameControl,
      email: this.emailControl,
      phone: this.phoneControl,
      pancardNumber: this.pancardNumberControl,
      accountHolderName: this.accountHolderNameControl,
      bankName: this.bankNameControl,
      accountNumber: this.accountNumberControl,
      ifscCode: this.ifscCodeControl,
    });
    if (this.dataToEdit != null) {
      this.addBusinessForm
        .get('firstName')
        ?.setValue(this.dataToEdit['user']['firstName']);
      this.addBusinessForm
        .get('lastName')
        ?.setValue(this.dataToEdit['user']['lastName']);
      this.addBusinessForm
        .get('email')
        ?.setValue(this.dataToEdit['user']['email']);
      this.addBusinessForm
        .get('phone')
        ?.setValue(this.dataToEdit['user']['phone']);

      this.addBusinessForm
        .get('pancardNumber')
        ?.setValue(this.dataToEdit['document'][0]['docNumber']);
      this.addBusinessForm
        .get('accountHolderName')
        ?.setValue(this.dataToEdit['bankDetails'][0]['accountHolderName']);
      this.addBusinessForm
        .get('bankName')
        ?.setValue(this.dataToEdit['bankDetails'][0]['bankName']);
      this.addBusinessForm
        .get('accountNumber')
        ?.setValue(this.dataToEdit['bankDetails'][0]['accountNumber']);
      this.addBusinessForm
        .get('ifscCode')
        ?.setValue(this.dataToEdit['bankDetails'][0]['ifscCode']);
      this.defaultChequeLeafImage =
        this.dataToEdit['bankDetails'] == null ||
        this.dataToEdit['bankDetails'].length < 1
          ? null
          : this.dataToEdit['bankDetails'][0]['chequeImageUrl'];
      this.defaultPancardImage =
        this.dataToEdit['document'] == null ||
        this.dataToEdit['document'].length < 1
          ? null
          : this.dataToEdit['document'][0]['docUrl'];
    }
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }
  displayPancard(event: any) {
    this.selectedPancardImage = event.target.files[0];
    this.selectedPancardImageName = this.selectedPancardImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultPancardImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedPancardImage);
  }
  displayChequeLeaf(event: any) {
    this.selectedChequeLeafImage = event.target.files[0];
    this.selectedChequeLeafImageName = this.selectedChequeLeafImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultChequeLeafImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedChequeLeafImage);
  }
  downloadImage(url: any) {
    window.open(url);
  }
  addBusiness() {
    this.isLoading = true;

    if (this.dataToEdit != null) {
      this.formdata.append('businessId', this.dataToEdit['_id']);
      this.formdata.append('userId', this.dataToEdit['user']['_id']);
      this.formdata.append(
        'panCardUrl',
        this.dataToEdit['document'][0]['docUrl']
      );
      this.formdata.append(
        'chequeImageUrl',
        this.dataToEdit['bankDetails'][0]['chequeImageUrl']
      );
    } else {
      this.formdata.append('businessId', '0');
    }
    if (this.selectedPancardImage != null) {
      this.formdata.append(
        'panCardUrl',
        this.selectedPancardImage,
        this.selectedPancardImageName
      );
    }
    if (this.selectedChequeLeafImage != null) {
      this.formdata.append(
        'chequeImageUrl',
        this.selectedChequeLeafImage,
        this.selectedChequeLeafImageName
      );
    }

    this.formdata.append(
      'firstName',
      this.addBusinessForm.get('firstName')?.value
    );
    this.formdata.append(
      'lastName',
      this.addBusinessForm.get('lastName')?.value
    );
    this.formdata.append('email', this.addBusinessForm.get('email')?.value);
    this.formdata.append('phone', this.addBusinessForm.get('phone')?.value);
    this.formdata.append(
      'pancardNumber',
      this.addBusinessForm.get('pancardNumber')?.value
    );
    this.formdata.append(
      'accountHolderName',
      this.addBusinessForm.get('accountHolderName')?.value
    );
    this.formdata.append(
      'bankName',
      this.addBusinessForm.get('bankName')?.value
    );
    this.formdata.append(
      'accountNumber',
      this.addBusinessForm.get('accountNumber')?.value
    );
    this.formdata.append(
      'ifscCode',
      this.addBusinessForm.get('ifscCode')?.value
    );

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    var URL;
    if (this.dataToEdit != null) {
      URL = `${webApi.domain + webApi.endPoint.createAndEditUserAndBusiness}`;
    } else {
      URL = `${webApi.domain + webApi.endPoint.createAndEditUserAndBusiness}`;
    }
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addBusinessForm.reset();
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
}
