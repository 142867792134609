import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteSportDialogComponent } from '../delete-sport-dialog/delete-sport-dialog.component';

export interface IOSport {
  id: number;
  isActive: boolean;
  sport: string;
  image: string;
  sportCategory: any;
}

@Component({
  selector: 'app-list-sport',
  templateUrl: './list-sport.component.html',
  styleUrls: ['./list-sport.component.scss'],
})
export class ListSportComponent implements OnInit {
  isAddEditCalled: boolean = false;
  dataToEdit: any;
  p: any = 1;
  count: any = 50;
  selectedSportCategory: any;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  toggleSortDiv: boolean = false;
  downloadReport: boolean = false;

  listOfSportToDisplay: Array<IOSport> = [];
  listOfSport: Array<IOSport> = [];
  listOfSportOriginal: any = [];
  listOfSportsCategory: any = [];

  userDetails: any;
  isLoading: boolean = true;
  myHeaders = new Headers();
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);

    this.fetchAllSport();
  }

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {}

  toggleSortOption() {
    this.toggleSortDiv = !this.toggleSortDiv;
  }
  searchData(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfSportToDisplay = [];

      this.listOfSport.forEach((sport: any, index: number) => {
        if (
          String(sport['sport'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(sport['sportCategory']['categoryName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(sport);
        }
      });
      this.listOfSportToDisplay = [];
      this.listOfSportToDisplay = tempSearchList;
    } else {
      this.listOfSportToDisplay = this.listOfSport;
    }
  }

  async fetchAllSportsCategory() {
    this.listOfSportsCategory = [];
    try {
      let param = {};
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchSportCategory}`;
      param = {};
      await this.as.fetchDetailViaGet(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfSportsCategory = data['result'];
        } else {
          this.listOfSportsCategory = [];
        }
      });
    } catch (error) {
      this.listOfSportsCategory = [];
    }
  }

  addNewSPort() {
    this.toggleOpened();
  }
  editSport(offer: any) {
    let offerToEdit;
    // this.listOfSportOriginal.forEach((ofr: any) => {
    //   if (ofr['_id'] == offer.id) {
    //     offerToEdit = ofr;
    //   }
    // });
    // this.dataToEdit = offerToEdit;
    this.dataToEdit = offer;
    this.toggleOpened();
  }

  async fetchAllSport() {
    this.isLoading = true;
    this.listOfSportToDisplay = [];
    try {
      this.fetchAllSportsCategory();
      let param = {
        fetchAllSport: true,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchAllSports}`;
      await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfSportToDisplay = data['result'];
          this.listOfSport = data['result'];
          this.isLoading = false;
        } else {
          this.listOfSportToDisplay = [];
          this.listOfSport = [];
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.isLoading = false;
      this.listOfSportToDisplay = [];
      this.listOfSport = [];
    }
  }
  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event != 'Error') {
        const index = this.listOfSportToDisplay.findIndex(
          (team: any) => team['_id'] == event['_id']
        );

        if (index == -1) {
          this.listOfSportToDisplay.push(event);
          this.listOfSport = [...this.listOfSportToDisplay];
          this.showSuccessToaster('Sport added successfully', 'Success');
        } else {
          this.listOfSportToDisplay[index] = event;
          this.listOfSport = [...this.listOfSportToDisplay];
          this.showSuccessToaster('Updated successfully', 'Success');
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      } else {
        this.showErrorToaster('Error occured.Kindly try again', 'Error');
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  deleteSport(sport: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { sport: sport };

    let dialogRef = this.matDialog.open(
      DeleteSportDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Delete Sport') {
          const param = {
            sportId: res['value']['_id'],
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.deleteSport}`;
          await this.as
            .updateDataViaPut(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                var i = 0;
                this.listOfSportToDisplay.forEach(
                  (offer: any, index: number) => {
                    if (offer['_id'] == res['value']['_id']) {
                      i = index;
                    }
                  }
                );
                this.listOfSportToDisplay.splice(i, 1);
                this.listOfSport = [...this.listOfSportToDisplay];

                this.showSuccessToaster('Sport Delete Successfully', 'Success');
              } else {
                this.showErrorToaster(
                  'Error Occurred while deleteing Offer. Try again',
                  'Error'
                );
              }
            });
        } else {
          this.toggleClose(null);
        }
      }
    });
  }

  onSelect(event: any) {
    console.log(event.target.value);
    let tempSearchList: any = [];
    this.listOfSportToDisplay = [];
    this.selectedSportCategory = event.target.value;
    if (this.selectedSportCategory == 'All') {
      this.listOfSportToDisplay = this.listOfSport;
    } else {
      this.listOfSport.forEach((sport: any) => {
        if (sport['sportCategory']['_id'] == this.selectedSportCategory) {
          tempSearchList.push(sport);
        }
      });
      this.listOfSportToDisplay = tempSearchList;
    }
  }

  async updateActiveStatus(sport: any, update: boolean) {
    const param = {
      sportId: sport['_id'],
      update: update,
    };
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    const URL = `${webApi.domain + webApi.endPoint.updateActiveStatus}`;
    await this.as
      .updateDataViaPut(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfSportToDisplay.forEach((business: any, index: number) => {
            if (business['_id'] == sport['_id']) {
              this.listOfSportToDisplay[index].isActive = update;
            }
          });
          this.listOfSport = [...this.listOfSportToDisplay];
          if (!update) {
            this.showSuccessToaster('Activated Successfully', 'Success');
          } else {
            this.showSuccessToaster('Inactivated Successfully', 'Success');
          }
        } else {
          this.showErrorToaster(
            'Error Occurred while suspending Business. Try again',
            'Error'
          );
        }
      });
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  MODES: Array<string> = ['over', 'push', 'slide'];
  POSITIONS: Array<string> = ['left', 'right', 'top', 'bottom'];
}
